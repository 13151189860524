import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface TabButtonProps extends ButtonProps {
  label: string;
  icon: IconType;
  active: boolean;
  onClick: () => void;
  tooltip?: string;
}
const TabButton = (props: TabButtonProps) => {
  const { label, icon, active, onClick, tooltip } = props;

  const activeColor = mode('gray.300', 'gray.700');

  const tabButton = (
    <Button
      flex="1"
      borderRadius="full"
      transition="all 0.3s"
      userSelect="none"
      backgroundColor={active ? activeColor : 'transparent'}
      color={mode('gray.700', 'gray.400')}
      onClick={onClick}
    >
      <HStack spacing="1">
        <Icon as={icon} fontSize="xs" opacity={0.64} />
        <Text as="span">{label}</Text>
      </HStack>
    </Button>
  );

  return (
    <>
      {tooltip ? (
        <Tooltip
          hasArrow
          placement="top"
          fontSize="md"
          label={tooltip}
          bg="blue.500"
          defaultIsOpen
          // isOpen
        >
          {tabButton}
        </Tooltip>
      ) : (
        tabButton
      )}
    </>
  );
};

export default TabButton;
