import { useCallback, useState } from 'react';
import {
  CommentRule,
  FilterRule,
  ReplyChildRule,
  RuleFieldName
} from '../../state/model/firebase';
import { uuidv4 } from '../../utils/index';

const initRule: ReplyChildRule = {
  id: uuidv4(),
  replyMessage: '',
  commentRule: CommentRule.default,
  filterRule: FilterRule.default,
  commentRuleTags: [],
  filterRuleTags: [],
  likeComment: true,
};

export function useReplyRuleState(initRules: ReplyChildRule[] = [initRule]) {
  const [rules, setRules] = useState<ReplyChildRule[]>(initRules);

  const validateRules = () => {
    let valid = false;

    for (let i = 0; i < rules.length; i += 1) {
      const rule = rules[i];
      const validCommentRules =
        rule.commentRule === CommentRule.default
          ? true
          : rule.commentRuleTags.length > 0;
      const validFilterRules =
        rule.filterRule === FilterRule.default
          ? true
          : rule.filterRuleTags.length > 0;
      valid =
        rule.replyMessage.length > 0 && validCommentRules && validFilterRules;

      if (!valid) return false;
    }
    return valid;
  };

  const validReplyRules = validateRules();

  const updateFieldChanged = useCallback(
    (index: number, fieldName: RuleFieldName, value: any) => {
      const newRules = [...rules];
      newRules[index] = { ...newRules[index], [fieldName]: value };
      setRules(newRules);
    },
    [rules]
  );

  const addRule = () => {
    const newRules = [...rules];
    newRules.push({ ...initRule, id: uuidv4() });
    setRules(newRules);
  };

  const removeRule = useCallback((ruleIndex: number) => {
    const newRules = [...rules];
    newRules.splice(ruleIndex, 1);
    setRules(newRules);
  }, [rules]);

  return {
    rules,
    validReplyRules,
    addRule,
    removeRule,
    updateFieldChanged,
  };
}
