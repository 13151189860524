import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Text,
  Textarea,
  useColorModeValue as mode,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaRegThumbsUp } from 'react-icons/fa';
import { boolean, number, object, string } from 'yup';
import { useRuleTags } from '../../hooks/ReplyRule/useRuleTags';
import {
  CommentRule,
  FilterRule,
  ReplyChildRule,
  RuleFieldName
} from '../../state/model/firebase';
import { insertAt } from '../../utils';
import CommentRuleSelect from './Select/CommentRuleSelect';
import FilterRuleSelect from './Select/FilterRuleSelect';
import TagInput from './TagInput';

const schema = object().shape({
  commentRule: number().required(),
  filterRule: number().required(),
  replyMessage: string().required(),
  likeComment: boolean().required(),
  enableAnd: boolean(),
});
interface FormValues {
  commentRule: number;
  filterRule: number;
  replyMessage: string;
  likeComment: boolean;
  enableAnd: boolean;
}

interface IReplyRuleItemProps {
  initValue: ReplyChildRule;
  ruleIndex: number;
  validateNow: boolean;
  isEditing: boolean;
  onReplyRulesChange: (
    index: number,
    fieldName: RuleFieldName,
    value: any
  ) => void;
  onRemoveRule: null | ((ruleIndex: number) => void);
}
const ReplyRuleItem = (props: IReplyRuleItemProps) => {
  const {
    initValue,
    ruleIndex,
    validateNow,
    isEditing,
    onReplyRulesChange,
    onRemoveRule,
  } = props;
  const { register, errors, watch, setValue, trigger } = useForm<FormValues>({
    defaultValues: {
      commentRule: initValue.commentRule as number,
      filterRule: initValue.filterRule as number,
      likeComment: initValue.likeComment,
      replyMessage: initValue.replyMessage,
      enableAnd: initValue.filterRule !== FilterRule.default || isEditing,
    },
    resolver: yupResolver(schema),
  });
  const commentRule = watch('commentRule');
  const filterRule = watch('filterRule');
  const enableAnd = watch('enableAnd');
  const [textPosition, setTextPosition] = useState(0);

  useEffect(() => {
    if (validateNow) trigger();
  }, [validateNow, trigger]);

  const {
    state: commentRuleTags,
    onAddRuleText: onAddComentRuleTag,
    onRemoveRuleText: onRemoveCommentRuleTag,
  } = useRuleTags(initValue.commentRuleTags, (tags) =>
    onReplyRulesChange(ruleIndex, 'commentRuleTags', tags)
  );
  const {
    state: filterRuleTags,
    onAddRuleText: onAddFilterRuleTag,
    onRemoveRuleText: onRemoveFilterRuleTag,
  } = useRuleTags(initValue.filterRuleTags, (tags) =>
    onReplyRulesChange(ruleIndex, 'filterRuleTags', tags)
  );

  // fixes performance issue when typing to fast
  const inputRef = useRef<any>();
  const [replyText, setReplyText] = useState(initValue.replyMessage || '');
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        replyText === inputRef.current.value &&
        replyText !== initValue.replyMessage
      ) {
        onReplyRulesChange(ruleIndex, 'replyMessage', replyText);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [replyText, ruleIndex, initValue, onReplyRulesChange]);

  return (
    <Box
      border="2px solid currentColor"
      rounded="lg"
      borderColor={mode('gray.200', 'gray.600')}
      overflow="hidden"
      p="4"
    >
      <VStack spacing="3">
        <FormControl id="commentRule" isInvalid={!!errors.commentRule}>
          <HStack>
            <FormLabel mb="0">If</FormLabel>
            <CommentRuleSelect
              name="commentRule"
              ref={register}
              onChanged={(event) => {
                const value = parseInt(event.target.value, 10);
                onReplyRulesChange(ruleIndex, 'commentRule', value);
              }}
            />
            {onRemoveRule !== null ? (
              <IconButton
                aria-label="Delete"
                variant="ghost"
                color="red.400"
                size="lg"
                icon={<DeleteIcon />}
                onClick={() => onRemoveRule(ruleIndex)}
              />
            ) : null}
          </HStack>
        </FormControl>
        {commentRule.toString() !== CommentRule.default.toString() && (
          <FormControl
            isInvalid={
              commentRuleTags.length < 1 &&
              commentRule.toString() !== CommentRule.default.toString()
            }
          >
            <TagInput
              placeHolder="...add a word and press 'enter'"
              ruleTags={commentRuleTags}
              tagColor="blue"
              onAddTag={(value) => onAddComentRuleTag(value)}
              onRemoveTag={(id) => onRemoveCommentRuleTag(id)}
            />
          </FormControl>
        )}
        <FormControl id="filterRule" isInvalid={!!errors.filterRule}>
          <HStack>
            <FormLabel m="0">And</FormLabel>
            <Checkbox
              ref={register}
              name="enableAnd"
              isDisabled={isEditing}
              onChange={() => {
                setValue('filterRule', FilterRule.default);
                onReplyRulesChange(ruleIndex, 'filterRule', FilterRule.default);
              }}
            />
            {enableAnd && (
              <FilterRuleSelect
                name="filterRule"
                ref={register}
                onChanged={(event) => {
                  const value = parseInt(event.target.value, 10);
                  onReplyRulesChange(ruleIndex, 'filterRule', value);
                }}
              />
            )}
          </HStack>
        </FormControl>
        {filterRule.toString() !== FilterRule.default.toString() && (
          <FormControl
            isInvalid={
              filterRuleTags.length < 1 &&
              filterRuleTags.toString() !== FilterRule.default.toString()
            }
          >
            <TagInput
              ruleTags={filterRuleTags}
              tagColor="red"
              onAddTag={(value) => onAddFilterRuleTag(value)}
              onRemoveTag={(id) => onRemoveFilterRuleTag(id)}
            />
          </FormControl>
        )}

        <FormControl id="replyMessage" isInvalid={!!errors.replyMessage}>
          {/* TODO: Future: add emoji support */}
          <FormLabel>Reply with</FormLabel>
          <Textarea
            ref={inputRef}
            value={replyText}
            name="replyMessage"
            type="text"
            placeholder="...e.g. please visit our website"
            onBlur={(event) =>
              setTextPosition(event.target.selectionStart || 0)
            }
            onChange={(event) => {
              setReplyText(event.target.value);
            }}
          />
          <Button
            variant="link"
            fontWeight="light"
            onClick={() => {
              const newValue = insertAt(
                inputRef.current.value,
                textPosition,
                '{{username}}'
              );
              setValue('replyMessage', newValue);
              setReplyText(newValue);
            }}
          >
            Insert Username
          </Button>
          <FormErrorMessage>
            {!!errors.replyMessage?.message && errors.replyMessage.message}
          </FormErrorMessage>
        </FormControl>
        <Checkbox
          ref={register}
          name="likeComment"
          alignSelf="flex-start"
          onChange={(event) => {
            onReplyRulesChange(ruleIndex, 'likeComment', event.target.value);
          }}
        >
          <HStack>
            <FaRegThumbsUp />
            <Text>Like Comment</Text>
          </HStack>
        </Checkbox>
      </VStack>
    </Box>
  );
};

export default ReplyRuleItem;

// TODO: Future collapse with transition or accordion -> future feature (wie bei postjelly mit gray background)?
// <Accordion allowToggle defaultIndex={[0]}>
// <AccordionItem>
//     <AccordionButton>
//       <Box flex="1" textAlign="left">
//         Section 1 title
//       </Box>
//       <AccordionIcon />
//     </AccordionButton>
//   <AccordionPanel px='0' pb={4}>
//     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//     eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
//     enim ad minim veniam, quis nostrud exercitation ullamco laboris
//     nisi ut aliquip ex ea commodo consequat.
//   </AccordionPanel>
// </AccordionItem>
// </Accordion>
