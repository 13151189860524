import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import { boolean, object, string } from 'yup';
import { FieldGroup } from '../../components/FormLayout/FieldGroup/FieldGroup';
import { useFormatMessage } from '../../hooks';
import useInviteMember from '../../hooks/api/mutation/useInviteMember';
import { useAuth } from '../../state/context/AuthContext';
import { usePreferences } from '../../state/context/PreferencesContext';
import paths from '../Router/paths';

const schema = object().shape({
  email: string().email().required(),
  canEdit: boolean().required(),
  canDelete: boolean().required(),
  canProcess: boolean().required(),
});

interface FormValues {
  email: string;
  canEdit: boolean;
  canDelete: boolean;
  canProcess: boolean;
}

const Invite = () => {
  const { state } = usePreferences();
  const { currentUser } = useAuth();
  const mutation = useInviteMember();
  const history = useHistory();

  const onSubmitHandler = (value: FormValues) => {
    mutation.mutate(
      { ...value },
      {
        onSuccess: () => history.push(paths.TEAM),
      }
    );
  };
  const { register, handleSubmit, errors } = useForm<FormValues>({
    defaultValues: {
      email: '',
      canEdit: true,
      canDelete: true,
      canProcess: true,
    },
    resolver: yupResolver(schema),
  });

  // Redirect if user is not owner of team
  const redirect = state.currentTeamId !== currentUser?.uid && (
    <Redirect to={paths.TEAM} />
  );

  return (
    <Box w="full" h="full" overflowY="auto">
      {redirect}
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
        <form id="user-form" onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack spacing="4" divider={<StackDivider />}>
            <Heading size="lg" as="h1" paddingBottom="4">
              {useFormatMessage('InviteUser.inviteUser')}
            </Heading>
            <FieldGroup title={useFormatMessage('InviteUser.userInfo')}>
              <VStack width="full" spacing="6">
                <FormControl id="email" isInvalid={!!errors.email}>
                  <FormLabel>{useFormatMessage('UserForm.email')}</FormLabel>
                  <Input type="email" name="email" ref={register} />
                  <FormErrorMessage>
                    {useFormatMessage('UserForm.invalidEmail')}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </FieldGroup>
            <FieldGroup title={useFormatMessage('InviteUser.permissions')}>
              <Stack spacing={10} direction="row">
                <Checkbox name="canEdit" ref={register}>
                  {useFormatMessage('InviteUser.edit')}
                </Checkbox>
                <Checkbox name="canDelete" ref={register}>
                  {useFormatMessage('InviteUser.delete')}
                </Checkbox>
                <Checkbox
                  name="canProcess"
                  ref={register}
                  defaultChecked
                  readOnly
                  isDisabled
                >
                  {useFormatMessage('InviteUser.process')}
                </Checkbox>
              </Stack>
            </FieldGroup>
          </Stack>
          <FieldGroup mt="8">
            <VStack spacing="4">
              <HStack width="full">
                <Button
                  type="submit"
                  colorScheme="blue"
                  loadingText={useFormatMessage('General.submitting')}
                  isLoading={mutation.isLoading || mutation.isLoading}
                >
                  {useFormatMessage('InviteUser.allowAccess')}
                </Button>
              </HStack>
              <Text fontSize="xs">
                If the user has already registered an account with TurboReply,
                he is automatically given access and can change teams at the
                profile dropdown. If the new member has not yet registered an
                account, they will receive an email invitation. The user must
                log in using the link, set a new password, and possibly log out
                and log in again.
              </Text>
            </VStack>
          </FieldGroup>
        </form>
      </Box>
    </Box>
  );
};

export default Invite;
