import {
  Box,
  Button,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { Redirect, useHistory } from 'react-router-dom';
import MyLogo from '../../components/Icons/MyLogo';
import useSendVerifyEmail from '../../hooks/api/mutation/useSendVerifyEmail';
import { logout, useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';

const VerifyEmail = () => {
  const { currentUser, verified } = useAuth();
  const { error, isLoading, isSuccess, mutate } = useSendVerifyEmail();

  const errorMessage = error ? `${error}` : null;
  let redirect =
    !!currentUser?.emailVerified || (verified && <Redirect to={paths.ROOT} />);
  redirect = !currentUser?.uid ? <Redirect to={paths.LOGIN} /> : redirect;
  const history = useHistory();

  return (
    <>
      {redirect}
      <Box
        bg={mode('gray.50', 'inherit')}
        minH="100vh"
        py="12"
        px={{ sm: '6', lg: '8' }}
      >
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} w={{ sm: 'full' }}>
          <Box mb={{ base: '10', md: '28' }}>
            <MyLogo mx="auto" h="8" iconColor={mode('blue.500', 'blue.200')} />
          </Box>
          <Heading mt="6" textAlign="center" size="xl" fontWeight="extrabold">
            E-Mail Verification
          </Heading>
        </Box>
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} mt="8" w={{ sm: 'full' }}>
          <Box
            bg={mode('white', 'gray.700')}
            py="8"
            px={{ base: '4', md: '10' }}
            shadow="base"
            rounded={{ sm: 'lg' }}
          >
            <Stack spacing="6">
              <FormLabel>
                A verification mail has been sent to your email account. Please
                check your Inbox to verify.
              </FormLabel>
              <Text>
                No email found? <Text as="b">Check your spam folder.</Text>
              </Text>
              {/* <FieldGroup mt="8"> */}
              <HStack width="full">
                <Button
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  onClick={async () => {
                    await logout();
                    history.push(paths.CONFIRM);
                  }}
                >
                  Login
                </Button>
                <Button
                  isLoading={isLoading}
                  isDisabled={isSuccess}
                  type="button"
                  variant="outline"
                  size="lg"
                  fontSize="md"
                  onClick={() => mutate()}
                >
                  Re-send E-mail
                </Button>
              </HStack>
              {isSuccess && !isLoading && (
                <FormLabel color="green.200">E-mail sent!</FormLabel>
              )}
              {errorMessage && (
                <FormLabel color="red.200">{errorMessage}</FormLabel>
              )}
              {/* </FieldGroup> */}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyEmail;
