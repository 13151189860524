import { forwardRef, Select } from '@chakra-ui/react';
import { FilterRule } from '../../../state/model/firebase';

const FilterRuleSelect = forwardRef(
  (
    props: {
      name?: string | undefined;
      onChanged?: React.ChangeEventHandler<HTMLSelectElement>;
    },
    ref
  ) => {
    const { name, onChanged } = props;
    return (
      <Select
        fontWeight="medium"
        fontSize="xs"
        ref={ref}
        name={name}
        onChange={onChanged}
      >
        <option value={FilterRule.default}>Select an Option (optional)</option>
        <option value={FilterRule.containsAny}>
          Comment does not contain any
        </option>
        <option value={FilterRule.containsAll}>
          Comment does not contain all
        </option>
      </Select>
    );
  }
);

export default FilterRuleSelect;
