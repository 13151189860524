import {
  ExternalLinkIcon,
  QuestionOutlineIcon,
  RepeatIcon
} from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  Tooltip,
  UseCheckboxProps,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FbAdAccounts } from '../../state/model/facebook';

interface ConnectPageElementProps extends UseCheckboxProps {
  title: string;
  fbPageId: string;
  igPageId: string | undefined;
  logo: string;
  adAccounts: FbAdAccounts | undefined;
  alreadyConnected: boolean;
  isConnecting: boolean;
  isRefreshing: boolean;
  isCurrentPage: boolean;
  onRefresh: () => void;
  onConnect: (pageId: string, adAccountId: string) => void;
}

export const ConnectPageElement = (props: ConnectPageElementProps) => {
  const {
    title,
    fbPageId,
    igPageId,
    logo,
    adAccounts,
    isRefreshing,
    isConnecting,
    isCurrentPage,
    alreadyConnected,
    onRefresh,
    onConnect,
  } = props;
  const [adAccountId, setAdAccount] = useState<undefined | string>();
  const [invalid, setInvalid] = useState(false);

  const handleConnect = () => {
    if (!adAccountId) {
      setInvalid(true);
      return;
    }
    onConnect(fbPageId, adAccountId);
  };

  return (
    <Box
      borderWidth="2px"
      px="4"
      py="3"
      borderRadius="md"
      transition="all 0.2s"
    >
      <VStack align="start" spacing="14">
        <HStack spacing="4" w="full">
          <Box fontSize="sm">
            <FaFacebook />
          </Box>
          <Avatar
            w="8"
            h="8"
            referrerPolicy="no-referrer"
            src={logo}
            alt="Profile"
            // name={title}
          />
          <VStack align="start" w="full">
            <Text fontWeight="bold">{title}</Text>
            {/* <Text fontSize="sm">{description}</Text> */}
            {igPageId ? (
              <Box
                fontSize="sm"
                display="inline-flex"
                alignItems="center"
                justifyItems="space-between"
              >
                <FaInstagram />
                <Text ml="1">Instagram connected</Text>
              </Box>
            ) : (
              <Alert status="warning" px="2" fontSize="sm">
                <AlertIcon alignSelf="start" />
                <VStack alignItems="start" spacing="0">
                  <Text>Instagram not connected.</Text>
                  <Link
                    href={`https://facebook.com/${fbPageId}/settings/?tab=instagram_management`}
                    isExternal
                  >
                    <Text as="u">
                      Connect here <ExternalLinkIcon mx="2px" />
                    </Text>
                  </Link>
                  <Button
                    variant="ghost"
                    size="xs"
                    m="0"
                    p="0"
                    isLoading={isRefreshing}
                    onClick={onRefresh}
                    loadingText="Please wait..."
                    leftIcon={<RepeatIcon />}
                  >
                    Refresh here
                  </Button>
                </VStack>
              </Alert>
            )}
          </VStack>
        </HStack>
        <FormControl isInvalid={invalid}>
          <Flex maxW="80%" mx="4">
            <Popover>
              <PopoverTrigger>
                <FormLabel as="button" type="button" w="40">
                  Ad Account <QuestionOutlineIcon />
                </FormLabel>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Why?</PopoverHeader>
                <PopoverBody>
                  Ad Account is required to respond on Facebook and Instagram
                  posts created by Ad Creatives. If you don&apos;t want to reply
                  to comments on your ad posts, you can choose the option
                  &apos;None&apos;
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Tooltip
              hasArrow
              // placement="right"
              fontSize="md"
              label="1. Select Ad Account"
              bg="blue.500"
              defaultIsOpen
              // isOpen
            >
              <Select
                placeholder="Select Ad Account"
                isRequired
                onChange={(event) => setAdAccount(event.target.value)}
                isInvalid={invalid}
              >
                <option value="none">None</option>
                {adAccounts?.data &&
                  adAccounts.data.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))}
              </Select>
            </Tooltip>
          </Flex>
        </FormControl>

        <Tooltip
          hasArrow
          placement="right"
          fontSize="md"
          label={`2. Click ${alreadyConnected ? "'Update'" : "'Connect'"}`}
          bg="blue.500"
          defaultIsOpen
          // isOpen
        >
          <Button
            colorScheme="gray"
            // colorScheme={alreadyConnected ? 'gray' : 'blue'}
            alignSelf="center"
            isLoading={isConnecting && isCurrentPage}
            isDisabled={isConnecting}
            onClick={handleConnect}
          >
            {alreadyConnected ? 'Update' : 'Connect'}
          </Button>
        </Tooltip>
      </VStack>
      <FormControl justifyItems="center" isInvalid={invalid}>
        {/* TODO: translation */}
        <FormErrorMessage>Select a option for Ad Account</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
