import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { removeReplyRule } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { ReplyRuleDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useDeleteReplyRule() {
  const queryClient = useQueryClient();

  const { locale } = usePreferences().state;
  const toast = useToast();
  // TODO: translate
  // const sucessMessage = useFormatMessage('Team.removeSuccess');
  const sucessMessage = 'Reply Rule deleted sucessfully';

  return useMutation(
    (variables: { teamId: string; replyRuleId: string }) =>
      removeReplyRule(variables.teamId, variables.replyRuleId),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (deletedRuleId) => {
        const previousPostInfos = queryClient.getQueryData(
          'replyRules'
        ) as ReplyRuleDocument[];
        if (previousPostInfos)
          queryClient.setQueryData(
            'replyRules',
            previousPostInfos.filter((post) => post.id !== deletedRuleId)
          );
        queryClient.invalidateQueries('replyRules');

        toast({
          description: sucessMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
