import { Spinner } from '@chakra-ui/react';
import { useIsFetching } from 'react-query';

const GlobalLoadingIndicator = () => {
  const isFetching = useIsFetching();

  return (
    <Spinner
      position="fixed"
      top="0.5rem"
      right="2rem"
      fontSize="1.5rem"
      transition="0.3s ease"
      pointerEvents="none"
      opacity={isFetching ? 1 : 0}
    />
  );
};

export default GlobalLoadingIndicator;
