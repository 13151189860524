import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  LightMode,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { boolean, object, string } from 'yup';
import useAuthProfile from '../../../hooks/api/mutation/useAuthProfile';
import paths from '../../../pages/Router/paths';
import { signInWithEmail, useAuth } from '../../../state/context/AuthContext';
import { UnderlineLink } from '../../UnderlineLink/UnderlineLink';

const schema = object().shape({
  remember: boolean(),
  email: string().email().required(),
  password: string().required(),
});

export const SigninForm = () => {
  const { email } = useAuth();
  const { error, isLoading, mutate } = useAuthProfile();
  const errorMessage = error ? `${error}` : null;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { email, password: '', remember: true },
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (value: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    mutate(() => signInWithEmail(value.email, value.password, value.remember));
  };
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack spacing="-px">
        <FormControl id="email-address" isInvalid={!!errors.email}>
          <FormLabel srOnly>Email address</FormLabel>
          <Input
            size="lg"
            name="email"
            type="email"
            autoComplete="email"
            required
            placeholder="Email address"
            bg={mode('white', 'gray.700')}
            fontSize="md"
            roundedBottom="0"
            ref={register}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="password" isInvalid={!!errors.password}>
          <FormLabel srOnly>Password</FormLabel>
          <Input
            name="password"
            type="password"
            autoComplete="current-password"
            required
            size="lg"
            bg={mode('white', 'gray.700')}
            fontSize="md"
            roundedTop="0"
            placeholder="Password"
            ref={register}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      <Flex align="center" justify="space-between" mt="8">
        <LightMode>
          <Checkbox
            size="lg"
            colorScheme="blue"
            sx={{
              '.chakra-checkbox__control': {
                '&:not([data-checked])': { bg: mode('white', 'gray.700') },
                rounded: 'base',
                borderWidth: '1px',
              },
              '.chakra-checkbox__label': { fontSize: 'sm' },
            }}
            name="remember"
            ref={register}
          >
            Remember me
          </Checkbox>
        </LightMode>
        <UnderlineLink to={paths.RESET_PASSWORD} fontSize="sm">
          Forgot Password
        </UnderlineLink>
      </Flex>
      <LightMode>
        <Button
          size="lg"
          type="submit"
          mt="8"
          w="full"
          colorScheme="blue"
          fontSize="md"
          fontWeight="bold"
          isLoading={isLoading}
        >
          Sign in
        </Button>
      </LightMode>
      {errorMessage ? (
        <Text mt="3" color={mode('red.600', 'red.400')} fontWeight="medium">
          {errorMessage}
        </Text>
      ) : null}
    </form>
  );
};
