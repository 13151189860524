import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { HiInboxIn } from 'react-icons/hi';
import Lottie, { Options } from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { analytics } from '../../firebase';
import animationData from '../../lotties/check-mark-success-animation.json';
import paths from '../Router/paths';

const lottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Done = () => {
  const history = useHistory();

  useEffect(() => {
    // google analytics
    analytics.logEvent('added_page', {
      page_location: 'https://app.turboreply.com/done',
      page_path: '/done',
      page_title: 'Done',
    });
  }, []);

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box
        maxW={{ base: 'xl', md: '3xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        pt="2"
      >
        <VStack pt="8" spacing="8">
          <Box>
            <Lottie options={lottieOptions} height={100} width={100} />
            <Heading>Well Done!</Heading>
          </Box>
          <Text>You can now reply to unanswered messages and comments!</Text>
          <Button
            colorScheme="blue"
            leftIcon={<HiInboxIn />}
            onClick={() => history.push(paths.ROOT)}
          >
            Open Inbox
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default Done;
