import {
  AspectRatio,
  Box, Button,
  Heading, Text, Tooltip,
  useColorModeValue as mode,
  VStack
} from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';
import useLinkFacebook from '../../../hooks/api/mutation/useLinkFacebook';
import { useProfile } from '../../../hooks/api/query/useProfile';
import { useAuth } from '../../../state/context/AuthContext';

const Permissions = (props: { goToNextStep: () => void }) => {
  const { goToNextStep } = props;
  const { currentUser } = useAuth();
  const { data: profile } = useProfile(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const mutation = useLinkFacebook();

  const onLinkFacebook = () => {
    if (!profile) {
      console.error('Current Profile not found!'); // should not happen!
      return;
    }
    mutation.mutate(profile, {
      onSuccess: () => {
        goToNextStep();
      },
    });
  };

  return (
    <VStack spacing="28" mt="8" w="full">
      <Tooltip
        hasArrow
        label="1. Start here! 🚀"
        bg="blue.500"
        defaultIsOpen
        isOpen
      >
        <Button
          variant="outline"
          size="lg"
          leftIcon={
            <Box as={FaFacebook} color={mode('facebook.500', 'facebook.300')} />
          }
          isLoading={mutation.isLoading}
          onClick={onLinkFacebook}
        >
          Add Page
        </Button>
      </Tooltip>
      {/* <Alert status="warning" maxW="lg">
        <AlertIcon />
        Make sure to select ALL pages you manage with TurboReply
      </Alert> */}
      {/* <Text fontWeight="light">
        First we&apos;ll need permission to manage your Facebook page&apos;s
        messages and automate your replies. Once you&apos;ve granted us the
        requested permission through Facebook, you&apos;ll be routed back to
        TurboReply.
      </Text> */}
      <VStack spacing="4" w='full' align='flex-start'>
        <Heading>
          Need a little help?{' '}
          <Text fontWeight="normal" fontSize="sm">
            {' '}
            See how TurboReply works in 100 seconds.
          </Text>
        </Heading>
        <AspectRatio w="full" ratio={16/9} display={{base: 'none', md: 'block'}}>
          <iframe
            title="TurboReply in 100 Seconds (Desktop)"
            src="https://www.youtube.com/embed/uExkMAMZNXo"
            allowFullScreen
          />
        </AspectRatio>
        <AspectRatio w="full" ratio={16/9} display={{base: 'block', md: 'none'}}>
          <iframe
            title="TurboReply in 100 Seconds (Mobile)"
            src="https://www.youtube.com/embed/9zjXzc6ybyM"
            allowFullScreen
          />
        </AspectRatio>
      </VStack>
    </VStack>
  );
};

export default Permissions;
