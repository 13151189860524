import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getStats } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { Stats } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchStats = async (teamId: string): Promise<Stats> => {
  const response = getStats(teamId);
  return response;
};

export const useStats = (enabled: boolean, teamId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  return useQuery('stats', () => fetchStats(teamId), {
    enabled,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
