import { AddIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  HStack,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue as mode,
  useMenuButton
} from '@chakra-ui/react';
import { BsChevronDown } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { useFacebookPages } from '../../hooks/api/query/useFacebookPages';
import usePermission from '../../hooks/Permission/usePermission';
import { useAuth } from '../../state/context/AuthContext';
import {
  setCurrentPageId,
  usePreferences
} from '../../state/context/PreferencesContext';
import paths from '../Router/paths';

interface AccountButtonProps extends FlexProps {
  name: string;
  logo: string | undefined;
}
const PageButton = (props: AccountButtonProps) => {
  const buttonProps = useMenuButton(props);
  const { name, logo } = props;

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={mode('gray.50', 'gray.800')}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      bgColor={mode('white', 'gray.700')}
      border='1px'
      borderColor={mode('gray.200', 'gray.700')}
      _active={{ bg: mode('gray.200', 'gray.700') }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Avatar
          w="8"
          h="8"
          referrerPolicy="no-referrer"
          src={logo}
          alt="Profile"
        />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold" >
            {name}
          </Box>
        </Box>
        <Box fontSize="lg" >
          <BsChevronDown />
        </Box>
      </HStack>
    </Flex>
  );
};

export const PageDropdown = () => {
  const { currentUser } = useAuth();
  const { state, dispatch } = usePreferences();
  const { data: pages = [] } = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );

  const currentPage = pages.find((p) => p.id === state.currentPageId);

  const onPageChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      setCurrentPageId(dispatch, value);
    }
  };

  const { isOwner } = usePermission();

  return (
    <>
      <Menu>
        <PageButton
          name={currentPage?.name || ''}
          logo={currentPage ? `https://graph.facebook.com/${currentPage.id}/picture?type=large` : undefined}
        />
        <MenuList
          shadow="lg"
          py="4"
          color={mode('gray.600', 'gray.200')}
          px="3"
        >
          {state.currentPageId && pages.length > 0 && (
            <>
              <Text fontWeight="medium" mb="2">
                Select Page
              </Text>
              <MenuOptionGroup
                defaultValue={state.currentPageId}
                onChange={onPageChange}
              >
                {pages.map((p) => (
                  <MenuItemOption
                    key={p.id}
                    value={p.id}
                    fontWeight="semibold"
                    rounded="md"
                  >
                    <Avatar
                      boxSize="2rem"
                      borderRadius="full"
                      src={`https://graph.facebook.com/${p.id}/picture?type=large`}
                      alt={p.name}
                      mr="12px"
                    />
                    {p.name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </>
          )}
          {isOwner && currentUser?.uid === state.currentTeamId && (
            <>
              <MenuDivider />
              <NavLink to={paths.EDIT_PAGES}>
                <MenuItem icon={<FiSettings />} rounded="md">
                  Manage Pages
                </MenuItem>
              </NavLink>
              {isOwner && (
                <NavLink to={paths.ADD_PAGE}>
                  <MenuItem icon={<AddIcon />} rounded="md">
                    New Page
                  </MenuItem>
                </NavLink>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
