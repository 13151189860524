import { Redirect, Route } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import { useAuth } from '../../../state/context/AuthContext';
import paths from '../paths';

// TODO: Future: isOwner property?
const PrivateRoute = (props: {
  path: string;
  exact: boolean;
  component: React.ComponentType<any>;
}) => {
  const { path, exact, component: Component } = props;
  const { currentUser, verified } = useAuth();

  let redirect: JSX.Element | boolean = false;
  if (!currentUser?.uid) redirect = <Redirect to={paths.LOGIN} />;
  else if (!currentUser?.emailVerified && !verified)
    redirect = <Redirect to={paths.VERIFY} />;

  return (
    <>
      {redirect}
      <Layout>
        <Route exact={exact} path={path} component={Component} />
      </Layout>
    </>
  );
};

export default PrivateRoute;
