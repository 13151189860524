import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ObjectSchema } from 'yup';
import { useFormatMessage } from '../../../hooks';
import useEditProfile from '../../../hooks/api/mutation/useEditProfile';
import paths from '../../../pages/Router/paths';
import { User } from '../../../state/model/firebase';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import FileUpload from '../FileUpload/FileUpload';

interface FormValues {
  email: string;
  name: string;
  file: any;
}

const UserForm = (props: {
  isEditing: boolean;
  isProfile: boolean;
  user: User;
  userId?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: ObjectSchema<any>;
}) => {
  const { isEditing, isProfile, user, userId, schema } = props;
  const history = useHistory();
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      email: user.email,
      name: user.name,
      file: null,
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  const mutationEdit = useEditProfile();

  const sucessMessage = useFormatMessage('UserForm.userModified');

  const onSubmitHandler = (value: FormValues) => {
    if (isEditing) {
      const newUser: User = {
        ...user,
        email: value.email,
        name: value.name,
      };
      if(!userId){
        console.error('UserId is empty!');
        return;
      }

      mutationEdit.mutate(
        { userId, user: newUser, file: value?.file[0] || null },
        {
          onSuccess: () => {
            history.push(paths.ROOT);
            toast({
              description: sucessMessage,
              position: 'top-right',
              duration: 9000,
              status: 'success',
              isClosable: true,
            });
          },
        }
      );
    }
  };

  const imagePreviewUrl =
    watch('file') && watch('file')?.[0]
      ? URL.createObjectURL(watch('file')?.[0])
      : user.logoUrl;

  const editProfileMessage = useFormatMessage('Profile.editProfile');
  const editUserMessage = useFormatMessage('User.editUser');
  const newUserMessage = useFormatMessage('User.newUser');

  let heading = '';
  if (!isProfile && !isEditing) {
    heading = newUserMessage;
  } else if (isProfile) {
    heading = editProfileMessage;
  } else {
    heading = editUserMessage;
  }

  return (
    <form id="user-form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack spacing="4" divider={<StackDivider />}>
        <Heading size="lg" as="h1" paddingBottom="4">
          {heading}
        </Heading>
        <FieldGroup title={useFormatMessage('UserForm.userInfo')}>
          <VStack width="full" spacing="6">
            <FormControl id="email" isInvalid={!!errors.email}>
              <FormLabel>{useFormatMessage('UserForm.email')}</FormLabel>
              <Input
                type="email"
                name="email"
                ref={register}
                variant={isEditing ? 'unstyled' : 'outline'}
                readOnly={isEditing}
              />
              <FormErrorMessage>
                {useFormatMessage('UserForm.invalidEmail')}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="name" isInvalid={!!errors.name}>
              <FormLabel>{useFormatMessage('UserForm.name')}</FormLabel>
              <Input type="name" name="name" maxLength={255} ref={register} />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <Stack direction="row" spacing="6" align="center" width="full">
              <Avatar
                size="xl"
                name={user.name || ''}
                referrerPolicy="no-referrer"
                src={imagePreviewUrl || undefined}
                borderColor="gray.100"
                borderWidth="thin"
              />
              <Box>
                <HStack spacing="5">
                  <FileUpload
                    name="file"
                    placeholder="Pick a file"
                    control={control}
                    acceptedFileTypes="image/*"
                    register={register}
                  >
                    {useFormatMessage('UserForm.logo')}
                  </FileUpload>
                </HStack>
              </Box>
            </Stack>
          </VStack>
        </FieldGroup>
      </Stack>
      <FieldGroup mt="8">
        <HStack width="full">
          <Button
            type="submit"
            colorScheme="blue"
            loadingText="Submitting"
            isLoading={mutationEdit.isLoading}
          >
            {useFormatMessage(`ChangePassword.submits`)}
          </Button>
        </HStack>
      </FieldGroup>
      {/* TODO: Translate */}
      <Text textAlign="center" fontSize="xs">
        {useFormatMessage('UserForm.logoChanges')}
      </Text>
    </form>
  );
};

export default UserForm;
