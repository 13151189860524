import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import PostForm from './PostForm';

const Post = () => {
  const { postId } = useParams<{ postId: string | undefined }>();
  // return <div>{postId}</div>;
  return (
    <Box w="full" h="full" overflowY="auto">
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
        <PostForm postId={postId} />
      </Box>
    </Box>
  );
};

export default Post;
