import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useBreakpointValue
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FaFacebook, FaFacebookMessenger, FaInstagram } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { useConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { useFormatMessage } from '../../../hooks';
import useDeletePost from '../../../hooks/api/mutation/useDeletePost';
import { PostInfoDocument, PostType } from '../../../state/model/firebase';
import paths from '../../Router/paths';

const PostTypeMenu = (props: {
  postInfo: PostInfoDocument | null;
  pageId: string;
  teamId: string;
  canEdit: boolean;
  canDelete: boolean;
}) => {
  const { postInfo, pageId, teamId, canEdit, canDelete } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mutation = useDeletePost();
  // TODO: translate!
  const { ConfirmationModal, onOpen, onClose, setPayload } =
    useConfirmationModal<{
      teamId: string;
      pageId: string;
      postId: string;
      imgUrl: string | undefined;
    }>(
      mutation.isLoading,
      useFormatMessage('Users.delete'),
      'This will permanently delete the post inside TurboReply. Action can not be undone.',
      useFormatMessage('Users.confirm'),
      useFormatMessage('Users.cancel')
    );
  const onPostDeleteHandler = useCallback(
    (values: {
      teamId: string;
      pageId: string;
      postId: string;
      imgUrl: string | undefined;
    }) => {
      mutation.mutate(values, {
        onSuccess: () => {
          onClose();
        },
      });
    },
    [mutation, onClose]
  );
  const onRemoveButtonClickHandler = useCallback(() => {
    setPayload({
      pageId,
      postId: postInfo?.id || '',
      teamId,
      imgUrl: postInfo?.storageImgUrl || undefined,
    });
    onOpen(onPostDeleteHandler);
  }, [onPostDeleteHandler, onOpen, setPayload, postInfo, pageId, teamId]);

  const getMenuList = () => {
    if (!postInfo)
      // Messenger
      return (
        <MenuList>
          {pageId && (
            <MenuItem
              icon={<FaFacebookMessenger />}
              as="a"
              href={`https://www.facebook.com/${pageId}/inbox`}
              target="_blank"
              isDisabled={!isDesktop}
            >
              Open Facebook Messenger
            </MenuItem>
          )}
          <MenuDivider />
          <MenuItem
            icon={<EditIcon />}
            as={NavLink}
            to={paths.EDIT_PAGE}
            isDisabled={!canEdit}
          >
            Edit
          </MenuItem>
        </MenuList>
      );
    switch (postInfo.postType?.toString()) {
      case PostType.AdCreative.toString():
        return (
          <MenuList>
            {!postInfo.isInstagramPost && (
              <MenuItem
                icon={<FaFacebook />}
                as="a"
                href={`https://www.facebook.com/${postInfo.postId}`}
                target="_blank"
              >
                Show Post with Comments
              </MenuItem>
            )}
            {postInfo.permalinkUrl && (
              <MenuItem
                icon={<FaInstagram />}
                as="a"
                href={postInfo.permalinkUrl}
                target="_blank"
              >
                Show Post with Comments
              </MenuItem>
            )}
            <MenuDivider />
            <MenuItem
              icon={<EditIcon />}
              as={NavLink}
              to={paths.EDIT_POST.replace(':postId', postInfo?.id)}
              isDisabled={!canEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              color="red.400"
              icon={<DeleteIcon />}
              onClick={onRemoveButtonClickHandler}
              isDisabled={!canDelete}
            >
              Delete
            </MenuItem>
          </MenuList>
        );
      case PostType.FacebookFeed.toString():
        return (
          <MenuList>
            {postInfo.permalinkUrl && (
              <MenuItem
                icon={<FaFacebook />}
                as="a"
                href={postInfo.permalinkUrl}
                target="_blank"
              >
                Show Post with Comments
              </MenuItem>
            )}
            <MenuDivider />
            <MenuItem
              icon={<EditIcon />}
              as={NavLink}
              to={paths.EDIT_POST.replace(':postId', postInfo?.id)}
              isDisabled={!canEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              color="red.400"
              icon={<DeleteIcon />}
              onClick={onRemoveButtonClickHandler}
              isDisabled={!canDelete}
            >
              Delete
            </MenuItem>
          </MenuList>
        );
      case PostType.InstagramFeed.toString():
        return (
          <MenuList>
            {postInfo.permalinkUrl && (
              <MenuItem
                icon={<FaInstagram />}
                as="a"
                href={postInfo.permalinkUrl}
                target="_blank"
              >
                Show Post with Comments
              </MenuItem>
            )}
            <MenuDivider />
            <MenuItem
              icon={<EditIcon />}
              as={NavLink}
              to={paths.EDIT_POST.replace(':postId', postInfo?.id)}
              isDisabled={!canEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              color="red.400"
              icon={<DeleteIcon />}
              onClick={onRemoveButtonClickHandler}
              isDisabled={!canDelete}
            >
              Delete
            </MenuItem>
          </MenuList>
        );
      default:
        throw new Error('Invalid Post Type');
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FiSettings />}
          variant="outline"
        />
        {getMenuList()}
      </Menu>
      <ConfirmationModal />
    </>
  );
};

export default PostTypeMenu;
