import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { createOrUpdatePostInfo } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { PostInfo, PostInfoDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useEditPost() {
  const { locale } = usePreferences().state;
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (values: {
      teamId: string;
      pageId: string;
      postInfo: PostInfo;
      postId?: string;
    }) =>
      createOrUpdatePostInfo(
        values.teamId,
        values.pageId,
        values.postInfo,
        values.postId
      ),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (newPostInfo, variables) => {
        const previousPostInfos = queryClient.getQueryData([
          variables.pageId,
          'postInfos',
        ]) as PostInfoDocument[];
        if (previousPostInfos) {
          queryClient.setQueryData(
            [variables.pageId, 'postInfos'],
            previousPostInfos.map((postInfo) => {
              if (postInfo.id === newPostInfo.id) return newPostInfo;
              return postInfo;
            })
          );
        } else {
          queryClient.setQueryData(
            [variables.pageId, 'postInfos'],
            [newPostInfo]
          );
        }
        queryClient.invalidateQueries([variables.pageId, 'postInfos']);

        toast({
          description: variables.postId
            ? 'Post modified sucessfully!'
            : 'Post added sucessfully',
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
