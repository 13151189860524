import { useToast } from '@chakra-ui/toast';
import { useMutation } from 'react-query';
import { useFormatMessage } from '../..';
import { deleteUser } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { firebaseError } from '../../../utils';

export default function useDeleteProfile() {
  const { locale } = usePreferences().state;
  const toast = useToast();
  const successMessage = useFormatMessage('Profile.deleteSuccess');

  return useMutation(
    (variables: { userId: string; logoUrl: string | null }) => deleteUser(variables),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: () => {
        toast({
          description: successMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
