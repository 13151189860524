import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import paths from '../../pages/Router/paths';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const SubscriptionModal = (props: SubscriptionModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background="linear-gradient(135deg, #3b5daa 0%, rgba(130,218,245,0.5) 100%)" />
      <ModalContent>
        <ModalHeader>Upgrade your Subscription</ModalHeader>
        <ModalCloseButton />
        {/* TODO: add good marketing */}
        <ModalBody pb={6}>
          <VStack>
            <Text>
              You have exceeded the total number allowed for your current plan.
            </Text>
            <Text>
              TurboReply gives you all the tools for engaging your audience
              through Messenger, Facebook and Instagram.{' '}
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            No thanks
          </Button>
          <Button
            as={NavLink}
            colorScheme="blue"
            onClick={onClose}
            to={paths.SUBSCRIPTION}
          >
            View Pricing
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubscriptionModal;
