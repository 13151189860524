import {
  HStack,
  Icon,
  Link,
  LinkProps,

  Text, useColorModeValue as mode
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { NavLink as RouterLink } from "react-router-dom";

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  icon: IconType;
  to?: string;
  exact?: boolean;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, isActive, label, to, exact, ...rest } = props;
  return (
    <Link
      display="block"
      py="2"
      px="3"
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      fontSize="sm"
      userSelect="none"
      aria-current={isActive ? 'page' : undefined}
      color={mode('gray.700', 'gray.400')}
      _hover={{
        bg: mode('gray.100', 'gray.700'),
        color: mode('gray.900', 'white'),
      }}
      _activeLink={{
        bg: mode('gray.200', 'gray.700'),
        color: 'inherit',
      }}
      as={RouterLink}
      to={to}
      exact={exact}
      {...rest}
    >
      <HStack spacing="4">
        <Icon as={icon} fontSize="lg" opacity={0.64} />
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  );
};
