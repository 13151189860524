import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getProfileTeams } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { MembershipDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchProfileTeams = async (
  userId: string
): Promise<MembershipDocument[]> => {
  const response = await getProfileTeams(userId);
  return response;
};

export const useProfileTeams = (userId: string, enabled: boolean) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  return useQuery('profileTeams', () => fetchProfileTeams(userId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
