import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getFacebookPages } from '../../../api/firebase';
import {
  setCurrentPageId,
  usePreferences
} from '../../../state/context/PreferencesContext';
import { FacebookPage } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchFacebookPages = async (teamId: string): Promise<FacebookPage[]> => {
  const response = getFacebookPages(teamId);
  return response;
};

  // TODO: Future Feature: Enable refetch again and fetch only updated docs after init fetch
export const useFacebookPages = (enabled: boolean, teamId: string) => {
  const toast = useToast();
  const { state, dispatch } = usePreferences();
  const { locale, currentPageId } = state;

  return useQuery('facebookPages', () => fetchFacebookPages(teamId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
    onSuccess: (newPages) => {
      // Init current page
      if (!currentPageId && newPages.length > 0) {
        setCurrentPageId(dispatch, newPages[0].id);
      }
    },
  });
};
