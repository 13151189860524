import { useToast } from '@chakra-ui/toast';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useState } from 'react';
import { analytics, firestore, functions } from '../../firebase';
import paths from '../../pages/Router/paths';
import { SubscriptionRole } from '../../state/model/firebase';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const useStripe = () => {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function subscribe(
    uid: string,
    priceId: string,
    unitamount: number,
    name: SubscriptionRole,
    yearly: boolean
  ) {
    try {
      // google analytics
      analytics.logEvent('begin_checkout', {
        value: unitamount,
        currency: 'USD',
        transaction_id: undefined,
        items: [
          {
            item_id: priceId,
            item_name: name,
            item_variant: yearly ? 'Yearly' : 'Monthly',
          },
        ],
      });

      setLoading(true);
      const docRef = await firestore
        .collection('customers')
        .doc(uid)
        .collection('checkout_sessions')
        .add({
          allow_promotion_codes: true,
          price: priceId,
          success_url:
            window.location.origin +
            paths.THANK_YOU.replace(':price', unitamount.toString())
              .replace(':priceId', priceId)
              .replace(':name', name)
              .replace(':yearly', yearly ? '1' : '0'),
          cancel_url: window.location.origin + paths.SUBSCRIPTION,
        });

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data() as any;
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          throw new Error(error.message);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const stripe = await loadStripe(publishableKey!);
          if (!stripe) throw new Error('Could not access Stripe');
          stripe.redirectToCheckout({ sessionId });
          setLoading(false);
        }
      });
    } catch (error) {
      toast({
        description: `${error.message}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
  }

  async function sendToCustomerPortal() {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const stripe = await loadStripe(publishableKey!);
      if (!stripe) throw new Error('Could not access Stripe');
      const functionRef = functions.httpsCallable(
        'ext-firestore-stripe-subscriptions-createPortalLink'
      );
      const { data } = await functionRef({ returnUrl: window.location.origin + paths.BILLING });
      window.location.assign(data.url);
      setLoading(false);
    } catch (error) {
      toast({
        description: `${error.message}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
    }
  }

  return {
    isLoading,
    subscribe,
    sendToCustomerPortal,
  };
};

export default useStripe;
