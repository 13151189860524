import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFormatMessage } from '../..';
import { removeUserFromTeam } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { MembershipDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useDeleteMember() {
  const queryClient = useQueryClient();

  const { locale } = usePreferences().state;
  const toast = useToast();
  const sucessMessage = useFormatMessage('Team.removeSuccess');

  return useMutation(
    (variables: { teamId: string; userId: string }) => removeUserFromTeam(variables.teamId, variables.userId),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (deletedUserId) => {
        const previousUsers = queryClient.getQueryData(
          'members'
        ) as MembershipDocument[];
        if (previousUsers)
          queryClient.setQueryData(
            'members',
            previousUsers.filter((user) => user.id !== deletedUserId)
          );
        queryClient.invalidateQueries('members');

        toast({
          description: sucessMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
