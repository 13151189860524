// TurboReply

// Mutation Models
export interface User {
  readonly name: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly logoUrl: string | null;
  readonly hasFacebook: boolean;
  readonly createdAt?: any;
  readonly updatedAt?: any;
}
export interface Membership {
  readonly logoUrl: string | null;
  readonly name: string;
  readonly email: string;
  readonly teamName: string;
  readonly teamId: string;
  readonly isOwner: boolean;
  readonly canDelete: boolean;
  readonly canEdit: boolean;
  readonly canProcess: boolean;
  readonly createdAt?: any;
  readonly updatedAt?: any;
}
export interface Team {
  readonly name: string;
  readonly createdAt?: any;
  readonly updatedAt?: any;
}

export interface Product {
  readonly active: boolean;
  readonly description: string | null;
  readonly images: string[];
  readonly name: string;
  readonly role: string | null;
}
export interface Price {
  readonly active: boolean;
  readonly description: string | null;
  readonly currency: string;
  // eslint-disable-next-line camelcase
  readonly unit_amount: number;
}

export interface FacebookPage {
  readonly id: string;
  readonly name: string;
  readonly img: string;
  readonly instagramBusinessAccountId: string | null;
  readonly adAccountId: string | null;
  readonly pageRuleId: string | null;
  readonly ignoreList: string[];
  readonly createdAt?: any;
  readonly updatedAt?: any;
}

export enum PostType {
  AdCreative = 0,
  FacebookFeed = 1,
  InstagramFeed = 2,
}

export interface PostInfo {
  readonly postType: PostType;
  readonly postId: string;
  readonly effectiveInstagramPostId: string | null;
  readonly pictureUrl: string | null;
  readonly permalinkUrl: string | null;
  readonly replyRuleId: string;
  readonly ignore: boolean;
  readonly ignoreList: string[];
  readonly isInstagramPost: boolean;
  readonly createdAt?: any;
  readonly updatedAt?: any;
}

export interface ReplyRule {
  readonly ruleName: string;
  readonly replyMessages: ReplyChildRule[];
  readonly createdAt?: any;
  readonly updatedAt?: any;
}
export interface ReplyChildRule {
  readonly id: string;
  readonly commentRule: CommentRule;
  readonly filterRule: FilterRule;
  readonly commentRuleTags: RuleTag[];
  readonly filterRuleTags: RuleTag[];
  readonly replyMessage: string;
  readonly likeComment: boolean;
}
export interface RuleTag {
  readonly id: string;
  readonly text: string;
}

export enum CommentRule {
  default = 0,
  containsAny = 1,
  containsAll = 2,
}

export enum FilterRule {
  default = 0,
  containsAny = 1,
  containsAll = 2,
}

export type RuleFieldName =
  | 'replyMessage'
  | 'likeComment'
  | 'commentRule'
  | 'filterRule'
  | 'commentRuleTags'
  | 'filterRuleTags';

// Query Models
export interface UserDocument extends User {
  readonly id: string;
  readonly createdAt?: firebase.default.firestore.Timestamp;
  readonly updatedAt?: firebase.default.firestore.Timestamp;
}
export interface MembershipDocument extends Membership {
  readonly id: string;
  readonly stripeRole: SubscriptionRole;
  readonly stripeStatus: SubscriptionStatus;
  readonly createdAt?: firebase.default.firestore.Timestamp;
  readonly updatedAt?: firebase.default.firestore.Timestamp;
}
export interface ProductDocument extends Product {
  readonly id: string;
  readonly prices: PriceDocument[];
}
export interface PriceDocument extends Price {
  readonly id: string;
}

export type SubscriptionRole = 'none' | 'basic' | 'pro' | 'agency';
export type SubscriptionStatus = 'inactive' | 'active' | 'trialing';

export interface Item {
  readonly price: Price;
}
export interface SubscriptionDocument {
  readonly id: string;
  readonly role: SubscriptionRole;
  readonly status: SubscriptionStatus;
  readonly cancelAt: firebase.default.firestore.Timestamp | null;
  readonly created: firebase.default.firestore.Timestamp;
  readonly currentPeriodEnd: firebase.default.firestore.Timestamp;
  readonly items: Item[];
}

export interface ReplyRuleDocument extends ReplyRule {
  readonly id: string;
  readonly createdAt?: firebase.default.firestore.Timestamp;
  readonly updatedAt?: firebase.default.firestore.Timestamp;
}

export interface PostInfoDocument extends PostInfo {
  readonly id: string;
  readonly storageImgUrl: string | null;
  readonly createdAt?: firebase.default.firestore.Timestamp;
  readonly updatedAt?: firebase.default.firestore.Timestamp;
}

export enum Platform {
  Facebook = 0,
  Instagram = 1,
  Messenger = 2,
}

export interface ReviewTask {
  readonly postId: string;
  readonly pageToken: string;
  readonly platform: Platform;
  readonly img: any;
  readonly imgfallback: any;
  readonly userMessage: {
    readonly parent: UserMessage;
    readonly children: UserMessage[];
  };
  readonly responseTask: ResponseTask;
}

export interface ResponseTask {
  readonly replyText: string;
  readonly likeComment: boolean;
  readonly ruleName: string;
  readonly replyRuleId: string;
  readonly childRuleId: string;
}

export interface UserMessage {
  readonly id: string;
  readonly fromName: string;
  readonly fromId: string;
  readonly message: string;
  readonly dateTime: Date;
  readonly permalink: string;
}

export interface Stats {
  readonly countField: string;
  readonly pageId: string;
  readonly postInfoId: string;
  readonly replyRuleId: string;
  readonly pageCount: number;
  readonly postCount: number;
  readonly replyRuleCount: number;
  readonly memberCount: number;
  readonly replyCount: number;
  readonly monthlyReplyCount: number;
  readonly nextMonthlyPeriodEnd: firebase.default.firestore.Timestamp | null;
  readonly hourlyRequestCount: number;
  readonly nextHourlyPeriodEnd: firebase.default.firestore.Timestamp | null;
  readonly createdAt?: firebase.default.firestore.Timestamp;
  readonly updatedAt?: firebase.default.firestore.Timestamp;
}
