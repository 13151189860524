import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getCustomerSubscription } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { SubscriptionDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchSubscriptions = async (
  userId: string
): Promise<SubscriptionDocument[]> => {
  const response = getCustomerSubscription(userId);
  return response;
};

export const useSubscriptions = (enabled: boolean, userId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  return useQuery('subscriptions', () => fetchSubscriptions(userId), {
    enabled,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
