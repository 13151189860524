import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Spinner,
  Text
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { RiPlayCircleLine } from 'react-icons/ri';
import { Redirect } from 'react-router-dom';
import { useFacebookPages } from '../../../hooks/api/query/useFacebookPages';
import useReviewTasks from '../../../hooks/api/query/useReviewTasks';
import { usePreferences } from '../../../state/context/PreferencesContext';
import paths from '../../Router/paths';
import ReviewCard from './ReviewCard';

const audioTune = new Audio(`${process.env.PUBLIC_URL}/long-pop.mp3`);

const Inbox = () => {
  const { state } = usePreferences();
  const [amountLoading, setAmountLoading] = useState(0);
  const { data: fbPages, isLoading: isLoadingPages } = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentFbPage = fbPages?.find((p) => p.id === state.currentPageId);
  const {
    data: reviewTasks,
    isLoading,
    isSuccess,
    refetch,
    remove,
  } = useReviewTasks(
    false,
    state.currentTeamId || '',
    state.currentPageId || '',
    currentFbPage?.instagramBusinessAccountId || undefined,
    currentFbPage?.pageRuleId || undefined,
    currentFbPage?.ignoreList || []
  );

  useEffect(() => {
    try {
      // audioTune.load();
    } catch (error) {
      // ignore
    }
  }, []);

  const playSound = () => {
    try {
      audioTune.play();
    } catch (error) {
      // ignore.
    }
  };

  const ReviewCards = useMemo(() => {
    return (
      <>
        {!isLoading &&
          (state?.currentPageId || '') !== '' &&
          (state?.currentTeamId || '') !== '' &&
          !!currentFbPage &&
          reviewTasks &&
          reviewTasks.map((t) => (
            <ReviewCard
              pageId={state.currentPageId || ''}
              teamId={state.currentTeamId || ''}
              currentPage={currentFbPage}
              reviewTask={t}
              key={t.userMessage.parent.id}
              onOk={playSound}
              handleOnReqStarted={() =>
                setAmountLoading((amount) => amount + 1)
              }
              handleOnReqFinished={() =>
                setAmountLoading((amount) => amount - 1)
              }
            />
          ))}
        <Box m="4">
          <Button
            fontSize="lg"
            fontWeight="bold"
            colorScheme="blue"
            w="full"
            loadingText="Please wait..."
            leftIcon={<RiPlayCircleLine />}
            isLoading={isLoading}
            onClick={() => {
              remove();
              refetch();
            }}
          >
            Start
          </Button>
          {/* TODO: Future Feature */}
          {/* <Text fontWeight="light" fontSize="sm">
              Last time checked: 05.05.2021 21:00
            </Text> */}
          {isSuccess && reviewTasks && reviewTasks.length < 1 && (
            <Flex justify="center" align="center" my="6">
              <Text>No open replies left.</Text>
              <Text fontSize="3xl">☕</Text>
            </Flex>
          )}
        </Box>
      </>
    );
  }, [
    isLoading,
    isSuccess,
    reviewTasks,
    currentFbPage,
    state,
    remove,
    refetch,
  ]);

  const redirect = !isLoadingPages && fbPages && fbPages.length <= 0 && (
    <Redirect to={paths.ADD_PAGE} />
  );

  return (
    <>
      {redirect}
      {/* 99% Because of mobile bug */}
      <Box flex="1" h="99%">
        <Flex justify="space-between" px="4">
          <Heading fontWeight="normal" fontSize={{ base: 'lg', lg: '2xl' }}>
            Review message...
          </Heading>
          <HStack align="center">
            {amountLoading > 0 && (
              <>
                <Text fontSize="xs">Sending {amountLoading}...</Text>
                <Spinner size="xs" />
              </>
            )}
          </HStack>
          <Heading fontWeight="normal" fontSize={{ base: 'lg', lg: '2xl' }}>
            ...then reply
          </Heading>
        </Flex>
        <Divider />
        <Box
          h="90%"
          pb="44"
          overflowY="auto"
          overflowX="hidden"
          position="relative"
        >
          {ReviewCards}
        </Box>
      </Box>
    </>
  );
};

export default Inbox;
