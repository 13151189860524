import { Flex, FlexProps, Heading, HStack, Icon, useColorModeValue as mode } from '@chakra-ui/react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { PageDropdown } from '../PageDropdown';

const BreadCrumb = (props: FlexProps) => {
  return (
    <Flex
      w="full"
      flexDirection="column"
      align="center"
      // bg="blue.500"
      // color="white"
      borderBottom='1px'
      borderColor={mode('gray.200', 'gray.700')}
      px="6"
      py="2"
      h="16"
      {...props}
    >
      <Flex justify="space-between" align="center" w="full" h="full">
        <HStack>
          <Heading fontSize="xl" fontWeight="normal">
            Replies
          </Heading>
          <Icon as={RiArrowRightSLine} w="6" h="6"/>
          <PageDropdown />
        </HStack>
      </Flex>
    </Flex>
  );
};

export default BreadCrumb;
