import { Redirect } from 'react-router-dom';
import { auth } from '../../firebase';
import { useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';
import EmailLink from './EmailLink';
import LoginForm from './LoginForm';

const Login = () => {
  const { currentUser } = useAuth();
  const isAuth = !!currentUser?.uid;
  const rootRedirect = isAuth && <Redirect to={paths.ROOT} />;

  const isEmailLink = auth.isSignInWithEmailLink(window.location.href);

  return (
    <>
      {rootRedirect}
      {isEmailLink ? <EmailLink /> : <LoginForm />}
    </>
  );
};

export default Login;
