import { Flex, useBreakpointValue } from '@chakra-ui/react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import BreadCrumb from './TopBar/BreadCrumb';

const Home = () => {
  // To reduce bloat
  const device = useBreakpointValue({ base: 'mobile', lg: 'desktop' });

  return (
    <Flex w="full" flexDirection="column">
      <BreadCrumb />
      {device === 'desktop' ? <Desktop /> : <Mobile />}
    </Flex>
  );
};

export default Home;
