import {
  Box,
  Button,
  Flex,
  Heading,

  SimpleGrid,
  Text,
  useColorModeValue as mode,
  VisuallyHidden
} from '@chakra-ui/react';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { SigninForm } from '../../components/Authentication/SignInForm/SignInForm';
import { DividerWithText } from '../../components/DividerWithText/DividerWithText';
import MyLogo from '../../components/Icons/MyLogo';
import { UnderlineLink } from '../../components/UnderlineLink/UnderlineLink';
import useAuthProfile from '../../hooks/api/mutation/useAuthProfile';
import { signInWithGoogle, useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';

const LoginForm = () => {
  const { signInWithFacebook } = useAuth();
  const { isLoading, error, mutate } = useAuthProfile();
  const errorMessage = error ? `${error}` : null;

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      overflow="hidden"
      minH="100vh"
      height="100%"
      bg={mode('gray.50', 'inherit')}
    >
      <Box
        overflowY="auto"
        flex="1"
        py={{ base: '10', md: '16' }}
        px={{ base: '6', md: '10' }}
      >
        <Box maxW="sm" mx="auto">
          <MyLogo
            mb={{ base: '14', md: '32' }}
            w="auto"
            h="8"
            mx="auto"
            iconColor="blue.500"
          />
          <Box textAlign="center" mb={{ base: '10', md: '16' }}>
            <Heading
              as="h1"
              size="xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Sign in to your account
            </Heading>
            <Text
              mt="3"
              color={mode('gray.600', 'gray.400')}
              fontWeight="medium"
            >
              Need an account?{' '}
              <UnderlineLink to={paths.SIGNUP}>Sign up for free</UnderlineLink>
            </Text>
          </Box>
          <SigninForm />
          <DividerWithText mt="6">or continue with</DividerWithText>
          <SimpleGrid mt="6" columns={2} spacing="2">
            <Button
              color="currentColor"
              variant="outline"
              isLoading={isLoading}
              onClick={() => mutate(signInWithFacebook)}
            >
              <VisuallyHidden>Login with Facebook</VisuallyHidden>
              <FaFacebook />
            </Button>
            <Button
              color="currentColor"
              variant="outline"
              isLoading={isLoading}
              onClick={() => mutate(signInWithGoogle)}
            >
              <VisuallyHidden>Login with Google</VisuallyHidden>
              <FaGoogle />
            </Button>
          </SimpleGrid>
          {errorMessage ? (
            <Text mt="3" color={mode('red.600', 'red.400')} fontWeight="medium">
              {errorMessage}
            </Text>
          ) : null}
        </Box>
      </Box>

      <Box
        display={{ base: 'none', lg: 'block' }}
        flex="1"
        bg="blue.500"
        color="white"
        px="20"
        pt="32"
      >
        {/* <Badge
          bg="blue.700"
          px="4"
          py="1"
          rounded="md"
          letterSpacing="wide"
          color="whiteAlpha.900"
        >
          New and Improved
        </Badge> */}
        <Text
          mt="6"
          fontWeight="extrabold"
          fontSize={{ base: '2xl', lg: '3xl' }}
          maxW="sm"
          letterSpacing="tight"
          lineHeight="normal"
        >
          Welcome back to TurboReply!
        </Text>
        <Text mt="5" maxW="md" fontSize="lg">
          Sign in to TurboReply.
        </Text>
        <Box mt="10" position="relative" insetEnd="6">
          {/* TODO: add screenshot */}
          {/* <Img
            alt="App screenshot"
            src="https://res.cloudinary.com/chakra-ui-pro/image/upload/v1621082943/pro-website/screenshot-dark_w6jpks.png"
          /> */}
        </Box>
      </Box>
    </Flex>
  );
};

export default LoginForm;
