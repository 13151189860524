import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getFbAdAccounts } from '../../../api/firebase';

export default function useFacebookAdAccounts(enabled: boolean, teamId: string) {
  const toast = useToast();
  return useQuery('fbadaccounts', () => getFbAdAccounts(teamId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onError: (error: Error) => {
      toast({
        description: `${error}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
}
