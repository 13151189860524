import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { UserDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';
import { fetchDocument } from '../../../utils/firestore';

const fetchUser = async (userId: string): Promise<UserDocument> => {
  const response = await fetchDocument('users', userId);
  return response as UserDocument;
};

export const useProfile = (enabled: boolean, userId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  // TODO: Future Feature: Enable refetch again and fetch only updated docs after init fetch
  return useQuery('profile', () => fetchUser(userId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
