import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import AppInstall1Android from '../../assets/install-app-1-android.jpg';
import AppInstall1 from '../../assets/install-app-1.jpg';
import AppInstall2Android from '../../assets/install-app-2-android.jpg';
import AppInstall2 from '../../assets/install-app-2.jpg';
import AppInstall3Android from '../../assets/install-app-3-android.jpg';
import AppInstall3 from '../../assets/install-app-3.jpg';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const InstallAppModal = (props: ModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How To Install The App</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Android</Tab>
              <Tab>iOS</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack>
                  <Text w="full">
                    Install the app on <Text as="b">iOS</Text> or{' '}
                    <Text as="b">Android</Text> for a better user experience.
                  </Text>
                  <Text w="full">
                    Navigate to <Text as="b">app.turboreply.com</Text> on your
                    mobile device and click the{' '}
                    <Text as="b">&apos;Add to Home Screen&apos;</Text> Button.
                  </Text>
                  <Text w="full">
                    For instructions, follow the steps in the pictures.
                  </Text>
                  <Image src={AppInstall1Android} />
                  <Image src={AppInstall2Android} />
                  <Image src={AppInstall3Android} />
                </VStack>
              </TabPanel>
              <TabPanel>
                <VStack>
                  <Text w="full">
                    Install the app on <Text as="b">iOS</Text> or{' '}
                    <Text as="b">Android</Text> for a better user experience.
                  </Text>
                  <Text w="full">
                    Navigate to <Text as="b">app.turboreply.com</Text> on your
                    mobile device and click the{' '}
                    <Text as="b">&apos;Add to Home Screen&apos;</Text> Button.
                  </Text>
                  <Text w="full">
                    For instructions, follow the steps in the pictures.
                  </Text>
                  <Image src={AppInstall1} />
                  <Image src={AppInstall2} />
                  <Image src={AppInstall3} />
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InstallAppModal;
