import { DeleteIcon, EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiHide } from 'react-icons/bi';
import { BsChevronDown, BsThreeDots } from 'react-icons/bs';
import {
  FaFacebook,
  FaFacebookMessenger,
  FaInstagram,
  FaRegThumbsUp
} from 'react-icons/fa';
import { MdDone } from 'react-icons/md';
import Card from '../../../components/Card/Card';
import SubscriptionModal from '../../../components/Modal/SubscriptionModal';
import ReplyRuleModal from '../../../components/ReplyRule/ReplyRuleModal';
import TextBubble from '../../../components/Text/TextBubble';
import { useFormInput } from '../../../hooks';
import useDeleteComment from '../../../hooks/api/mutation/useDeleteComment';
import useHideComment from '../../../hooks/api/mutation/useHideComment';
import useIgnoreComment from '../../../hooks/api/mutation/useIgnoreComment';
import useIgnoreMessage from '../../../hooks/api/mutation/useIgnoreMessage';
import useProcessComment from '../../../hooks/api/mutation/useProcessComment';
import { usePostInfos } from '../../../hooks/api/query/usePostInfos';
import {
  FacebookPage,
  Platform,
  ReviewTask
} from '../../../state/model/firebase';
import ReplyTextModal from './ReplyTextModal';

interface ReviewCardProps {
  reviewTask: ReviewTask;
  teamId: string;
  pageId: string;
  currentPage: FacebookPage;
  onOk: () => void;
  handleOnReqStarted: () => void;
  handleOnReqFinished: () => void;
}
const ReviewCard = (props: ReviewCardProps) => {
  const {
    reviewTask,
    teamId,
    pageId,
    currentPage,
    onOk,
    handleOnReqStarted,
    handleOnReqFinished,
  } = props;
  const replyMutation = useProcessComment(handleOnReqFinished);
  const deleteMutation = useDeleteComment(handleOnReqFinished);
  const hideMutation = useHideComment(handleOnReqFinished);
  const ignoreCommentMutation = useIgnoreComment(handleOnReqFinished);
  const ignoreMessageMutation = useIgnoreMessage(handleOnReqFinished);

  const { data: postInfos } = usePostInfos(true, teamId, pageId);

  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: true,
  });
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();
  const {
    platform,
    img,
    imgfallback,
    responseTask,
    userMessage,
    pageToken,
    postId,
  } = reviewTask;
  const { parent, children } = userMessage;
  const { likeComment, replyText, replyRuleId, ruleName } = responseTask;

  const [showAll, setShowAll] = useState(false);
  const [like, setLike] = useState(likeComment);
  const ReplyTextInput = useFormInput(replyText);
  const ReplyTextProps = ReplyTextInput.InputProps;

  const handleModalClose = (text?: string) => {
    if (text) {
      let name = '';
      if (children.length > 0) name = children[children.length - 1].fromName;
      else name = parent.fromName;
      const newText = text.replace('/{{username}}/g', name);
      ReplyTextInput.setValue(newText);
    }
    onModalClose();
  };

  const mutateOption = {
    onError: () => {
      onOpen();
    },
  };

  const handleOk = () => {
    onOk();
    onClose();
    handleOnReqStarted();
    let commentId: string | undefined = parent.id;
    // search id which is not equal to page id.
    if (platform === Platform.Messenger) {
      commentId =
        children.length > 0
          ? children.find((c) => c.fromId !== currentPage.id)?.fromId
          : parent.fromId;
      if (!commentId) commentId = parent.fromId; // should not happen
    }

    replyMutation.mutate(
      {
        pageId,
        teamId,
        commentId,
        pageToken,
        platform,
        replyText: ReplyTextInput.value,
        shouldLike: like,
      },
      {
        onError: (error: Error) => {
          // TODO: error message correct?
          if (error.message === 'auth/max-reply-reached') {
            onLimitOpen();
          }
          onOpen();
        },
      }
    );
  };

  const handleDeleteComment = (commentId: string) => {
    onClose();
    handleOnReqStarted();
    deleteMutation.mutate(
      {
        pageId,
        teamId,
        commentId,
        pageToken,
      },
      mutateOption
    );
  };

  const handleHideComment = (commentId: string) => {
    onClose();
    handleOnReqStarted();
    hideMutation.mutate(
      {
        pageId,
        teamId,
        commentId,
        pageToken,
        platform,
      },
      mutateOption
    );
  };

  const handleIgnoreComment = () => {
    onClose();
    handleOnReqStarted();
    const commentId =
      children.length > 0 ? children[children.length - 1].id : parent.id;
    if (platform === Platform.Messenger) {
      ignoreMessageMutation.mutate(
        {
          pageId,
          teamId,
          page: currentPage,
          commentId,
        },
        mutateOption
      );
      return;
    }
    const currentPost = postInfos?.find((p) => p.postId === postId);
    if (!currentPost) {
      console.error('Current post not found');
      return;
    }

    ignoreCommentMutation.mutate(
      {
        pageId,
        teamId,
        postInfo: currentPost,
        commentId,
      },
      mutateOption
    );
  };

  let okText = isOpen && !replyMutation.isLoading ? 'OK' : '🚀';
  if (replyMutation.isError) okText = 'Retry';

  let icon = <FaFacebook />;
  if (platform === Platform.Instagram) icon = <FaInstagram />;
  if (platform === Platform.Messenger) icon = <FaFacebookMessenger />;

  return (
    <>
      <Collapse in={isOpen} animateOpacity>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent="center"
          my={{ base: '12', xl: '16' }}
        >
          <Box mt="2" w="full" maxW="96" alignSelf="center">
            <HStack spacing="2" align="flex-start" mx="2">
              <Box w="16">
                <Avatar
                  w="full"
                  h="full"
                  borderRadius={
                    platform === Platform.Messenger ? 'full' : 'none'
                  }
                  src={imgfallback}
                >
                  <AvatarBadge bgColor="white" borderColor="white">
                    {icon}
                  </AvatarBadge>
                </Avatar>
              </Box>
              <VStack>
                <TextBubble
                  key={parent.id}
                  heading={parent.fromName}
                  text={parent.message}
                />
                {/* eslint-disable-next-line no-nested-ternary */}
                {children.length + 1 > 2 && !showAll ? (
                  <Button
                    variant="ghost"
                    size="xs"
                    onClick={() => setShowAll(true)}
                  >
                    View {children.length - 1} more replies
                  </Button>
                ) : showAll ? (
                  <Button
                    variant="ghost"
                    size="xs"
                    onClick={() => setShowAll(false)}
                  >
                    Hide {children.length - 1} Replies
                  </Button>
                ) : null}
              </VStack>
              <Box textAlign="end">
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<BsThreeDots />}
                    variant="ghost"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<ExternalLinkIcon />}
                      as="a"
                      href={parent.permalink}
                      target="_blank"
                    >
                      {`Show ${
                        platform !== Platform.Messenger ? 'Comment' : 'Message'
                      }`}
                    </MenuItem>
                    <Divider />
                    <MenuItem icon={<MdDone />} onClick={handleIgnoreComment}>
                      Mark as done
                    </MenuItem>
                    {platform !== Platform.Messenger && (
                      <MenuItem
                        icon={<BiHide />}
                        onClick={() => handleHideComment(parent.id)}
                      >
                        Hide comment
                      </MenuItem>
                    )}
                    {platform !== Platform.Messenger && (
                      <MenuItem
                        color="red.400"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteComment(parent.id)}
                      >
                        Delete comment
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
            <VStack>
              {children.length > 1 && !showAll ? (
                <Flex>
                  <Box w="40" />
                  <TextBubble
                    heading={children[children.length - 1].fromName}
                    text={children[children.length - 1].message}
                  />
                </Flex>
              ) : (
                children.map((m) => (
                  <Flex key={m.id}>
                    <Box w="40" />
                    <TextBubble
                      key={m.id}
                      heading={m.fromName}
                      text={m.message}
                    />
                  </Flex>
                ))
              )}
            </VStack>
          </Box>
          <Flex
            alignItems="center"
            alignSelf={{ base: 'center', xl: 'flex-end' }}
            ml="2"
            w="full"
          >
            <Button
              colorScheme={!replyMutation.isError ? 'blue' : 'red'}
              size="lg"
              onClick={handleOk}
              disabled={replyMutation.isLoading}
            >
              {okText}
            </Button>
            <Card w="full" p="4">
              <Textarea minH="40" {...ReplyTextProps} />
              <Flex justify="space-between">
                <Checkbox
                  defaultChecked={like}
                  checked={like}
                  onChange={(e) => setLike(e.target.checked)}
                  isDisabled={platform !== Platform.Facebook}
                  opacity={platform === Platform.Facebook ? 'initial' : '0'}
                >
                  <FaRegThumbsUp />
                </Checkbox>
                <ButtonGroup size="sm" isAttached variant="outline">
                  <Button
                    mr="-px"
                    disabled={!replyRuleId}
                    onClick={onModalOpen}
                  >
                    <Text
                      color={replyRuleId ? 'inherit' : 'red.400'}
                      isTruncated
                      w="24"
                      mr="0.5"
                    >
                      {ruleName.length > 0 ? ruleName : 'Rule deleted'}
                    </Text>
                    <BsChevronDown />
                  </Button>
                  <IconButton
                    aria-label="Edit Rule"
                    icon={<EditIcon />}
                    disabled={!replyRuleId}
                    onClick={onEditOpen}
                  />
                </ButtonGroup>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Collapse>
      <ReplyTextModal
        ruleName={ruleName}
        replyRuleId={replyRuleId}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      />
      <ReplyRuleModal
        ruleId={replyRuleId}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
      <SubscriptionModal isOpen={isLimitOpen} onClose={onLimitClose} />
    </>
  );
};

export default ReviewCard;
