import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { useFormatMessage } from '../..';
import { inviteUser } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { MembershipDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useInviteMember() {
  const { locale } = usePreferences().state;
  const toast = useToast();
  const sucessMessage = useFormatMessage('Team.inviteSuccess');
  const queryClient = useQueryClient();

  return useMutation(
    (variables: {
      email: string;
      canEdit: boolean;
      canDelete: boolean;
      canProcess: boolean;
    }) =>
      inviteUser(
        variables.email,
        variables.canDelete,
        variables.canEdit,
        variables.canProcess
      ),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: (_, error, variables) => {
        if (error) return;

        const newMember: MembershipDocument = {
          email: variables.email,
          canDelete: variables.canDelete,
          canEdit: variables.canEdit,
          canProcess: variables.canProcess,
          id: '',
          isOwner: false,
          logoUrl: null,
          name: variables.email,
          stripeRole: 'none',
          stripeStatus: 'inactive',
          teamId: '',
          teamName: '',
        };

        // update members
        const previousMembers = queryClient.getQueryData(
          'members'
        ) as MembershipDocument[];
        if (previousMembers) {
          const newMembers = [...previousMembers];
          newMembers.push(newMember);
          queryClient.setQueryData('members', [...newMembers]);
        } else {
          queryClient.setQueryData('members', [newMember]);
        }
        queryClient.invalidateQueries('members');

        toast({
          description: sucessMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
