import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { FieldGroup } from '../../components/FormLayout/FieldGroup/FieldGroup';
import { useConfirmationModal } from '../../components/Modal/ConfirmationModal';
import SubscriptionModal from '../../components/Modal/SubscriptionModal';
import ReplyRuleModal from '../../components/ReplyRule/ReplyRuleModal';
import { useFormatMessage } from '../../hooks';
import useDeleteReplyRule from '../../hooks/api/mutation/useDeleteReplyRule';
import useEditReplyRule from '../../hooks/api/mutation/useEditReplyRule';
import { useReplyRules } from '../../hooks/api/query/useReplyRules';
import usePermission from '../../hooks/Permission/usePermission';
import { usePreferences } from '../../state/context/PreferencesContext';
import { ReplyRule } from '../../state/model/firebase';
import SettingsCard from '../Home/Settings/SettingsCard';

const ReplyRules = () => {
  const { state } = usePreferences();
  const { data: replyRules, isLoading } = useReplyRules(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const { canEdit, canDelete, subscriptionLimits } = usePermission();

  const [currentRuleId, setCurrentRuleId] = useState<string | undefined>();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();

  const createMutation = useEditReplyRule();
  const deleteMutation = useDeleteReplyRule();
  // TODO: translate!
  const {
    ConfirmationModal,
    onOpen,
    onClose,
    setPayload,
  } = useConfirmationModal<{ teamId: string; replyRuleId: string }>(
    deleteMutation.isLoading,
    useFormatMessage('Users.delete'),
    'This will permanently delete the reply rule. Action can not be undone.',
    useFormatMessage('Users.confirm'),
    useFormatMessage('Users.cancel')
  );
  const onPostDeleteHandler = useCallback(
    (values: { teamId: string; replyRuleId: string }) => {
      deleteMutation.mutate(values, {
        onSuccess: () => {
          onClose();
        },
      });
    },
    [deleteMutation, onClose]
  );

  const onRemoveButtonClickHandler = useCallback(
    (replyRuleId: string) => {
      setPayload({ teamId: state.currentTeamId || '', replyRuleId });
      onOpen(onPostDeleteHandler);
    },
    [onPostDeleteHandler, onOpen, setPayload, state]
  );

  const onDuplicateHandler = (id: string) => {
    if (replyRules && replyRules?.length >= subscriptionLimits.MAX_RULES) {
      onLimitOpen();
      return;
    }
    if (replyRules && replyRules.length > 0) {
      const currentRule = replyRules.find((r) => r.id === id);
      if (!currentRule) {
        console.error('Current rule not found!');
        return;
      }
      const newRule: ReplyRule = {
        ruleName: `${currentRule.ruleName} - Copy`,
        replyMessages: currentRule.replyMessages,
      };
      createMutation.mutate({
        teamId: state.currentTeamId || '',
        replyRule: newRule,
      });
    }
  };

  const onAddHandler = () => {
    if (replyRules && replyRules?.length < subscriptionLimits.MAX_RULES)
      onAddOpen();
    // open upgrade modal
    else onLimitOpen();
  };

  return (
    <>
      <Box w="full" h="full" overflowY="auto">
        <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
          <Stack spacing="4" divider={<StackDivider />}>
            <Heading size="lg" as="h1" paddingBottom="4">
              Manage Rules
            </Heading>
            <FieldGroup title="Your Rules">
              <VStack width="full" spacing="4" align="start">
                <Button
                  alignSelf={{ base: 'flex-start', md: 'flex-end' }}
                  size="sm"
                  variant="outline"
                  leftIcon={<AddIcon />}
                  onClick={onAddHandler}
                  isDisabled={!canEdit}
                >
                  New Rule
                </Button>
                {!isLoading &&
                  replyRules &&
                  replyRules.map((r) => (
                    <SettingsCard
                      key={r.id}
                      title={r.ruleName}
                      img=""
                      w="full"
                      menu={
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            disabled={
                              deleteMutation.isLoading ||
                              createMutation.isLoading ||
                              (!canEdit && !canDelete)
                            }
                            aria-label="Options"
                            icon={<FiSettings />}
                            variant="outline"
                          />
                          <MenuList>
                            {canEdit && (
                              <>
                                <MenuItem
                                  icon={<EditIcon />}
                                  onClick={() => {
                                    setCurrentRuleId(r.id);
                                    onEditOpen();
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  icon={<HiOutlineDuplicate />}
                                  onClick={() => onDuplicateHandler(r.id)}
                                >
                                  Duplicate
                                </MenuItem>
                              </>
                            )}
                            {canDelete && (
                              <MenuItem
                                icon={<DeleteIcon />}
                                color="red.400"
                                onClick={() => onRemoveButtonClickHandler(r.id)}
                              >
                                Delete
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      }
                    />
                  ))}
              </VStack>
            </FieldGroup>
          </Stack>
        </Box>
      </Box>
      <ReplyRuleModal isOpen={isAddOpen} onClose={onAddClose} />
      <ReplyRuleModal
        ruleId={currentRuleId}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />
      <ConfirmationModal />
      <SubscriptionModal isOpen={isLimitOpen} onClose={onLimitClose} />
    </>
  );
};

export default ReplyRules;
