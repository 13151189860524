import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { useReplyRules } from '../../../hooks/api/query/useReplyRules';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { RuleTag } from '../../../state/model/firebase';

const ReplyText = (props: {
  text: string;
  commentRuleTags: RuleTag[];
  filterRuleTags: RuleTag[];
  onClose: () => void;
}) => {
  const { text, commentRuleTags, filterRuleTags, onClose } = props;


const TagElements = (tags: RuleTag[], color: 'blue' | 'red') => {
  return tags.map((tag) => {
    return (
      <Tag
        key={tag.id}
        borderRadius="full"
        variant="solid"
        colorScheme={color}
        mr="1"
        mb="1"
      >
        <TagLabel>{tag.text}</TagLabel>
      </Tag>
    );
  });
};

  return (
    <Box
      px="8"
      py="4"
      borderY="solid"
      borderX="none"
      borderWidth="thin"
      borderColor={mode('gray.200', 'gray.400')}
      w="full"
      minH="16"
      textAlign="start"
      as="button"
      onClick={onClose}
      _hover={{ bgColor: mode('gray.200', 'gray.800') }}
    >
      <Box mb="2">{TagElements(commentRuleTags, 'blue')}{TagElements(filterRuleTags, 'red')}</Box>
      <Text>{text}</Text>
    </Box>
  );
};

const ReplyTextModal = (props: {
  ruleName: string;
  replyRuleId: string;
  isOpen: boolean;
  onClose: (text?: string) => void;
}) => {
  const { ruleName, replyRuleId, isOpen, onClose } = props;

  const { state } = usePreferences();
  const { data: replyRules, isLoading } = useReplyRules(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentRule = replyRules?.find((r) => r.id === replyRuleId);

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Reply Text</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0">
          <Heading size="xs" p="4" color="gray.500">
            {ruleName}
          </Heading>
          {!isLoading &&
            currentRule &&
            currentRule.replyMessages.map((r) => (
              <ReplyText
                key={r.id}
                text={r.replyMessage}
                commentRuleTags={r.commentRuleTags}
                filterRuleTags={r.filterRuleTags}
                onClose={() => onClose(r.replyMessage)}
              />
            ))}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default ReplyTextModal;
