import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { HiInboxIn } from 'react-icons/hi';
import Lottie, { Options } from 'react-lottie';
import { useHistory, useParams } from 'react-router-dom';
import { analytics } from '../../firebase';
import animationData from '../../lotties/confetti.json';
import { useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';

const lottieOptions: Options = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ThankYou = () => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { price, priceId, name, yearly } = useParams<{
    price: string | undefined;
    priceId: string | undefined;
    name: string | undefined;
    yearly: string | undefined;
  }>();

  useEffect(() => {
    // google analytics
    if (!price || !priceId || !name || !yearly) return;
    analytics.logEvent('purchase', {
      value: parseInt(price || '0', 10),
      currency: 'USD',
      transaction_id: undefined,
      items: [
        {
          item_id: priceId,
          item_name: name,
          item_variant: yearly === '1' ? 'Yearly' : 'Monthly',
        },
      ],
    });
    // referral program
    if (currentUser?.email)
      (window as any).fpr('referral', { email: currentUser?.email });
  }, [price, priceId, name, yearly, currentUser]);

  return (
    <>
      <Lottie
        options={lottieOptions}
        style={{ position: 'absolute', pointerEvents: 'none' }}
        isClickToPauseDisabled
      />
      <Box w="full" h="full" overflowY="auto">
        <Box
          maxW={{ base: 'xl', md: '3xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          h="full"
          pt="2"
        >
          <VStack pt="8" spacing="6">
            <Box>
              <Heading size="2xl">Thank You!</Heading>
            </Box>
            <Text>Thank you very much for your trust!</Text>
            <Text>Enjoy your free trial.</Text>
            <Button
              colorScheme="blue"
              leftIcon={<HiInboxIn />}
              onClick={() => history.push(paths.ROOT)}
            >
              Open Inbox
            </Button>
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default ThankYou;
