import * as React from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useQueryClient } from 'react-query';
import {
  createUserDocuments,
  editUser,
  setFbAccessToken
} from '../../api/firebase';
import firebase, { analytics, auth } from '../../firebase';
import { fetchDocument } from '../../utils/firestore';
import { User, UserDocument } from '../model/firebase';
import {
  setCurrentPageId,
  setCurrentTeamId,
  setHasFb,
  usePreferences
} from './PreferencesContext';

function TrackRegistration(signInMethod: string | undefined) {
  try {
    // FB Pixel
    ReactPixel.init('4184426034948021');
    ReactPixel.trackSingle('4184426034948021', 'Lead');
    ReactPixel.trackSingle('4184426034948021', 'CompleteRegistration');

    // Google analytics
    analytics.logEvent('sign_up', { method: signInMethod });
    analytics.logEvent('generate_lead', { method: signInMethod });
  } catch (error) {
    console.error(error);
  }
}

export function signup(email: string, password: string) {
  return auth.createUserWithEmailAndPassword(email, password);
}

export function login(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password);
}

export const authenticateUser = async (
  authResult: firebase.auth.UserCredential,
  name?: string,
  newUser?: boolean,
  hasFacebook?: boolean
): Promise<UserDocument> => {
  const { user, additionalUserInfo } = authResult;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { uid, photoURL, email, displayName } = user!;

  const userData: User = {
    email: email || '',
    name: name || displayName || '',
    firstName: name?.split(" ")[0] || displayName?.split(" ")[0] || '',
    lastName: name?.split(" ")[1] || displayName?.split(" ")[1] || '',
    logoUrl: photoURL || '',
    hasFacebook: !!hasFacebook,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  let userFromDb: UserDocument | undefined;
  if (additionalUserInfo?.isNewUser || newUser) {
    await createUserDocuments(uid, userData);
    TrackRegistration(authResult.credential?.signInMethod);
  } else {
    userFromDb = (await fetchDocument('users', uid)) as UserDocument;
    analytics.logEvent('login', {
      method: authResult.credential?.signInMethod,
    });
  }

  return {
    ...userData,
    ...userFromDb,
    id: uid,
  };
};

export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const result = await auth.signInWithPopup(provider);

  return authenticateUser(result);
};

export const linkFacebookAccount = async (user: UserDocument) => {
  // track event
  analytics.logEvent('begin_add_page', {
    page_location: 'https://app.turboreply.com/add-page',
    page_path: '/add-page',
    page_title: 'Add Page',
  });
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope(
    'email,pages_show_list,instagram_basic,ads_management,pages_read_user_content,pages_messaging,pages_read_engagement,pages_manage_engagement,pages_read_engagement,instagram_manage_comments'
  );
  let result;
  if (user.hasFacebook) result = await auth.signInWithPopup(provider);
  else result = await auth.currentUser?.linkWithPopup(provider);

  if (!result || !result.user)
    throw new Error('Failed to connect to Facebook.');

  // update token
  const userCredential = result?.credential as any;
  await setFbAccessToken(result.user.uid, userCredential.accessToken);

  // update user has facebook if not already
  if (!user.hasFacebook)
    return editUser({
      userId: user.id,
      user: {
        email: user.email,
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        hasFacebook: true,
        logoUrl: user.logoUrl,
      },
    });

  return user;
};

export async function sendEmailVerification() {
  await auth.currentUser?.sendEmailVerification();
}

export async function signupWithEmail(
  name: string,
  email: string,
  password: string
) {
  const userCredential = await auth.createUserWithEmailAndPassword(
    email,
    password
  );
  await userCredential.user?.sendEmailVerification();
  return authenticateUser(userCredential, name);
}

export async function signInWithEmail(
  email: string,
  password: string,
  remember: boolean
) {
  if (remember) await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  else await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const userCredential = await auth.signInWithEmailAndPassword(email, password);
  return authenticateUser(userCredential);
}

export function logout() {
  return auth.signOut();
}

export const signInWithEmailLink = async (
  email: string,
  password: string,
  url: string
) => {
  const userCredential = await auth.signInWithEmailLink(email, url);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  userCredential.user!.updatePassword(password);
  return authenticateUser(userCredential, email, true);
};

export function resetPassword(email: string) {
  return auth.sendPasswordResetEmail(email);
}

export async function changePassword(
  currentPassword: string,
  newPassword: string
) {
  if (!auth.currentUser?.email)
    throw new Error('Could not read email of current user.');
  const credential = firebase.auth.EmailAuthProvider.credential(
    auth.currentUser.email,
    currentPassword
  );
  // Check if current password is correct
  await auth.currentUser.reauthenticateWithCredential(credential);

  return auth.currentUser.updatePassword(newPassword);
}

export async function getCurrentUserIdToken() {
  return auth.currentUser?.getIdTokenResult(false);
}

export type UserProvider =
  | { type: 'facebook.com' }
  | { type: 'google.com' }
  | { type: 'email'; password: string };
export async function reauthenticateAccount(provider: UserProvider) {
  if (!auth.currentUser?.email)
    throw new Error('Could not read email of current user.');

  let result;

  switch (provider.type) {
    case 'facebook.com':
      result = await auth.signInWithPopup(
        new firebase.auth.FacebookAuthProvider()
      );
      break;
    case 'google.com':
      result = await auth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      );
      break;
    case 'email':
      result = await auth.currentUser.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          auth.currentUser.email,
          provider.password
        )
      );
      break;
    default:
      throw new Error('Provider not defined.');
  }
  // Check if current password is correct
  return !!result;
}

export function updateEmail(email: string, currentUser: firebase.User) {
  return currentUser.updateEmail(email);
}

export function updatePassword(password: string, currentUser: firebase.User) {
  return currentUser.updatePassword(password);
}

type ContextState = {
  currentUser: firebase.User | null | undefined;
  email: string;
  verified: boolean;
  signInWithFacebook: () => Promise<UserDocument>;
  setEmail: (newEmail: string) => void;
};
const AuthContext = createContext<ContextState>({
  currentUser: undefined,
  email: '',
  verified: false,
  setEmail: () => {
    throw new Error('not defined');
  },
  signInWithFacebook: () => {
    throw new Error('not defined');
  },
});

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

type AuthProviderProps = { children: React.ReactNode };
export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const { dispatch, state } = usePreferences();
  const queryClient = useQueryClient();

  const setNewEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  const signInWithFacebook = useCallback(async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    const result = await auth.signInWithPopup(provider);

    setHasFb(dispatch, true);
    return authenticateUser(result, undefined, undefined, true);
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (!user) {
        setCurrentTeamId(dispatch, null);
        setCurrentPageId(dispatch, null);
        setHasFb(dispatch, null);
        queryClient.clear();
        logout();
      }
    });

    return unsubscribe;
  }, [dispatch, queryClient]);

  const value = {
    currentUser,
    verified: !!state.hasFb,
    email,
    setEmail: setNewEmail,
    signInWithFacebook,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
