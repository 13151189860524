import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getPostInfos } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { PostInfoDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchPostInfos = async (teamId: string, pageId: string): Promise<PostInfoDocument[]> => {
  const response = getPostInfos(teamId, pageId);
  return response;
};

export const usePostInfos = (enabled: boolean, teamId: string, pageId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  // TODO: Future Feature: Enable refetch again and fetch only updated docs after init fetch
  return useQuery([pageId, 'postInfos'], () => fetchPostInfos(teamId, pageId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
