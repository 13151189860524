import { useState } from 'react';
import { RuleTag } from '../../state/model/firebase';
import { uuidv4 } from '../../utils';

export function useRuleTags(
  initTagRuleTags: RuleTag[] = [],
  onRuleTagsChanged: (tags: RuleTag[]) => void
) {
  const [ruleTags, setRuleTags] = useState<RuleTag[]>(initTagRuleTags);

  const onAddRuleText = (value: string) => {
    if (value.length < 1) return;
    const tags = [...ruleTags];
    tags.push({ id: uuidv4(), text: value });
    setRuleTags(tags);
    onRuleTagsChanged(tags);
  };

  const onRemoveRuleText = (id: string) => {
    let tags = [...ruleTags];
    tags = tags.filter((t) => t.id !== id);
    setRuleTags(tags);
    onRuleTagsChanged(tags);
  };

  return {
    state: ruleTags,
    onAddRuleText,
    onRemoveRuleText,
  };
}
