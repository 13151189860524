import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import MyLogo from '../../components/Icons/MyLogo';
import { useFormatMessage } from '../../hooks';
import useAuthProfile from '../../hooks/api/mutation/useAuthProfile';
import {
  logout,
  signInWithEmailLink,
  useAuth
} from '../../state/context/AuthContext';
import paths from '../Router/paths';

const schema = object().shape({
  email: string().email().required(),
  password: string().min(6).required(),
});

const EmailLink = () => {
  const mutation = useAuthProfile();
  const { setEmail } = useAuth();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const onSubmitHandler = (value: { email: string; password: string }) => {
    mutation.mutate(
      () =>
        signInWithEmailLink(value.email, value.password, window.location.href),
      {
        onSuccess: async () => {
          setEmail(value.email);
          await logout();
          history.push(paths.CONFIRM); // HACK not working :(
        },
      }
    );
  };

  return (
    <Box
      bg={mode('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ sm: '6', lg: '8' }}
    >
      <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} w={{ sm: 'full' }}>
        <Box mb={{ base: '10', md: '28' }}>
          <MyLogo mx="auto" h="8" iconColor={mode('blue.500', 'blue.200')} />
        </Box>
        <Heading mt="6" textAlign="center" size="xl" fontWeight="extrabold">
          {useFormatMessage('Login.setNewPassword')}
        </Heading>
      </Box>
      <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} mt="8" w={{ sm: 'full' }}>
        <Box
          bg={mode('white', 'gray.700')}
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
        >
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Stack spacing="6">
              <FormControl id="email" isInvalid={!!errors.email}>
                <FormLabel>{useFormatMessage('Login.email')}</FormLabel>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  ref={register}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={!!errors.password}>
                <FormLabel>{useFormatMessage('Login.password')}</FormLabel>
                <Input
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  ref={register}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" fontSize="md">
                {useFormatMessage('Login.setPassword')}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailLink;
