import { Box, Flex, Heading } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Progressbar } from '../../components/Steps/ProgressBar';
import { Step } from '../../components/Steps/Step';
import { useProgressState } from '../../components/Steps/useProgressState';
import paths from '../Router/paths';
import Accounts from './Steps/Accounts';
// import FirstPost from './Steps/FirstPost';
import Permissions from './Steps/Permissions';

const AddPage = () => {
  const steps = [
    { label: 'Give Permissions' },
    { label: 'Connect Page' },
    // { label: 'Add Post (optional)' },
  ];
  const { value, getState, goToNextStep, activeStep } = useProgressState(steps);
  const history = useHistory();

  const renderCurrentStep = () => {
    switch (activeStep) {
      case 1:
        return <Permissions goToNextStep={goToNextStep} />;
      case 2:
        return <Accounts goToNextStep={() => history.push(paths.DONE)} />;
      // case 3:
      //   return <FirstPost />;
      default:
        return <div>Not found</div>;
    }
  };

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box
        maxW={{ base: 'xl', md: '3xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        pt="2"
      >
        <Heading>Welcome, let&apos;s get started!</Heading>
        <Box as="nav" aria-label="Steps" position="relative" mt="8">
          <Flex
            justify="space-between"
            align="center"
            as="ol"
            listStyleType="none"
            zIndex={1}
          >
            {steps.map((step, index) => (
              <Step label={step.label} key={step.label} state={getState(index)}>
                {index + 1}
              </Step>
            ))}
          </Flex>
          <Progressbar value={value} />
        </Box>
        {renderCurrentStep()}
      </Box>
    </Box>
  );
};

export default AddPage;
