import { useAuth } from '../../state/context/AuthContext';
import { usePreferences } from '../../state/context/PreferencesContext';
import {
  SubscriptionRole,
  SubscriptionStatus
} from '../../state/model/firebase';
import { useMembers } from '../api/query/useMembers';

const FREE_MAX_PAGES = 1;
const FREE_MAX_MEMBERS = 0;
const FREE_MAX_POSTS = 3;
const FREE_MAX_RULES = 3;
const FREE_MAX_REPLIES = 100;
const BASIC_MAX_PAGES = 1;
const BASIC_MAX_MEMBERS = 2;
const BASIC_MAX_POSTS = 10;
const BASIC_MAX_RULES = 5;
const BASIC_MAX_MONTHLY_REPLIES = 150;
const PRO_MAX_PAGES = 2;
const PRO_MAX_MEMBERS = 3;
const PRO_MAX_POSTS = 40;
const PRO_MAX_RULES = 20;
const AGENCY_MAX_PAGES = 10;
const AGENCY_MAX_MEMBERS = 10;
const AGENCY_MAX_POSTS = 300;
const AGENCY_MAX_RULES = 50;

type UserSubscription = {
  MAX_PAGES: number;
  MAX_MEMBERS: number;
  MAX_POSTS: number;
  MAX_RULES: number;
  MAX_REPLIES: number | undefined;
  MAX_MONTHLY_REPLIES: number | undefined;
};
function useSubscriptionLimits(
  subscription:
    | { stripeRole: SubscriptionRole; stripeStatus: SubscriptionStatus }
    | undefined
): UserSubscription {
  const freePlan = {
    MAX_PAGES: FREE_MAX_PAGES,
    MAX_MEMBERS: FREE_MAX_MEMBERS,
    MAX_POSTS: FREE_MAX_POSTS,
    MAX_RULES: FREE_MAX_RULES,
    MAX_REPLIES: FREE_MAX_REPLIES,
    MAX_MONTHLY_REPLIES: undefined,
  };

  if (
    !subscription ||
    subscription.stripeStatus === 'inactive' ||
    subscription.stripeRole === 'none'
  )
    // free plan
    return freePlan;

  switch (subscription.stripeRole) {
    case 'basic':
      return {
        MAX_PAGES: BASIC_MAX_PAGES,
        MAX_MEMBERS: BASIC_MAX_MEMBERS,
        MAX_POSTS: BASIC_MAX_POSTS,
        MAX_RULES: BASIC_MAX_RULES,
        MAX_REPLIES: undefined,
        MAX_MONTHLY_REPLIES: BASIC_MAX_MONTHLY_REPLIES,
      };
    case 'pro':
      return {
        MAX_PAGES: PRO_MAX_PAGES,
        MAX_MEMBERS: PRO_MAX_MEMBERS,
        MAX_POSTS: PRO_MAX_POSTS,
        MAX_RULES: PRO_MAX_RULES,
        MAX_REPLIES: undefined,
        MAX_MONTHLY_REPLIES: undefined,
      };
    case 'agency':
      return {
        MAX_PAGES: AGENCY_MAX_PAGES,
        MAX_MEMBERS: AGENCY_MAX_MEMBERS,
        MAX_POSTS: AGENCY_MAX_POSTS,
        MAX_RULES: AGENCY_MAX_RULES,
        MAX_REPLIES: undefined,
        MAX_MONTHLY_REPLIES: undefined,
      };
    default:
      return freePlan;
  }
}

function usePermission() {
  const { state } = usePreferences();
  const { currentUser } = useAuth();
  const {
    data: members,
    isLoading,
    isFetched,
  } = useMembers(
    (state?.currentTeamId || '') !== '',
    // TODO: fix error on verify page, when users signs up with email!!!!
    // &&
    //   !!currentUser?.uid &&
    //   currentUser?.emailVerified &&
    //   verified,
    state.currentTeamId || ''
  );

  // TODO: use memo
  const currentMember = members?.find((m) => m.id === currentUser?.uid);

  const subscriptionLimits = useSubscriptionLimits(
    currentMember
      ? {
          stripeRole: currentMember.stripeRole,
          stripeStatus: currentMember.stripeStatus,
        }
      : undefined
  );

  return {
    isLoading,
    isFetched,
    canEdit: currentMember?.canEdit || currentMember?.isOwner || false,
    canDelete: currentMember?.canDelete || currentMember?.isOwner || false,
    canProcess: currentMember?.canProcess || currentMember?.isOwner || false,
    isOwner: currentMember?.isOwner || false,
    subscriptionLimits,
    stripeRole: currentMember?.stripeRole,
    stripeStatus: currentMember?.stripeStatus,
    hasSubscription:
      isFetched &&
      members &&
      currentMember?.stripeStatus &&
      currentMember?.stripeRole &&
      currentMember.stripeRole !== 'none' &&
      currentMember.stripeStatus !== 'inactive',
  };
}

export default usePermission;
