import { AddIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  VStack
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import useEditReplyRule from '../../hooks/api/mutation/useEditReplyRule';
import { useReplyRules } from '../../hooks/api/query/useReplyRules';
import { useReplyRuleState } from '../../hooks/ReplyRule/useReplyRuleState';
import { usePreferences } from '../../state/context/PreferencesContext';
import ReplyRuleItem from './ReplyRuleItem';

const schema = object().shape({
  name: string().required(),
});

type PopOverContent = { heading: string; content: string };
interface FormValues {
  name: string;
}
interface ReplyRuleFromProps {
  ruleId?: string;
  onSave?: () => void;
  onCancel?: () => void;
}
const ReplyRuleForm = (props: ReplyRuleFromProps) => {
  const { ruleId, onSave, onCancel } = props;
  const { state } = usePreferences();
  const isEditing = !!useMemo(() => ruleId, [ruleId]);
  const { data: replyRules } = useReplyRules(
    (state?.currentTeamId || '') !== '' && isEditing,
    state.currentTeamId || ''
  );
  const rule = useMemo(() => replyRules?.find((r) => r.id === ruleId), [ruleId, replyRules]);
  const mutation = useEditReplyRule();
  const toast = useToast();

  const { register, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: isEditing ? rule?.ruleName : undefined,
    },
    resolver: yupResolver(schema),
  });
  const {
    rules,
    validReplyRules,
    updateFieldChanged,
    addRule,
    removeRule,
  } = useReplyRuleState(isEditing ? rule?.replyMessages : undefined);

  const [validateChildRules, setValidateChildRules] = useState(false);
  const ruleItems = useMemo(() => {
    return rules.map((value, index) => (
      <ReplyRuleItem
        key={value.id}
        initValue={value}
        ruleIndex={index}
        validateNow={validateChildRules}
        isEditing={isEditing}
        onReplyRulesChange={updateFieldChanged}
        onRemoveRule={index === 0 ? null : removeRule}
      />
    ));
  },[isEditing, removeRule, rules, updateFieldChanged, validateChildRules]);

  const onSubmitHandler = (values: FormValues) => {
    if (!validReplyRules) {
      setValidateChildRules(true);
      return;
    }

    if (state.currentTeamId) {
      const id = isEditing ? ruleId : undefined;
      mutation.mutate(
        {
          ruleId: id,
          teamId: state.currentTeamId,
          replyRule: {
            ruleName: values.name,
            replyMessages: rules,
          },
        },
        {
          onSuccess: () => {
            // TODO: translations!
            toast({
              description: isEditing
                ? 'Reply rule modified sucessfully!'
                : 'Reply rule created sucessfuly!',
              position: 'top-right',
              duration: 9000,
              status: 'success',
              isClosable: true,
            });
            if (onSave) onSave();
          },
        }
      );
    }
  };

  const popOverContent: PopOverContent[] = [
    {
      heading: 'Contains anything',
      content:
        'Reply message will be triggered for any user message including text, image or just a emoji. (Usually used as default awsner, and only one at once possible.)',
    },
    {
      heading: 'Contains any',
      content:
        "This is a more flexible matching rule. It will be triggered if any defined keyword exists any where in the user's message.",
    },
    {
      heading: 'Contains all',
      content:
        "This can be used for more advanced matching. It requires ALL defined keywords to exist in the user's message.",
    },
  ];
  const [popOverStep, setPopOverStep] = useState(0);

  return (
    <>
      <VStack spacing="3" mb="10">
        <FormControl id="name" isInvalid={!!errors.name}>
          <FormLabel>Rule Name</FormLabel>
          <Input placeholder="Rule Name" ref={register} name="name" />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <Popover placement="bottom" closeOnBlur={false}>
            <PopoverTrigger>
              <FormLabel as="button" type="button" w="40">
                Reply Conditions <QuestionOutlineIcon />
              </FormLabel>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                {popOverContent[popOverStep].heading}
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>{popOverContent[popOverStep].content}</PopoverBody>
              <PopoverFooter
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                pb={4}
              >
                <Box fontSize="sm">
                  Step {popOverStep + 1} of {popOverContent.length}
                </Box>
                <ButtonGroup size="sm">
                  <Button
                    isDisabled={popOverStep - 1 < 0}
                    onClick={() => setPopOverStep((prev) => prev - 1)}
                  >
                    Previous
                  </Button>
                  <Button
                    colorScheme="blue"
                    isDisabled={popOverStep + 1 >= popOverContent.length}
                    onClick={() => setPopOverStep((prev) => prev + 1)}
                  >
                    Next
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Popover>
          <VStack>{ruleItems}</VStack>
        </FormControl>
        <Button colorScheme="blue" leftIcon={<AddIcon />} onClick={addRule}>
          Add Condition
        </Button>
      </VStack>
      <HStack width="full" justify="flex-end">
        <Button
          type="button"
          colorScheme="blue"
          loadingText="Submitting"
          isLoading={mutation.isLoading}
          onClick={handleSubmit(onSubmitHandler)}
        >
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </HStack>
    </>
  );
};

export default ReplyRuleForm;
