import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, ref, string } from 'yup';
import { FieldGroup } from '../../../components/FormLayout/FieldGroup/FieldGroup';
import { useFormatMessage } from '../../../hooks';
import useChangePassword from '../../../hooks/api/mutation/useChangePassword';

const schema = object().shape({
  current: string().min(6).required(),
  new: string()
    .min(6)
    .notOneOf([ref('current')])
    .required(),
  confirmation: string()
    .equals([ref('new')])
    .required(),
});

const ChangePassword = () => {
  const { isLoading, isSuccess, isError, mutate } = useChangePassword();
  const { register, handleSubmit, watch, setValue, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      current: '',
      new: '',
      confirmation: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (value: {
    current: string;
    confirmation: string;
  }) => {
    mutate({
      currentPassword: value.current,
      newPassword: value.confirmation,
    });
  };

  useEffect(() => {
    if (isSuccess && !isLoading && !isError) {
      setValue('current', '');
      setValue('new', '');
      setValue('confirmation', '');
    }
  }, [setValue, isSuccess, isLoading, isError]);

  const newPassword = watch('new');
  const confirmationPassword = watch('confirmation');
  const invalidPasswordMessage = useFormatMessage(
    `ChangePassword.invalidPassword`
  );
  const safePasswordMessage = useFormatMessage(`ChangePassword.safePassword`);
  const insecurePasswordMessage = useFormatMessage(
    `ChangePassword.insecurePassword`
  );
  const passwordsMatchMessagge = useFormatMessage(
    `ChangePassword.matchPassword`
  );
  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );
  const samePasswordMessage = useFormatMessage(`ChangePassword.samePassword`);

  return (
    <form id="security-form" onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack spacing="4" divider={<StackDivider />}>
        <Heading size="lg" as="h1" paddingBottom="4" paddingTop="4">
        {useFormatMessage(`ChangePassword.security`)}
        </Heading>
        <FieldGroup title={useFormatMessage(`ChangePassword.changePassword`)}>
          <VStack width="full" spacing="6">
            <FormControl id="currentPassword" isInvalid={!!errors.current}>
              <FormLabel>
                {useFormatMessage('ChangePassword.currentPassword')}
              </FormLabel>
              <Input name="current" type="password" required ref={register} />
              <FormErrorMessage>
                {errors.current && invalidPasswordMessage}
              </FormErrorMessage>
            </FormControl>
            <FormControl id="newPassword" isInvalid={!!errors.new}>
              <FormLabel>
                {useFormatMessage('ChangePassword.newPassword')}
              </FormLabel>
              <Input
                name="new"
                type="password"
                required
                ref={register}
                borderColor={
                  newPassword && !errors.new ? 'green.100' : 'inherit'
                }
              />
                {/* eslint-disable-next-line no-nested-ternary */}
                {errors.new
                  ? newPassword.length < 6
                    ? <FormErrorMessage>{insecurePasswordMessage}</FormErrorMessage>
                    : <FormErrorMessage>{samePasswordMessage}</FormErrorMessage>
                  : newPassword && <FormLabel color='green.200'>{safePasswordMessage}</FormLabel>}
            </FormControl>
            <FormControl id="confirmPassword" isInvalid={!!errors.confirmation}>
              <FormLabel>
                {useFormatMessage('ChangePassword.confirmPassword')}
              </FormLabel>
              <Input
                name="confirmation"
                type="password"
                required
                ref={register}
                borderColor={
                  confirmationPassword && !errors.confirmation
                    ? 'green.100'
                    : 'inherit'
                }
              />
              <FormErrorMessage>
                {errors.confirmation
                  ? notMatchPasswordMessage
                  : confirmationPassword && (
                      <FormLabel>{passwordsMatchMessagge}</FormLabel>
                    )}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </FieldGroup>
      </Stack>
      <FieldGroup mt="8">
        <HStack width="full">
          <Button type="submit" colorScheme="blue">
            {useFormatMessage(`ChangePassword.submits`)}
          </Button>
        </HStack>
      </FieldGroup>
    </form>
  );
};

export default ChangePassword;
