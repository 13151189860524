import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import ReplyRuleForm from './ReplyRuleForm';

interface ReplyRuleModalProps {
  ruleId?: string;
  isOpen: boolean;
  onClose: () => void;
}
const ReplyRuleModal = (props: ReplyRuleModalProps) => {
  const { ruleId, isOpen, onClose } = props;
  const isEditing = !!ruleId;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isEditing ? 'Edit Rule' : 'Create Rule'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <ReplyRuleForm onCancel={onClose} onSave={onClose} ruleId={ruleId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReplyRuleModal;
