import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { HiInboxIn } from 'react-icons/hi';
import TabButton from '../../components/Layout/TabButton';
import Inbox from './Inbox/Inbox';
import Settings from './Settings/Settings';

type Tab = 'inbox' | 'settings';

const TabList = (props: { activeTab: string; onClick: (tab: Tab) => void }) => {
  const { activeTab, onClick } = props;

  return (
    <Flex m="1em" display={{ base: 'flex', lg: 'none' }}>
      <TabButton
        label="Inbox"
        icon={HiInboxIn}
        active={activeTab === 'inbox'}
        onClick={() => onClick('inbox')}
      />
        <TabButton
          label="Settings"
          icon={FiSettings}
          active={activeTab === 'settings'}
          onClick={() => onClick('settings')}
          // tooltip='Add Posts here'
        />
    </Flex>
  );
};

const Mobile = () => {
  const [activeTab, setActiveTab] = useState<Tab>('inbox');

  const handleTabChange = (tab: Tab) => {
    setActiveTab(tab);
  };

  return (
    <Box display={{ base: 'flex', lg: 'none' }} h="full">
      <Flex flex="1" flexDirection="column">
        <TabList activeTab={activeTab} onClick={handleTabChange} />
        {activeTab === 'inbox' ? <Inbox /> : <Settings />}
      </Flex>
    </Box>
  );
};

export default Mobile;
