import { useToast } from '@chakra-ui/toast';
import { useInfiniteQuery } from 'react-query';
import { getFbFeed } from '../../../api/firebase';
import { FbMedia } from '../../../state/model/facebook';
import { PostType } from '../../../state/model/firebase';

export default function useFacebookMedia(
  enabled: boolean,
  teamId: string,
  pageId: string
) {
  const toast = useToast();
  return useInfiniteQuery(
    [pageId, 'fbmedia'],
    (context) =>
      getFbFeed(
        teamId,
        pageId,
        PostType.InstagramFeed,
        context.pageParam
      ) as Promise<FbMedia>,
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
}
