import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getReplyRules } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { ReplyRuleDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchReplyRules = async (teamId: string): Promise<ReplyRuleDocument[]> => {
  const response = getReplyRules(teamId);
  return response;
};

export const useReplyRules = (enabled: boolean, teamId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  // TODO: Future Feature: Enable refetch again and fetch only updated docs after init fetch
  return useQuery('replyRules', () => fetchReplyRules(teamId), {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    }
  });
};
