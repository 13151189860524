import firebase, { firestore } from '../firebase';

const getFirestoreRef = (path: string) => firestore.collection(path);

export const fetchDocument = async (collection: string, id: string) => {
  const document = await getFirestoreRef(collection).doc(id).get();
  if (!document.exists) {
    return null;
  }

  return { id: document.id, ...document.data() };
};

export const fetchCollection = async (collection: string, options?: any) => {
  const data: any[] = [];
  let baseQuery:
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    | firebase.firestore.Query<firebase.firestore.DocumentData> = getFirestoreRef(
    collection
  );

  if (options?.queries) {
    const { queries } = options;
    queries.forEach(
      (
        fieldPath: string | firebase.firestore.FieldPath,
        opStr: firebase.firestore.WhereFilterOp,
        value: any
      ) => {
        baseQuery = baseQuery.where(fieldPath, opStr, value);
      }
    );
  }

  if (options?.sort) {
    const { attribute, order } = options.sort;
    baseQuery = baseQuery.orderBy(attribute, order);
  }
  (await baseQuery.get()).forEach((doc) =>
    data.push({ id: doc.id, ...doc.data() })
  );

  return data;
};

export const deleteDocument = (collection: string, id: string) => {
  return getFirestoreRef(collection).doc(id).delete();
};

export const createDocument = (collection: string, id: string, values: any) => {
  return getFirestoreRef(collection).doc(id).set(values);
};

export const pushDocument = (collection: string, values: any) => {
  return getFirestoreRef(collection).add(values);
};

export const updateDocument = (collection: string, id: string, values: any) => {
  return getFirestoreRef(collection).doc(id).update(values);
};
