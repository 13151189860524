import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useConfirmationModal } from '../../components/Modal/ConfirmationModal';
import SubscriptionModal from '../../components/Modal/SubscriptionModal';
import DataTable from '../../components/Table/DataTable';
import { useFormatMessage } from '../../hooks';
import useDeleteMember from '../../hooks/api/mutation/useDeleteMember';
import { useMembers } from '../../hooks/api/query/useMembers';
import { useProfile } from '../../hooks/api/query/useProfile';
import usePermission from '../../hooks/Permission/usePermission';
import {
  Columns,
  useMembersColumns
} from '../../hooks/table/useMembersColumns';
import { useAuth } from '../../state/context/AuthContext';
import {
  setCurrentTeamId,
  usePreferences
} from '../../state/context/PreferencesContext';
import paths from '../Router/paths';

const Team = () => {
  const { currentUser } = useAuth();
  const { data: profile } = useProfile(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const { state, dispatch } = usePreferences();
  const { data: members } = useMembers(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const mutation = useDeleteMember();
  const onDeleteMemberHandler = useCallback(
    (values: { teamId: string; userId: string }) => {
      mutation.mutate(values, {
        onSuccess: () => {
          // Change current team, if you leave a team
          if (values.userId === currentUser?.uid) {
            setCurrentTeamId(dispatch, currentUser.uid);
            window.location.reload(false);
          }
        },
      });
    },
    [mutation, currentUser?.uid, dispatch]
  );

  const {
    ConfirmationModal,
    onOpen,
    onClose,
    setPayload,
  } = useConfirmationModal<{ teamId: string; userId: string }>(
    mutation.isLoading,
    useFormatMessage('Users.delete'),
    useFormatMessage('Team.removeMember'),
    useFormatMessage('Users.confirm'),
    useFormatMessage('Users.cancel')
  );
  const onRemoveButtonClickHandler = useCallback(
    (userId: string) => {
      setPayload({ userId, teamId: state.currentTeamId! });
      onOpen(onDeleteMemberHandler);
    },
    [onOpen, setPayload, onDeleteMemberHandler, state.currentTeamId]
  );

  useEffect(() => {
    if (mutation.isSuccess && !mutation.isLoading) onClose();
  }, [mutation.isSuccess, mutation.isLoading, onClose]);

  const columns = useMembersColumns(
    state?.currentTeamId || '',
    profile?.id || '',
    onRemoveButtonClickHandler
  );
  const content: Columns[] = members ? [...members] : [];

  const newUserText = useFormatMessage('Users.newUser');

  const { isOwner, subscriptionLimits } = usePermission();

  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();

  const onNewHandler =
    -1 + (members?.length || 0) >= subscriptionLimits.MAX_MEMBERS
      ? onLimitOpen
      : undefined;

  const newButtonUrl =
    isOwner && members && -1 + members.length < subscriptionLimits.MAX_MEMBERS
      ? paths.INVITE
      : undefined;

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box as="section" py="12">
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          <Box overflowX="auto">
            <Heading size="lg" mb="6">
              {useFormatMessage('Team.team')}
            </Heading>
            <DataTable
              columns={columns}
              data={content}
              lengthLabel={useFormatMessage('Team.members')}
              filterPlaceholder={useFormatMessage('Team.filterPlaceholder')}
              newButtonText={isOwner ? newUserText : undefined}
              newButtonUrl={isOwner ? newButtonUrl : undefined}
              newButtonAction={isOwner ? onNewHandler : undefined}
            />
            <ConfirmationModal />
            <SubscriptionModal isOpen={isLimitOpen} onClose={onLimitClose} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Team;
