import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { editPage } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { FacebookPage } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useEditPage() {
  const { locale } = usePreferences().state;
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (values: { teamId: string; pageId: string; page: FacebookPage }) =>
      editPage(values.teamId, values.pageId, values.page),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (newPage) => {
        const previousPages = queryClient.getQueryData([
          'facebookPages',
        ]) as FacebookPage[];
        if (previousPages) {
          queryClient.setQueryData(
            'facebookPages',
            previousPages.map((page) => {
              if (page.id === newPage.id) return newPage;
              return page;
            })
          );
        } else {
          queryClient.setQueryData('facebookPages', [newPage]);
        }
        queryClient.invalidateQueries('facebookPages');

        // TODO: translations
        toast({
          description: 'Page modified sucessfully!',
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
