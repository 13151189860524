import { Box, BoxProps, HStack, Image, Text, VStack } from '@chakra-ui/react';
import Card from '../../../components/Card/Card';

interface SettingsCardProps extends BoxProps {
  title: string;
  menu: React.ReactNode;
  img?: string;
  imgfallback?: string;
  icon?: React.ReactNode | undefined;
  imgsize?: string | undefined;
}
const SettingsCard = (props: SettingsCardProps) => {
  const { img, imgfallback, title, icon, menu, imgsize } = props;
  return (
    <Card {...props} p={{ base: '4', md: '4' }}>
      <HStack spacing="2">
        {img && (
          <Image
            w={imgsize || '16'}
            h={imgsize || '16'}
            src={img}
            fallbackSrc={imgfallback}
          />
        )}
        <VStack align="flex-start">
          <Text fontSize="md" w="max-content">
            {title}
          </Text>
          {icon}
        </VStack>
        <Box w="full" textAlign="end">
          {menu}
        </Box>
      </HStack>
    </Card>
  );
};

export default SettingsCard;
