import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  chakra,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode
} from '@chakra-ui/react';
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { TableActions } from './TableActions';
import { TablePagination } from './TablePagination';

// eslint-disable-next-line @typescript-eslint/ban-types
type DataTableProps<Data extends object> = {
  columns: Column<Data>[];
  data: Data[];
  lengthLabel: string;
  filterPlaceholder: string;
  newButtonText?: string;
  newButtonUrl?: string;
  newButtonAction?: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const DataTable = <Data extends object>(props: DataTableProps<Data>) => {
  const {
    columns,
    data,
    lengthLabel,
    filterPlaceholder,
    newButtonText,
    newButtonUrl,
    newButtonAction,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <TableActions
        globalFilter={state.globalFilter}
        filterPlaceholder={filterPlaceholder}
        setGlobalFilter={setGlobalFilter}
        newButtonText={newButtonText}
        newButtonUrl={newButtonUrl}
        newButtonAction={newButtonAction}
      />
      <Table {...getTableProps()} my="8" borderWidth="1px" fontSize="sm">
        <Thead bg={mode('gray.50', 'gray.800')}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render('Header')}
                  <chakra.span pl="4">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                  >
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <TablePagination
        length={data.length}
        lengthLabel={lengthLabel}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </>
  );
};

export default DataTable;
