import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

const Card = (props: BoxProps) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.700')}
      maxWidth="2xl"
      m="4"
      p={{ base: '4', md: '8' }}
      rounded="lg"
      shadow="base"
      {...props}
    />
  );
};

export default Card;
