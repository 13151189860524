import { Locale } from '../../utils/index';

export type Action =
  | { type: 'PREFERENCES_SET_LOCALE'; locale: Locale }
  | { type: 'PREFERENCES_SET_CURRENT_TEAM'; teamId: string | null }
  | { type: 'PREFERENCES_SET_CURRENT_PAGE'; pageId: string | null }
  | { type: 'PREFERENCES_SET_HAS_FB'; hasFb: boolean | null };

export type State = {
  locale: Locale;
  currentTeamId: string | null;
  currentPageId: string | null;
  hasFb: boolean | null;
};

export const LOCALSTORAGE_KEY_LOCALE = 'turboreply_locale';
export const LOCALSTORAGE_KEY_CURRENT_TEAM = 'turboreply_currentTeamId';
export const LOCALSTORAGE_KEY_CURRENT_PAGE = 'turboreply_currentPageId';
export const LOCALSTORAGE_KEY_HAS_FB = 'turboreply_hasFb';

export function authReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'PREFERENCES_SET_LOCALE':
      localStorage.setItem(LOCALSTORAGE_KEY_LOCALE, action.locale);
      return { ...state, locale: action.locale };
    case 'PREFERENCES_SET_CURRENT_TEAM':
      if (action.teamId)
        localStorage.setItem(LOCALSTORAGE_KEY_CURRENT_TEAM, action.teamId);
      else localStorage.removeItem(LOCALSTORAGE_KEY_CURRENT_TEAM);
      return { ...state, currentTeamId: action.teamId };
    case 'PREFERENCES_SET_CURRENT_PAGE':
      if (action.pageId)
        localStorage.setItem(LOCALSTORAGE_KEY_CURRENT_PAGE, action.pageId);
      else localStorage.removeItem(LOCALSTORAGE_KEY_CURRENT_PAGE);
      return { ...state, currentPageId: action.pageId };
    case 'PREFERENCES_SET_HAS_FB':
      if (action.hasFb)
        localStorage.setItem(LOCALSTORAGE_KEY_HAS_FB, action.hasFb ? '1' : '0');
      else localStorage.removeItem(LOCALSTORAGE_KEY_HAS_FB);
      return { ...state, hasFb: action.hasFb };
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
