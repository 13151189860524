import { forwardRef, Select } from '@chakra-ui/react';
import { CommentRule } from '../../../state/model/firebase';

const CommentRuleSelect = forwardRef(
  (
    props: {
      name?: string | undefined;
      onChanged?: React.ChangeEventHandler<HTMLSelectElement>;
    },
    ref
  ) => {
    const { name, onChanged } = props;
    return (
      <Select
        fontWeight="medium"
        fontSize="xs"
        ref={ref}
        name={name}
        onChange={onChanged}
      >
        <option value={CommentRule.default}>Message contains anything</option>
        <option value={CommentRule.containsAny}>Message contains any</option>
        <option value={CommentRule.containsAll}>Message contains all</option>
      </Select>
    );
  }
);

export default CommentRuleSelect;
