import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  HStack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { RiAdvertisementLine } from 'react-icons/ri';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory } from 'react-router-dom';
import Messenger from '../../../assets/messenger.png';
import SubscriptionModal from '../../../components/Modal/SubscriptionModal';
import { useFacebookPages } from '../../../hooks/api/query/useFacebookPages';
import { usePostInfos } from '../../../hooks/api/query/usePostInfos';
import usePermission from '../../../hooks/Permission/usePermission';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { PostInfo, PostType } from '../../../state/model/firebase';
import { formatPostId } from '../../../utils/index';
import paths from '../../Router/paths';
import PostTypeMenu from './PostTypeMenu';
import SettingsCard from './SettingsCard';

const Settings = () => {
  const history = useHistory();
  const { state } = usePreferences();
  const { data: postInfos, isLoading } = usePostInfos(
    (state?.currentTeamId || '') !== '' && (state?.currentPageId || '') !== '',
    state.currentTeamId || '',
    state.currentPageId || ''
  );
  const { canEdit, canDelete, subscriptionLimits } = usePermission();
  const pages = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentPage = pages.data?.find((p) => p.id === state.currentPageId);

  const getPlatformIcon = (postInfo: PostInfo) => {
    switch (postInfo.postType.toString()) {
      case PostType.AdCreative.toString(): {
        // TODO: Test: adCreative which only targets instagram
        return (
          <VStack spacing="0" align="start">
            <HStack spacing="1">
              <RiAdvertisementLine />
              {!postInfo.isInstagramPost ? <FaFacebook /> : <FaInstagram />}
            </HStack>
            {postInfo.effectiveInstagramPostId && (
              <HStack spacing="1">
                <FaInstagram />
                {(currentPage?.instagramBusinessAccountId || '') === '' && (
                  <>
                    <ArrowBackIcon color="red.500" />
                    <Text color="red" fontSize="xs">
                      Not connected
                    </Text>
                  </>
                )}
              </HStack>
            )}
          </VStack>
        );
      }
      case PostType.FacebookFeed.toString():
        return <FaFacebook />;
      case PostType.InstagramFeed.toString():
        return <FaInstagram />;
      default:
        throw new Error('Invalid Post Type');
    }
  };

  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();

  const onAddHandler = () => {
    if (postInfos && postInfos?.length < subscriptionLimits.MAX_RULES)
      history.push(paths.ADD_POST);
    // open upgrade modal
    else onLimitOpen();
  };

  return (
    <>
      <SettingsCard
        img={Messenger}
        title="Messenger"
        imgsize="12"
        menu={
          <PostTypeMenu
            postInfo={null}
            pageId={state.currentPageId || 'me'}
            teamId={state.currentTeamId || ''}
            canEdit={canEdit}
            canDelete={canDelete}
          />
        }
        // h={{base: 'sm', lg: '2xl'}}
      />
      <Container maxW="7xl" h={{ base: '80', lg: 'lg' }}>
        <Box
          border="2px solid currentColor"
          rounded="lg"
          borderColor={mode('gray.200', 'gray.600')}
          h="full"
          overflow="hidden"
        >
          <Button
            roundedBottom="none"
            leftIcon={<AddIcon />}
            colorScheme="blue"
            w="full"
            mb="4"
            onClick={onAddHandler}
            disabled={!canEdit}
          >
            Add Post
          </Button>
          <Box h="90%">
            {/* TODO: Future Check if possible without on mobile */}
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              {postInfos && !isLoading ? (
                postInfos.map((post) => (
                  <SettingsCard
                    key={post.id}
                    title={formatPostId(post.postId, post.postType)}
                    img={post.pictureUrl || undefined}
                    imgfallback={post.storageImgUrl || undefined}
                    icon={getPlatformIcon(post)}
                    menu={
                      <PostTypeMenu
                        postInfo={post}
                        pageId={state.currentPageId || ''}
                        teamId={state.currentTeamId || ''}
                        canEdit={canEdit}
                        canDelete={canDelete}
                      />
                    }
                    px="2"
                    mx="2"
                  />
                ))
              ) : (
                <Box>
                  <Text>Loading posts...</Text>
                </Box>
              )}
            </PerfectScrollbar>
          </Box>
        </Box>
      </Container>
      <SubscriptionModal isOpen={isLimitOpen} onClose={onLimitClose} />
    </>
  );
};

export default Settings;
