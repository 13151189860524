import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { removePostInfo } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { PostInfoDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useDeletePost() {
  const queryClient = useQueryClient();

  const { locale } = usePreferences().state;
  const toast = useToast();
  // TODO: translate
  // const sucessMessage = useFormatMessage('Team.removeSuccess');
  const sucessMessage = 'Post deleted sucessfully';

  return useMutation(
    (variables: { teamId: string; pageId: string; postId: string, imgUrl: string | undefined }) =>
      removePostInfo(variables.teamId, variables.pageId, variables.postId, variables.imgUrl),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (deletedPostId, variables) => {
        const previousPostInfos = queryClient.getQueryData([
          variables.pageId,
          'postInfos',
        ]) as PostInfoDocument[];
        if (previousPostInfos)
          queryClient.setQueryData(
            [variables.pageId, 'postInfos'],
            previousPostInfos.filter((post) => post.id !== deletedPostId)
          );
        queryClient.invalidateQueries([variables.pageId, 'postInfos']);

        toast({
          description: sucessMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
