import {
  Flex,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import SubscriptionModal from '../../../components/Modal/SubscriptionModal';
import useConnectPage from '../../../hooks/api/mutation/useConnectPage';
import useFacebookAccounts from '../../../hooks/api/query/useFacebookAccounts';
import useFacebookAdAccounts from '../../../hooks/api/query/useFacebookAdAccounts';
import { useFacebookPages } from '../../../hooks/api/query/useFacebookPages';
import usePermission from '../../../hooks/Permission/usePermission';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { FacebookPage } from '../../../state/model/firebase';
import { ConnectPageElement } from '../ConnectPageElement';

const Accounts = (props: { goToNextStep: () => void }) => {
  const { goToNextStep } = props;
  const { state } = usePreferences();
  const accounts = useFacebookAccounts(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const adAccounts = useFacebookAdAccounts(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const mutation = useConnectPage();
  const { data: pages = [] } = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );

  const { isOwner, subscriptionLimits } = usePermission();

  const {
    isOpen: isLimitOpen,
    onOpen: onLimitOpen,
    onClose: onLimitClose,
  } = useDisclosure();

  const [currentPageId, setCurrentPageId] = useState(''); // -> used for only set isLoading on current page

  const handleConnect = (pageId: string, adAccountId: string) => {
    const data = accounts.data?.accounts?.data?.find((p) => p.id === pageId);
    const currentPage = pages.find((p) => p.id === pageId);

    // Check subscription limit
    if (!currentPage && pages && pages.length >= subscriptionLimits.MAX_PAGES) {
      onLimitOpen();
      return;
    }
    setCurrentPageId(pageId);

    if (!data) {
      console.error('Selected page not found.');
      return;
    }
    const page: FacebookPage = {
      id: data.id,
      img: data.picture.data.url,
      instagramBusinessAccountId: data?.instagram_business_account?.id ?? null,
      name: data?.name || 'Name Not Defined',
      adAccountId: adAccountId && adAccountId !== 'none' ? adAccountId : null,
      // TODO: Future: handle init rule -> temporary with template id. possible solution: replyRules[0]
      pageRuleId: currentPage?.pageRuleId || 'ySlTu0SaLHPug2kPxOVQ',
      ignoreList: [],
    };

    if (!state.currentTeamId || !accounts?.data?.id || !isOwner) {
      console.error('Missing data for connecting page.');
      return;
    }
    mutation.mutate(
      {
        teamId: state.currentTeamId,
        newPage: page,
        meId: accounts.data.id,
        email: accounts.data.email,
        update: !!currentPage?.id,
      },
      {
        onSuccess: () => {
          // history.push(paths.ROOT);
          goToNextStep();
        },
      }
    );
  };

  const renderCheckboxGroup = () => {
    if (
      accounts.data?.accounts?.data &&
      accounts.data?.accounts?.data?.length > 0
    )
      return accounts.data?.accounts?.data?.map((acc) => (
        <ConnectPageElement
          title={acc.name}
          logo={acc.picture.data.url}
          key={acc.id}
          fbPageId={acc.id}
          igPageId={acc.instagram_business_account?.id}
          adAccounts={adAccounts.data}
          isRefreshing={accounts.isFetching}
          isConnecting={mutation.isLoading}
          isCurrentPage={currentPageId === acc.id}
          alreadyConnected={!!pages?.find((p) => p.id === acc.id)}
          onRefresh={accounts.refetch}
          onConnect={handleConnect}
        />
      ));
    return (
      <Text>No accounts found. Please create a Facebook Page and retry.</Text>
    );
  };

  return (
    <Stack justify="flex-start" maxW="lg" m="auto" spacing="4" py="8">
      {accounts.isLoading && (
        <VStack>
          <Flex justify="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              mx="auto"
            />
          </Flex>
          <Text>Please wait...</Text>
          <Text>This may take a while.</Text>
        </VStack>
      )}
      {accounts.isSuccess &&
      !accounts.isLoading &&
      adAccounts.isFetched &&
      accounts.data?.accounts?.data
        ? renderCheckboxGroup()
        : null}
      <SubscriptionModal isOpen={isLimitOpen} onClose={onLimitClose} />
    </Stack>
  );
};

export default Accounts;
