import { createIntl, createIntlCache } from 'react-intl';
import german from '../languages/de.json';
import english from '../languages/en.json';
import { PostType } from '../state/model/firebase';

// TODO: add more from https://firebase.google.com/docs/auth/admin/errors
export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export type Locale = 'en' | 'de';

export const messages = {
  en: english,
  de: german,
};

const getIntlContext = (locale: Locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error: string, locale: keyof typeof messages) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const availableLocales = Object.keys(messages) as Locale[];

export const browserLocale = navigator.language.split(/[-_]/)[0];

// stack overflow for the win:
export function uuidv4() {
  const u =
    Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
  const guid = [
    u.substr(0, 8),
    u.substr(8, 4),
    `4000-8${u.substr(13, 3)}`,
    u.substr(16, 12),
  ].join('-');
  return guid;
}

export function insertAt(str: string, index: number, value: string) {
  return str.substr(0, index) + value + str.substr(index);
}

export function formatPostId(postId: string, postType: PostType) {
  const index = postId.indexOf('_');
  if (postType.toString() !== PostType.InstagramFeed.toString() && index > -1) {
    return postId.slice(index + 1);
  }
  return postId;
}
