import { CheckIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import { number, object, string } from 'yup';
import { FieldGroup } from '../../components/FormLayout/FieldGroup/FieldGroup';
import AddPostHelpModal from '../../components/Modal/AddPostHelpModal';
import { useFormatMessage } from '../../hooks';
import useEditPost from '../../hooks/api/mutation/useEditPost';
import useVerifyPost from '../../hooks/api/mutation/useVerifyPost';
import { useFacebookPages } from '../../hooks/api/query/useFacebookPages';
import { usePostInfos } from '../../hooks/api/query/usePostInfos';
import { usePreferences } from '../../state/context/PreferencesContext';
import { PostInfo, PostType } from '../../state/model/firebase';
import paths from '../Router/paths';
import ReplyRuleSelect from './ReplyRuleSelect';
import SelectPostModal from './SelectPostModal';

interface FormValues {
  postType: number;
  postUrl: string;
  postId: number;
  replyRuleId: string;
}

interface PostFormProps {
  postId: string | undefined;
  hideCancel?: boolean;
  nextPath?: string;
  label?: string;
}

const PostForm = (props: PostFormProps) => {
  const { postId: currentPostId, hideCancel, nextPath, label } = props;
  const history = useHistory();
  const { state } = usePreferences();
  const pages = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentPage = pages.data?.find((p) => p.id === state.currentPageId);
  const isEditing = !!currentPostId;
  const { data: postInfos } = usePostInfos(
    (state?.currentPageId || '') !== '' && (state?.currentTeamId || '') !== '',
    state.currentTeamId || '',
    state.currentPageId || ''
  );
  const defaultPostInfo = currentPostId
    ? postInfos?.find((p) => p.id === currentPostId)
    : undefined;

  const schema = object().shape({
    postType: !isEditing ? number().required() : number(),
    postUrl: string(),
    postId: !isEditing ? number().required() : number(),
    replyRuleId: string().required(),
  });
  const { register, handleSubmit, errors, watch, setError, setValue } =
    useForm<FormValues>({
      defaultValues: {
        postType: defaultPostInfo?.postType || PostType.AdCreative,
        postId: defaultPostInfo?.postId
          ? parseInt(defaultPostInfo?.postId, 10)
          : undefined,
        replyRuleId: defaultPostInfo?.replyRuleId,
      },
      resolver: yupResolver(schema),
    });

  const verifyMutation = useVerifyPost();
  const postMutation = useEditPost();

  const [modalPost, setModalPost] = useState<PostInfo | undefined>();
  const postType = watch('postType');
  const postUrl = watch('postUrl');
  const postId = watch('postId');
  const replyRuleId = watch('replyRuleId');

  const hasNoAdAccount =
    postType.toString() === PostType.AdCreative.toString() &&
    !currentPage?.adAccountId;
  const hasNoInstagram =
    postType.toString() === PostType.InstagramFeed.toString() &&
    !currentPage?.instagramBusinessAccountId;

  // Reset values on postType change
  const { reset } = verifyMutation;
  useEffect(() => {
    if (!isEditing) {
      setValue('postId', undefined);
      reset();
    }
  }, [postType, isEditing, reset, setValue]);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const helpModalClosure = useDisclosure();

  const postAlreadyExists = () => {
    if (postInfos?.find((p) => p.postId === postId.toString())) {
      setError('postId', {
        type: 'manual',
        message: 'Post with this Id already added',
      });
      return true;
    }
    return false;
  };

  const onSubmitHandler = (values: FormValues) => {
    if (!isEditing && postAlreadyExists()) {
      return;
    }
    let newPost = !isEditing ? verifyMutation.data : defaultPostInfo;
    if (!isEditing && postType.toString() !== PostType.AdCreative.toString())
      newPost = modalPost;
    if (state.currentTeamId && state.currentPageId && newPost)
      postMutation.mutate(
        {
          teamId: state.currentTeamId,
          pageId: state.currentPageId,
          postInfo: { ...newPost, replyRuleId: values.replyRuleId },
          postId: defaultPostInfo?.id, // Only if isEditing
        },
        {
          onSuccess: () => {
            if (nextPath) {
              history.push(nextPath);
              return;
            }
            history.push(paths.ROOT);
          },
        }
      );
  };

  const verifyConnection = () => {
    if (!isEditing && postAlreadyExists()) {
      return;
    }
    if (!state.currentTeamId || !postUrl || !currentPage?.adAccountId) {
      console.error('Missing information!');
      return;
    }
    verifyMutation.mutate(
      {
        teamId: state.currentTeamId,
        postUrl,
        accountId:
          postType.toString() === PostType.AdCreative.toString()
            ? currentPage.adAccountId
            : currentPage.id,
        postType: postType as PostType,
      },
      {
        onSuccess: (newData) => {
          setValue('postId', newData.postId);
        },
      }
    );
  };

  const getPostTypeText = () => {
    switch (postType.toString()) {
      case PostType.AdCreative.toString():
        return 'Ad Creative';
      case PostType.FacebookFeed.toString():
        return 'Facebook Feed';
      case PostType.InstagramFeed.toString():
        return 'Instagram Feed';
      default:
        return '';
    }
  };

  const handleModalClose = (newPost: PostInfo | undefined) => {
    if (newPost) {
      setValue('postId', parseInt(newPost.postId, 10));
      setModalPost(newPost);
    }
    onModalClose();
  };

  return (
    <>
      <form id="post-form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4">
            {label ||
              (!isEditing
                ? `Add Post - ${currentPage?.name}`
                : `Edit Post - ${currentPage?.name}`)}
          </Heading>
          <FieldGroup title="Post Info">
            <VStack width="full" spacing="6">
              <FormControl id="postType" isInvalid={!!errors.postType}>
                <FormLabel>Post Type</FormLabel>
                {!isEditing ? (
                  <Select ref={register} name="postType">
                    <option value={PostType.AdCreative}>Ad Creative</option>
                    <option value={PostType.FacebookFeed}>Facebook Feed</option>
                    <option value={PostType.InstagramFeed}>
                      Instagram Feed
                    </option>
                  </Select>
                ) : (
                  <Text>{getPostTypeText()}</Text>
                )}
              </FormControl>
              {!isEditing &&
                postType.toString() === PostType.AdCreative.toString() && (
                  <FormControl
                    id="postUrl"
                    isInvalid={
                      !!errors.postUrl || hasNoInstagram || hasNoAdAccount
                    }
                  >
                    <FormLabel>Post ID or URL</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        name="postUrl"
                        fontSize="xs"
                        ref={register}
                        readOnly={
                          isEditing ||
                          postType.toString() !==
                            PostType.AdCreative.toString() ||
                          hasNoAdAccount
                        }
                        onChange={(e) => setValue('postUrl', e.target?.value?.replace(/\s/g, ""))}
                      />
                      {postType.toString() === PostType.AdCreative.toString() &&
                      !isEditing &&
                      !hasNoAdAccount ? (
                        <Tooltip
                          hasArrow
                          label="Where can I find the ID / URL?"
                          defaultIsOpen
                        >
                          <InputRightElement>
                            <IconButton
                              aria-label="Help"
                              icon={<QuestionOutlineIcon />}
                              borderLeftRadius="none"
                              onClick={helpModalClosure.onOpen}
                            />
                          </InputRightElement>
                        </Tooltip>
                      ) : null}
                    </InputGroup>
                  </FormControl>
                )}

              <FormControl
                id="postId"
                isInvalid={!!errors.postId || hasNoInstagram || hasNoAdAccount}
              >
                {postType.toString() === PostType.AdCreative.toString() &&
                !verifyMutation.data?.postId &&
                !isEditing ? null : (
                  <FormLabel>Post ID</FormLabel>
                )}
                <HStack>
                  <InputGroup>
                    <Input
                      type="number"
                      name="postId"
                      ref={register}
                      variant="unstyled"
                      readOnly
                    />
                    {verifyMutation.data?.postId && (
                      <InputRightElement>
                        <CheckIcon color="green.500" />
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {postType.toString() !== PostType.AdCreative.toString() &&
                    !isEditing && (
                      <Button
                        colorScheme="blue"
                        isDisabled={hasNoInstagram}
                        onClick={onModalOpen}
                      >
                        Select Post
                      </Button>
                    )}
                </HStack>
                <FormErrorMessage>
                  {hasNoInstagram && (
                    <VStack align="flex-start">
                      <Text>
                        You did not connect an instagram account to this page.
                      </Text>
                      <Link as={NavLink} to={paths.ADD_PAGE}>
                        Connect here
                      </Link>
                    </VStack>
                  )}
                  {hasNoAdAccount && (
                    <VStack align="flex-start">
                      <Text>
                        You did not connect an ad account to this page.
                      </Text>
                      <Link as={NavLink} to={paths.ADD_PAGE}>
                        Connect here
                      </Link>
                    </VStack>
                  )}
                  {errors.postId && errors.postId.message}
                </FormErrorMessage>
              </FormControl>
              {!isEditing &&
                postType.toString() === PostType.AdCreative.toString() && (
                  <Button
                    isDisabled={!postUrl || hasNoAdAccount}
                    isLoading={verifyMutation.isLoading}
                    loadingText="Please wait..."
                    type="button"
                    onClick={verifyConnection}
                  >
                    Verify Connection
                  </Button>
                )}
            </VStack>
          </FieldGroup>
          <FieldGroup title="Apply Settings">
            <VStack width="full" spacing="6">
              <FormControl id="replyRuleId" isInvalid={!!errors.replyRuleId}>
                <FormLabel>Reply Rule</FormLabel>
                <ReplyRuleSelect
                  name="replyRuleId"
                  replyRuleId={replyRuleId}
                  ref={register}
                />
                <FormErrorMessage>
                  {!!errors.replyRuleId?.message && errors.replyRuleId.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FieldGroup>
        </Stack>
        <FieldGroup mt="8">
          <HStack width="full" justify="flex-end">
            <Button
              disabled={
                !isEditing &&
                !verifyMutation.data?.postId &&
                postType.toString() === PostType.AdCreative.toString()
              }
              type="submit"
              colorScheme="blue"
              loadingText={useFormatMessage('General.submitting')}
              isLoading={postMutation.isLoading}
            >
              Save
            </Button>
            {!hideCancel && (
              <Button onClick={() => history.push(paths.ROOT)}>Cancel</Button>
            )}
          </HStack>
        </FieldGroup>
      </form>
      <SelectPostModal
        isOpen={isModalOpen}
        postType={postType}
        onClose={handleModalClose}
      />
      <AddPostHelpModal {...helpModalClosure} />
    </>
  );
};

export default PostForm;
