import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue as mode,
  VStack
} from '@chakra-ui/react';
import useFacebookFeed from '../../hooks/api/query/useFacebookFeed';
import useFacebookMedia from '../../hooks/api/query/useFacebookMedia';
import { useFacebookPages } from '../../hooks/api/query/useFacebookPages';
import { usePreferences } from '../../state/context/PreferencesContext';
import { PostInfo, PostType } from '../../state/model/firebase';
import { formatPostId } from '../../utils';

const PostItem = (props: {
  text: string;
  imgurl?: string;
  onClose: () => void;
}) => {
  const { text, imgurl, onClose } = props;

  return (
    <Box
      px="8"
      py="4"
      borderY="solid"
      borderX="none"
      borderWidth="thin"
      borderColor={mode('gray.200', 'gray.400')}
      w="full"
      minH="16"
      textAlign="start"
      as="button"
      onClick={onClose}
      _hover={{ bgColor: mode('gray.200', 'gray.800') }}
    >
      <HStack>
        <Image w="16" src={imgurl} />
        <Text noOfLines={3}>{text}</Text>
        <VStack w="full">{/* <Text size='xd'>Post ID</Text> */}</VStack>
      </HStack>
    </Box>
  );
};

const FacebookFeed = (props: { onClose: (post: PostInfo) => void }) => {
  const { onClose } = props;
  const { state } = usePreferences();
  const {
    data: fbPosts,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useFacebookFeed(
    (state?.currentTeamId || '') !== '' && (state?.currentPageId || '') !== '',
    state.currentTeamId || '',
    state.currentPageId || ''
  );

  const paging = fbPosts?.pages[fbPosts.pages.length - 1].paging;
  const after = !!paging?.next && paging.cursors.after;

  return (
    <>
      {isLoading && (
        <Flex justify="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            mx="auto"
          />
        </Flex>
      )}
      {!isLoading &&
        fbPosts &&
        fbPosts.pages &&
        fbPosts.pages.map((p) => {
          if (p.data)
            return p.data.map((d) => (
              <PostItem
                key={d.id}
                text={d.message}
                imgurl={d.picture}
                onClose={() =>
                  onClose({
                    postId: formatPostId(d.id, PostType.FacebookFeed),
                    postType: PostType.FacebookFeed,
                    ignore: false,
                    effectiveInstagramPostId: null,
                    ignoreList: [],
                    permalinkUrl: d.permalink_url,
                    // eslint-disable-next-line no-nested-ternary
                    pictureUrl: d.picture
                      ? d.picture
                      : d.attachments?.data &&
                        d.attachments.data.length > 0 &&
                        d.attachments.data[0].media?.image?.src
                      ? d.attachments.data[0].media.image?.src
                      : null,
                    replyRuleId: '',
                    isInstagramPost: false,
                  })
                }
              />
            ));
          return <Text>No posts found.</Text>;
        })}
      {fbPosts && fbPosts.pages.length > 0 && after && (
        <Button
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage({ pageParam: after })}
        >
          Load More
        </Button>
      )}
    </>
  );
};

const InstagramFeed = (props: { onClose: (post: PostInfo) => void }) => {
  const { onClose } = props;
  const { state } = usePreferences();
  const pages = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentPage = pages.data?.find((p) => p.id === state.currentPageId);
  const {
    data: igPosts,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useFacebookMedia(
    (state?.currentTeamId || '') !== '' && !!currentPage,
    state.currentTeamId || '',
    currentPage?.instagramBusinessAccountId || ''
  );

  const paging = igPosts?.pages[igPosts.pages.length - 1].paging;
  const after = !!paging?.next && paging.cursors.after;

  return (
    <>
      {isLoading && (
        <Flex justify="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            mx="auto"
          />
        </Flex>
      )}
      {!isLoading &&
        igPosts &&
        igPosts.pages.map((p) => {
          if (p.data)
            return p.data.map((d) => (
              <PostItem
                key={d.id}
                text={d.caption}
                imgurl={
                  d.media_type === 'VIDEO' ? d.thumbnail_url : d.media_url
                }
                onClose={() =>
                  onClose({
                    postId: d.id,
                    postType: PostType.InstagramFeed,
                    ignore: false,
                    effectiveInstagramPostId: null,
                    ignoreList: [],
                    permalinkUrl: d.permalink,
                    pictureUrl:
                      d.media_type === 'VIDEO' ? d.thumbnail_url : d.media_url,
                    replyRuleId: '',
                    isInstagramPost: true,
                  })
                }
              />
            ));
          return <Text>No posts found.</Text>;
        })}
      {igPosts && igPosts.pages && igPosts.pages.length > 0 && after && (
        <Button
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage({ pageParam: after })}
        >
          Load More
        </Button>
      )}
    </>
  );
};

const SelectPostModal = (props: {
  isOpen: boolean;
  postType: PostType;
  onClose: (post?: PostInfo) => void;
}) => {
  const { isOpen, postType, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody px="0">
          <Heading size="xs" p="4" color="gray.500">
            {postType.toString() === PostType.FacebookFeed.toString()
              ? 'Facebook Feed Posts'
              : 'Instagram Feed Posts'}
          </Heading>
          {postType.toString() === PostType.FacebookFeed.toString() ? (
            <FacebookFeed onClose={onClose} />
          ) : (
            <InstagramFeed onClose={onClose} />
          )}
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default SelectPostModal;
