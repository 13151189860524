import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import LanguageWrapper from './components/LanguageWrapper/LanguageWrapper';
import theme from './components/Theme/theme';
import Router from './pages/Router/RouterComponent';
import { AuthProvider } from './state/context/AuthContext';
import { PreferencesProvider } from './state/context/PreferencesContext';

// TODO: --passWithNoTests` -> remove it and fix test

const queryCache = new QueryCache();
const queryClient = new QueryClient({ queryCache });

function App() {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider resetCSS theme={theme}>
        <QueryClientProvider client={queryClient} contextSharing>
          <PreferencesProvider>
            <LanguageWrapper>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </LanguageWrapper>
          </PreferencesProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
