import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

export function useConfirmationModal<ConfirmationPayload>(
  isLoading: boolean,
  title: string,
  body: string,
  confirmButtonMessage: string,
  cancelButtonMessage: string
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payload, setPayload] = useState<ConfirmationPayload>();
  const onConfirmationRef = useRef<(payload: ConfirmationPayload) => void>();

  const onOpenHandler = (
    confirmationHandler: (payload: ConfirmationPayload) => void
  ) => {
    onConfirmationRef.current = confirmationHandler;
    onOpen();
  };

  const ConfirmationModal = () => {
    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          motionPreset={isLoading ? 'none' : 'slideInBottom'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{body}</ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {cancelButtonMessage}
              </Button>
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={() => {
                  if (payload && onConfirmationRef.current)
                    onConfirmationRef.current(payload);
                }}
              >
                {confirmButtonMessage}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return {
    onOpen: onOpenHandler,
    onClose,
    ConfirmationModal,
    setPayload,
  };
}
