import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { object, string } from 'yup';
import { signupWithEmail, useAuth } from '../../../state/context/AuthContext';

const schema = object().shape({
  name: string().required(),
  email: string().email().required(),
  password: string().min(6).required(),
});

export const SigupForm = () => {
  const { setEmail } = useAuth();
  const {
    error,
    isLoading,
    mutate,
  } = useMutation((value: { name: string; email: string; password: string }) =>
    signupWithEmail(value.name, value.email, value.password)
  );
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { name: '', email: '', password: '' },
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (value: {
    name: string;
    email: string;
    password: string;
  }) => {
    setEmail(value.email);
    mutate(value);
  };
  const errorMessage = error ? `${error}` : null;

  return (
    <>
      {/* {redirect} */}
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing="4">
          <FormControl id="name" isInvalid={!!errors.name}>
            <FormLabel mb={1}>Name</FormLabel>
            <Input autoComplete="name" name="name" ref={register} />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="email" isInvalid={!!errors.email}>
            <FormLabel mb={1}>Email</FormLabel>
            <Input
              type="email"
              autoComplete="email"
              name="email"
              ref={register}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl id="password" isInvalid={!!errors.password}>
            <FormLabel mb={1}>Password</FormLabel>
            <Input
              type="password"
              autoComplete="current-password"
              name="password"
              ref={register}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            fontSize="md"
            isLoading={isLoading}
          >
            Sign up with your email
          </Button>
          {errorMessage ? (
            <Text mt="3" color={mode('red.600', 'red.400')} fontWeight="medium">
              {errorMessage}
            </Text>
          ) : null}
        </Stack>
      </form>
    </>
  );
};
