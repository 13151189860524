import { Box, Spinner } from '@chakra-ui/react';
import { object, string } from 'yup';
import UserForm from '../../components/FormLayout/UserForm/UserForm';
import { useUserProvider } from '../../hooks';
import { useProfile } from '../../hooks/api/query/useProfile';
import { useAuth } from '../../state/context/AuthContext';
import ChangePassword from './ChangePassword/ChangePassword';
import DeleteAccount from './DeleteAccount/DeleteAccount';
// TODO: add translations
// import Preferences from './Preferences/Preferences';

const schema = object().shape({
  name: string().required(),
  createdAt: string().notRequired(),
});

const Profile = () => {
  const { currentUser } = useAuth();
  const { data: profile } = useProfile(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const userProvider = useUserProvider();

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
        {!profile || !userProvider ? (
          <Spinner />
        ) : (
          <>
            <UserForm
              isEditing
              isProfile
              user={profile}
              userId={profile.id}
              schema={schema}
            />
            {/* <Preferences /> */}
            {userProvider.type === 'email' && <ChangePassword />}
            <DeleteAccount profile={profile} userProvider={userProvider} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
