import {
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { HiCheckCircle } from 'react-icons/hi';
import { Card, CardProps } from './Card';

type Feature = {
  text: string;
  icon?: () => JSX.Element;
};
export interface PricingCardData {
  features: Feature[];
  name: string;
  price: string;
  secondPrice: string;
  showYearly: boolean;
}

interface PricingCardProps extends CardProps {
  data: PricingCardData;
  icon: React.ElementType;
  button: React.ReactElement;
}

export const PricingCard = (props: PricingCardProps) => {
  const { data, icon, button, ...rest } = props;
  const { features, price, name, secondPrice, showYearly } = data;
  const accentColor = useColorModeValue('blue.600', 'blue.200');

  return (
    <Card rounded={{ sm: 'xl' }} {...rest}>
      <VStack spacing={6}>
        <Icon aria-hidden as={icon} fontSize="4xl" color={accentColor} />
        <Heading size="md" fontWeight="extrabold">
          {name}
        </Heading>
      </VStack>
      <Flex
        align="flex-end"
        justify="center"
        fontWeight="extrabold"
        color={accentColor}
        mt="8"
      >
        <Heading size="2xl" fontWeight="inherit" lineHeight="0.9em">
          {price}
        </Heading>
        <Text fontWeight="inherit" fontSize="xl">
          / month
        </Text>
      </Flex>
      <Text textAlign="center" mt="4" mb="8">
        Billed as {secondPrice} {showYearly ? 'every year' : 'every month'}
      </Text>
      <List spacing="3" mb="8" maxW="28ch" mx="auto">
        {features.map((feature) => (
          <ListItem fontWeight="medium" key={feature.text}>
            {feature.icon ? (
              <HStack>
                <Text>{feature.text}</Text>
                {feature.icon()}
              </HStack>
            ) : (
              <>
                <ListIcon
                  fontSize="xl"
                  as={HiCheckCircle}
                  marginEnd={2}
                  color={accentColor}
                />
                {feature.text}
              </>
            )}
          </ListItem>
        ))}
      </List>
      {button}
    </Card>
  );
};
