import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  VStack
} from '@chakra-ui/react';
import PostIDHelp from '../../assets/How_To_Find_Post_ID.jpg';
import PostUrlHelp from '../../assets/How_to_find_post_url.png';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const AddPostHelpModal = (props: ModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Where can I find the Post ID / URL?</ModalHeader>
        <ModalCloseButton />
        {/* TODO: add good marketing */}
        <ModalBody pb={6}>
          <VStack spacing="8" align="flex-start" divider={<Divider />}>
            <Box>
              <Text fontSize="lg" as="b">
                A. Post ID
              </Text>
              <OrderedList>
                <ListItem>
                  <Link
                    color="blue.500"
                    href="https://www.facebook.com/adsmanager/manage/"
                    isExternal
                  >
                    Open the ads manager <ExternalLinkIcon mx="2px" />
                  </Link>
                </ListItem>
                <ListItem>
                  Navigate to your ad (AD MUST BE ACTIVE) and navigate to the Ad
                  Creative section
                </ListItem>
                <ListItem>
                  Copy the Post ID of your selceted post (see Image). If the Ad
                  is newly created, click on the button
                </ListItem>
              </OrderedList>
              <Image mt="2" src={PostIDHelp} />
            </Box>
            <Box>
              <Text fontSize="lg" as="b">
                B. Post URL (does not work on &apos;Create Ad&apos; except for
                Instagram)
              </Text>
              <OrderedList>
                <ListItem>
                  <Link
                    color="blue.500"
                    href="https://www.facebook.com/adsmanager/manage/"
                    isExternal
                  >
                    Open the ads manager <ExternalLinkIcon mx="2px" />
                  </Link>
                </ListItem>
                <ListItem>
                  Navigate to your ad (AD MUST BE ACTIVE) and click on the
                  &apos;Post with Comments&apos; Button in the Ad Creative
                  section
                </ListItem>
                <ListItem>
                  Copy the Post Url from the browser’s address bar.
                </ListItem>
              </OrderedList>
            </Box>
            <Image mt="2" src={PostUrlHelp} />
            <Text mt="2">
              <Text as="b">Tip: </Text>For ads with both Platforms (Facebook
              &amp; Instagram), you only have to copy the Post ID / Url from
              Facebook.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPostHelpModal;
