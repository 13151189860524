import firebase, { auth, firestore, functions, storage } from '../firebase';
import {
  FbAccounts,
  FbAdAccounts,
  FbAdCreativeInfo,
  FbError,
  FbFacebookFeedInfo,
  FbFeed,
  FbInstagramFeedInfo,
  FbMedia
} from '../state/model/facebook';
import {
  FacebookPage,
  Membership,
  MembershipDocument,
  Platform,
  PostInfo,
  PostInfoDocument,
  PostType,
  PriceDocument,
  ProductDocument,
  ReplyRule,
  ReplyRuleDocument,
  ReviewTask,
  Stats,
  SubscriptionDocument,
  Team,
  User,
  UserDocument
} from '../state/model/firebase';
import { deleteDocument, updateDocument } from '../utils/firestore';
import { formatPostId } from '../utils/index';

export const getDownloadUrlFromRef = async (url: string) => {
  const ref = storage.refFromURL(url);
  return (await ref.getDownloadURL()) as string;
};

export const downloadRemotePicture = (remoteimageurl: string) => {
  return new Promise<Blob>((resolve) => {
    fetch(remoteimageurl)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        resolve(blob);
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

const uploadImage = (filename: string, blob: Blob) => {
  const storageRef = storage.ref();

  // uploading blob to firebase storage
  return storageRef.child(filename).put(blob);
};

export const uploadLogo = (uid: string, file: File) => {
  const storageRef = storage.ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`users/${uid}/${fileName}`).put(file);
};

export const getBucketImageUrl = (
  uid: string,
  file: File | string,
  teamId: string
) => {
  let fileExtension;
  if (typeof file === 'string') fileExtension = file.split('.').pop();
  else fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/users%2F${teamId}%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const deleteStorageImage = (oldLogo: string, teamId: string) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split(`users%2F${teamId}%2F`)
    .pop()
    ?.split('?alt=media')
    .shift();
  return storage.ref(`users/${teamId}/${logoPath}`).delete();
};

export const getTeamMembers = async (currentTeamId: string) => {
  const members: MembershipDocument[] = [];
  const memberships = await firestore
    .collection('teams')
    .doc(currentTeamId)
    .collection('memberships')
    .get();
  memberships.forEach((doc) => {
    // console.log(doc.id, ' => ', doc.data());
    members.push({ id: doc.id, ...doc.data() } as MembershipDocument);
  });
  return members;
};

export const getReplyRules = async (currentTeamId: string) => {
  const replyRules: ReplyRuleDocument[] = [];
  const replyRulesDocs = await firestore
    .collection('teams')
    .doc(currentTeamId)
    .collection('replyRules')
    .get();
  replyRulesDocs.forEach((doc) => {
    // console.log(doc.id, ' => ', doc.data());
    replyRules.push({ id: doc.id, ...doc.data() } as ReplyRuleDocument);
  });
  return replyRules;
};

export const getStats = async (currentTeamId: string) => {
  const statsDoc = await firestore.collection('stats').doc(currentTeamId).get();
  const stats = statsDoc.data() as Stats;
  return stats;
};

export const getPostInfos = async (
  currentTeamId: string,
  currentPageId: string
) => {
  const postInfos: PostInfoDocument[] = [];
  const postInfosDocs = await firestore
    .collection('pages')
    .doc(currentTeamId)
    .collection('facebook')
    .doc(currentPageId)
    .collection('postInfos')
    .get();
  postInfosDocs.forEach((doc) => {
    // console.log(doc.id, ' => ', doc.data());
    postInfos.push({ id: doc.id, ...doc.data() } as PostInfoDocument);
  });
  return postInfos;
};

export const createOrUpdateReplyRule = async (
  teamId: string,
  replyRule: ReplyRule,
  ruleId?: string
) => {
  const replyRulesRef = firestore
    .collection('teams')
    .doc(teamId)
    .collection('replyRules');

  if (!ruleId) {
    // Create
    const newId = replyRulesRef.doc().id;
    const statsRef = firestore.collection('stats').doc(teamId);

    const batch = firestore.batch();
    batch.set(replyRulesRef.doc(newId), {
      ...replyRule,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // add count
    batch.update(statsRef, {
      replyRuleCount: firebase.firestore.FieldValue.increment(1),
      replyRuleId: newId,
      docRef: replyRulesRef.doc(newId),
      countField: 'replyRuleCount',
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await batch.commit();

    return { id: newId, ...replyRule } as ReplyRuleDocument;
  }

  // Update
  await replyRulesRef.doc(ruleId).update({
    ...replyRule,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
  return { id: ruleId, ...replyRule } as ReplyRuleDocument;
};

export const createOrUpdatePostInfo = async (
  teamId: string,
  pageId: string,
  postInfo: PostInfo,
  postId?: string
) => {
  const postInfosRef = firestore
    .collection('pages')
    .doc(teamId)
    .collection('facebook')
    .doc(pageId)
    .collection('postInfos');

  if (!postId) {
    // Create
    const newId = postInfosRef.doc().id;
    const statsRef = firestore.collection('stats').doc(teamId);

    // get picture
    let storageUrl = null;
    if (postInfo.pictureUrl) {
      const pictureBlob = await downloadRemotePicture(postInfo.pictureUrl);
      await uploadImage(`users/${teamId}/${newId}.jpg`, pictureBlob);
      storageUrl = getBucketImageUrl(newId, `${newId}.jpg`, teamId);
    }
    const batch = firestore.batch();
    batch.set(postInfosRef.doc(newId), {
      ...postInfo,
      storageImgUrl: storageUrl,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // add count
    batch.update(statsRef, {
      postCount: firebase.firestore.FieldValue.increment(1),
      postInfoId: newId,
      docRef: postInfosRef.doc(newId),
      countField: 'postCount',
      pageId,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    await batch.commit();

    return { id: newId, ...postInfo } as PostInfoDocument;
  }

  // Update
  await postInfosRef.doc(postId).update({
    ...postInfo,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
  return { id: postId, ...postInfo } as PostInfoDocument;
};

export const getProfileTeams = async (userId: string) => {
  const teams: MembershipDocument[] = [];
  const currentUserRef = firestore.collection('users').doc(userId);
  const membershipsRef = firestore
    .collectionGroup('memberships')
    .where('user', '==', currentUserRef);
  const profileTeams = await membershipsRef.get();
  profileTeams.docs.forEach((doc) => {
    // console.log(doc.id, ' => ', doc.data());
    teams.push({ id: doc.id, ...doc.data() } as MembershipDocument);
  });
  return teams;
};

export const createUserDocuments = async (uid: string, user: User) => {
  const teamName = `${user.name}'s Team`;
  const newTeam: Team = {
    name: teamName,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const newMemberShip: Membership = {
    email: user.email,
    name: user.name,
    logoUrl: user.logoUrl,
    teamName,
    teamId: uid,
    isOwner: true,
    canDelete: true,
    canEdit: true,
    canProcess: true,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const currentUserRef = firestore.collection('users').doc(uid);
  const currentTeamRef = firestore.collection('teams').doc(uid);
  const currentMembershipRef = currentTeamRef
    .collection('memberships')
    .doc(uid);

  const batch = firestore.batch();
  batch.set(currentUserRef, user);
  batch.set(currentTeamRef, { ...newTeam, createdBy: currentUserRef });
  batch.set(currentMembershipRef, {
    ...newMemberShip,
    user: currentUserRef,
  });

  return batch.commit();
};

export const inviteUser = async (
  email: string,
  canDelete: boolean,
  canEdit: boolean,
  canProcess: boolean
) => {
  const createUserAuth = functions.httpsCallable('httpsCreateUser');
  const response = await createUserAuth({
    email,
    canDelete,
    canEdit,
    canProcess,
  });

  const { uid } = response.data;

  // Skip email, if user already exist
  if (!uid) return;

  const actionCodeSettings = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: process.env.REACT_APP_LOGIN_PAGE_URL!,
    handleCodeInApp: true,
  };

  await auth.sendSignInLinkToEmail(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    email,
    actionCodeSettings
  );
};

export const removeUserFromTeam = async (teamId: string, userId: string) => {
  const currentTeamRef = firestore.collection('teams').doc(teamId);
  const currentMembershipRef = currentTeamRef
    .collection('memberships')
    .doc(userId);
  const statsRef = firestore.collection('stats').doc(teamId);

  const batch = firestore.batch();
  batch.delete(currentMembershipRef);

  // update stats count
  batch.update(statsRef, {
    memberCount: firebase.firestore.FieldValue.increment(-1),
    membershipId: currentMembershipRef.id,
    docRef: currentMembershipRef,
    countField: 'memberCount',
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  await batch.commit();

  return userId;
};

export const removeReplyRule = async (teamId: string, replyRuleId: string) => {
  const currentReplyRuleRef = firestore
    .collection('teams')
    .doc(teamId)
    .collection('replyRules')
    .doc(replyRuleId);

  const statsRef = firestore.collection('stats').doc(teamId);

  const batch = firestore.batch();
  batch.delete(currentReplyRuleRef);

  // add count
  batch.update(statsRef, {
    replyRuleCount: firebase.firestore.FieldValue.increment(-1),
    replyRuleId: currentReplyRuleRef.id,
    docRef: currentReplyRuleRef,
    countField: 'replyRuleCount',
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  await batch.commit();

  return replyRuleId;
};

export const removePostInfo = async (
  teamId: string,
  pageId: string,
  postId: string,
  imgUrl: string | undefined
) => {
  const currentPostRef = firestore
    .collection('pages')
    .doc(teamId)
    .collection('facebook')
    .doc(pageId)
    .collection('postInfos')
    .doc(postId);
  const statsRef = firestore.collection('stats').doc(teamId);
  const batch = firestore.batch();
  batch.delete(currentPostRef);

  // add count
  batch.update(statsRef, {
    postCount: firebase.firestore.FieldValue.increment(-1),
    postInfoId: currentPostRef.id,
    docRef: currentPostRef,
    countField: 'postCount',
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  try {
    if (imgUrl) await deleteStorageImage(imgUrl, teamId);
  } catch (error) {
    console.error(`Could not delete image: ${error}`);
  }

  await batch.commit();

  return postId;
};

export const removePage = async (teamId: string, pageId: string) => {
  const currentPageRef = firestore
    .collection('pages')
    .doc(teamId)
    .collection('facebook')
    .doc(pageId);
  const statsRef = firestore.collection('stats').doc(teamId);
  const postInfosRef = currentPageRef.collection('postInfos');

  // delete all posts
  // TODO: Future: move to cloud function in the future?
  const postInfoDocs = await postInfosRef.get();
  for (let i = 0; i < postInfoDocs.docs.length; i += 1) {
    const doc = postInfoDocs.docs[i];
    const postInfo = doc.data() as PostInfoDocument;
    if (postInfo.storageImgUrl)
      // eslint-disable-next-line no-await-in-loop
      await removePostInfo(teamId, pageId, doc.id, postInfo.storageImgUrl);
  }

  const batch = firestore.batch();

  batch.delete(currentPageRef);

  // update stats
  batch.update(statsRef, {
    pageCount: firebase.firestore.FieldValue.increment(-1),
    pageId: currentPageRef.id,
    docRef: currentPageRef,
    countField: 'pageCount',
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  await batch.commit();

  return pageId;
};

export const editPage = async (
  teamId: string,
  pageId: string,
  page: FacebookPage
) => {
  const currentPageRef = firestore
    .collection('pages')
    .doc(teamId)
    .collection('facebook')
    .doc(pageId);
  await currentPageRef.update({
    ...page,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return page;
};

export const deleteUser = async (deleteUserDto: {
  userId: string;
  logoUrl: string | null;
}) => {
  // delete images
  const listRef = storage.ref(`users/${deleteUserDto.userId}`);
  const list = await listRef.listAll();
  const deleteImagesTask: Promise<any>[] = [];
  list.items.forEach((item) => {
    deleteImagesTask.push(item.delete());
  });

  const deleteUserTask = deleteDocument('users', deleteUserDto.userId);

  await Promise.all([...deleteImagesTask, deleteUserTask]);
  return deleteUserDto.userId;
};

export const editUser = async (editUserDto: {
  userId: string;
  user: User;
  file?: File;
}): Promise<UserDocument> => {
  const { userId, user, file } = editUserDto;

  let deleteLogoTask;
  let uploadLogoTask;
  let newLogoUrl = null;
  if (file) {
    newLogoUrl = getBucketImageUrl(userId, file, userId);
    deleteLogoTask = user.logoUrl && deleteStorageImage(user.logoUrl, userId);
    uploadLogoTask = uploadLogo(userId, file);
  }

  const newUser: User = {
    ...user,
    logoUrl: newLogoUrl || user.logoUrl,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  const updateUserDbTask = updateDocument('users', userId, newUser);

  await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
  return { id: userId, ...newUser } as UserDocument;
};

export const getStripeProducts = async () => {
  const products: ProductDocument[] = [];
  const productsDoc = await firestore
    .collection('products')
    .where('active', '==', true)
    .get();

  for (let index = 0; index < productsDoc.size; index += 1) {
    const product: ProductDocument = {
      ...(productsDoc.docs[index].data() as ProductDocument),
      prices: [],
    };
    // eslint-disable-next-line no-await-in-loop
    const priceSnap = await productsDoc.docs[index].ref
      .collection('prices')
      .where('active', '==', true)
      // .orderBy('unit_amount')
      .get();
    priceSnap.forEach((priceDoc) => {
      const price = {
        ...priceDoc.data(),
        id: priceDoc.id,
      };
      product.prices.push(price as PriceDocument);
    });
    products.push(product);
  }
  return products;
};

export const getCustomerSubscription = async (
  userId: string
): Promise<SubscriptionDocument[]> => {
  const subscriptions: SubscriptionDocument[] = [];

  const subscriptionsDoc = await firestore
    .collection('customers')
    .doc(userId)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .get();

  subscriptionsDoc.forEach((doc) => {
    const data = doc.data() as SubscriptionDocument;
    subscriptions.push(data);
  });

  return subscriptions;
};

export const setFbAccessToken = async (teamId: string, accessToken: string) => {
  const currentTokenRef = firestore.collection('tokens').doc(teamId);
  await currentTokenRef.set({ accessToken });
};

export const getFbAccounts = async (teamId: string): Promise<FbAccounts> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    endpoint: 'accounts',
  });

  return JSON.parse(response.data) as FbAccounts;
};

export const getFbAdAccounts = async (
  teamId: string
): Promise<FbAdAccounts> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    endpoint: 'adaccounts',
  });

  return JSON.parse(response.data) as FbAdAccounts;
};

export const getReviewTasks = async (
  teamId: string,
  fbPageId: string,
  igPageId: string | undefined,
  pageRuleId: string | undefined,
  ignoreList: string[]
): Promise<ReviewTask[]> => {
  const createRequest = functions.httpsCallable('httpsRequestReviewTasks');
  const response = await createRequest({
    teamId,
    fbPageId,
    igPageId,
    pageRuleId,
    ignoreList,
  });

  return JSON.parse(response.data) as ReviewTask[];
};

export const processComment = async (
  teamId: string,
  commentId: string,
  replyText: string | undefined,
  shouldLike: boolean,
  platform: Platform,
  pageToken: string,
  pageId: string
): Promise<string> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    commentId,
    endpoint: 'processComment',
    replyText,
    shouldLike,
    platform: platform.toString(),
    pageToken,
    pageId,
  });
  const error = JSON.parse(response.data) as FbError;
  if (error?.error?.message) throw new Error(JSON.stringify(error));
  return response.data;
};

export const deleteComment = async (
  teamId: string,
  commentId: string,
  pageToken: string
): Promise<string> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    commentId,
    endpoint: 'deleteComment',
    pageToken,
  });
  const error = JSON.parse(response.data) as FbError;
  if (error?.error?.message) throw new Error(JSON.stringify(error));
  return response.data;
};

export const hideComment = async (
  teamId: string,
  commentId: string,
  pageToken: string,
  platform: Platform
): Promise<string> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    commentId,
    endpoint: 'hideComment',
    pageToken,
    platform: platform.toString(),
  });
  const error = JSON.parse(response.data) as FbError;
  if (error?.error?.message) throw new Error(JSON.stringify(error));
  return response.data;
};

export const ignoreMessage = async (
  teamId: string,
  pageId: string,
  page: FacebookPage,
  messageId: string
): Promise<FacebookPage> => {
  const newPage: FacebookPage = { ...page };
  newPage.ignoreList.push(messageId);
  const { length } = newPage.ignoreList;
  if (length > 100) newPage.ignoreList.splice(0, length - 100);
  return editPage(teamId, pageId, newPage);
};

export const ignoreComment = async (
  teamId: string,
  pageId: string,
  postInfo: PostInfoDocument,
  commentId: string
): Promise<PostInfoDocument> => {
  const newPost: PostInfo = { ...postInfo };
  newPost.ignoreList.push(commentId);
  const { length } = newPost.ignoreList;
  if (length > 100) newPost.ignoreList.splice(0, length - 100);
  return createOrUpdatePostInfo(teamId, pageId, newPost, postInfo.id);
};

export const getFbFeed = async (
  teamId: string,
  pageId: string,
  postType: PostType,
  after: string | undefined
): Promise<FbFeed | FbMedia> => {
  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    endpoint: 'feed',
    pageId,
    postType: postType.toString(),
    after,
  });

  if (postType === PostType.FacebookFeed)
    return JSON.parse(response.data) as FbFeed;

  return JSON.parse(response.data) as FbMedia;
};

export const getPostInfo = async (
  teamId: string,
  postUrl: string,
  accountId: string,
  postType: PostType
): Promise<PostInfo> => {
  if (
    Number.isNaN(+postUrl) &&
    !postUrl.includes('posts/') &&
    !postUrl.includes('p/')
  )
    throw new Error('PostUrl is invalid.');

  const createFbRequest = functions.httpsCallable('httpsRequestFacebook');
  const response = await createFbRequest({
    teamId,
    endpoint: 'postInfo',
    postUrl,
    accountId,
    postType: postType as number,
  });
  let result: PostInfo | undefined;

  const error = JSON.parse(response.data) as FbError;
  if (error?.error?.message) {
    throw new Error('Invalid Input.');
  }
  if (postType.toString() === PostType.AdCreative.toString()) {
    const data = JSON.parse(response.data) as FbAdCreativeInfo;

    const isInstagramUrl = !!postUrl.includes('instagram');

    if (!data) throw new Error('Post Id not found for this page.');
    if (!data.effective_instagram_media_id && isInstagramUrl)
      throw new Error('Post Id not found for this instagram post.');
    result = {
      postType,
      postId: isInstagramUrl
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data.effective_instagram_media_id!
        : formatPostId(data.effective_object_story_id, postType),
      effectiveInstagramPostId: isInstagramUrl
        ? null
        : data.effective_instagram_media_id || null,
      permalinkUrl: data.instagram_permalink_url || null,
      pictureUrl: data.thumbnail_url,
      replyRuleId: '',
      ignoreList: [],
      ignore: false,
      isInstagramPost: !!isInstagramUrl,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  if (postType.toString() === PostType.FacebookFeed.toString()) {
    const data = JSON.parse(response.data) as FbFacebookFeedInfo;
    if (!data) throw new Error('Post Id not found for this page.');
    result = {
      postType,
      postId: formatPostId(data.id, postType),
      effectiveInstagramPostId: null,
      permalinkUrl: data.permalink_url,
      pictureUrl: data.picture,
      replyRuleId: '',
      ignoreList: [],
      ignore: false,
      isInstagramPost: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }
  if (postType.toString() === PostType.InstagramFeed.toString()) {
    const data = JSON.parse(response.data) as FbInstagramFeedInfo;
    if (!data) throw new Error('Post Id not found for this page.');
    result = {
      postType,
      postId: data.id,
      effectiveInstagramPostId: null,
      pictureUrl:
        data.media_type?.toLowerCase() === 'video'
          ? data.thumbnail_url
          : data.media_url,
      permalinkUrl: data.permalink,
      replyRuleId: '',
      ignoreList: [],
      ignore: false,
      isInstagramPost: true,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }

  if (!result) throw new Error('Invalid data');
  return result;
};

export const connectPage = async (
  teamId: string,
  page: FacebookPage,
  fbMeId: string,
  email: string,
  update?: boolean
): Promise<FacebookPage> => {
  const pagesRef = firestore.collection('pages').doc(teamId);
  const facebookRef = pagesRef.collection('facebook');
  const statsRef = firestore.collection('stats').doc(teamId);

  await pagesRef.set({ fbMeId, email });

  const batch = firestore.batch();

  if (!update) {
    batch.set(facebookRef.doc(page.id), {
      ...page,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // add count
    batch.update(statsRef, {
      pageCount: firebase.firestore.FieldValue.increment(1),
      pageId: page.id,
      docRef: facebookRef.doc(page.id),
      countField: 'pageCount',
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } else
    batch.update(facebookRef.doc(page.id), {
      ...page,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  await batch.commit();

  return page;
};

export const getFacebookPages = async (
  teamId: string
): Promise<FacebookPage[]> => {
  const pages: FacebookPage[] = [];
  const docs = await firestore
    .collection('pages')
    .doc(teamId)
    .collection('facebook')
    .get();

  docs.forEach((doc) => {
    pages.push({ id: doc.id, ...doc.data() } as FacebookPage);
  });

  return pages;
};
