import {
    Button,
    forwardRef,
    HStack,
    Select,
    useDisclosure
} from '@chakra-ui/react';
import ReplyRuleModal from '../../components/ReplyRule/ReplyRuleModal';
import { useReplyRules } from '../../hooks/api/query/useReplyRules';
import { usePreferences } from '../../state/context/PreferencesContext';

const ReplyRuleSelect = forwardRef(
  (
    props: {
      name: string | undefined;
      replyRuleId: string | undefined;
    },
    ref
  ) => {
    const { name, replyRuleId } = props;
    const { state } = usePreferences();
    const { data: replyRules } = useReplyRules(
      (state?.currentTeamId || '') !== '',
      state.currentTeamId || ''
    );
    const {
      isOpen: isAddOpen,
      onOpen: onAddOpen,
      onClose: onAddClose,
    } = useDisclosure();
    const {
      isOpen: isEditOpen,
      onOpen: onEditOpen,
      onClose: onEditClose,
    } = useDisclosure();
    return (
      <>
        {replyRules && replyRules.length > 0 && (
          <HStack>
            <Select ref={ref} name={name}>
              {replyRules.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.ruleName}
                </option>
              ))}
            </Select>
            <Button onClick={onEditOpen}>Edit</Button>
          </HStack>
        )}
        <Button
          mt="3"
          alignSelf="flex-start"
          colorScheme="blue"
          onClick={onAddOpen}
        >
          Add New Rule
        </Button>
        <ReplyRuleModal isOpen={isAddOpen} onClose={onAddClose} />
        <ReplyRuleModal
          ruleId={replyRuleId}
          isOpen={isEditOpen}
          onClose={onEditClose}
        />
      </>
    );
  }
);

export default ReplyRuleSelect;
