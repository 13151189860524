/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { RiAddFill } from 'react-icons/ri';
import { NavLink as RouterLink } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

function GlobalFilter(props: {
  globalFilter: any;
  setGlobalFilter: any;
  filterPlaceholder: string;
}) {
  const { globalFilter, setGlobalFilter, filterPlaceholder } = props;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((v) => {
    setGlobalFilter(v || undefined);
  }, 200);

  return (
    <InputGroup size="sm">
      <FormLabel srOnly>Filter by name or email</FormLabel>
      <InputLeftElement pointerEvents="none" color="gray.400">
        <BsSearch />
      </InputLeftElement>
      <Input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        rounded="base"
        type="search"
        placeholder={filterPlaceholder}
      />
    </InputGroup>
  );
}

export const TableActions = (props: {
  globalFilter: any;
  filterPlaceholder: string;
  setGlobalFilter: any;
  newButtonText?: string;
  newButtonUrl?: string;
  newButtonAction?: () => void;
}) => {
  const {
    globalFilter,
    filterPlaceholder,
    setGlobalFilter,
    newButtonText,
    newButtonUrl,
    newButtonAction,
  } = props;
  return (
    <Stack
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      <HStack>
        <FormControl minW={{ md: '320px' }} id="search">
          <GlobalFilter
            globalFilter={globalFilter}
            filterPlaceholder={filterPlaceholder}
            setGlobalFilter={setGlobalFilter}
          />
        </FormControl>
      </HStack>
      <ButtonGroup size="sm" variant="outline">
        {newButtonUrl && (
          <Button
            iconSpacing="1"
            leftIcon={<RiAddFill fontSize="1.25em" />}
            as={RouterLink}
            to={newButtonUrl}
          >
            {newButtonText}
          </Button>
        )}
        {newButtonAction && (
          <Button
            iconSpacing="1"
            leftIcon={<RiAddFill fontSize="1.25em" />}
            onClick={newButtonAction}
          >
            {newButtonText}
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};
