import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getReviewTasks } from '../../../api/firebase';

export default function useReviewTasks(
  enabled: boolean,
  teamId: string,
  fbPageId: string,
  igPageId: string | undefined,
  pageRuleId: string | undefined,
  ignoreList: string[]
) {
  const toast = useToast();
  return useQuery(
    [fbPageId, 'reviewTasks'],
    () => getReviewTasks(teamId, fbPageId, igPageId, pageRuleId, ignoreList),
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
}
