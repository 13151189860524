/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import * as React from 'react';
import { useRef } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { HiCloudUpload } from 'react-icons/hi';
import { useFormatMessage } from '../../../hooks';

interface FileUploadProps {
  name: string;
  placeholder: string;
  acceptedFileTypes: string;
  control: Control<FieldValues>;
  register: (e: HTMLInputElement | null) => void;
  isRequired?: boolean;
  children: React.ReactNode;
}
const FileUpload = (props: FileUploadProps) => {
  const {
    name,
    acceptedFileTypes,
    control,
    register,
    isRequired,
    children,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    field: { ref, value, onChange, ...inputProps },
    meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
  });

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor="writeUpFile">{children}</FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <HiCloudUpload />
        </InputLeftElement>
        <input
          {...inputProps}
          type="file"
          accept={acceptedFileTypes}
          ref={(e) => {
            register(e);
            inputRef.current = e;
          }}
          name={name}
          style={{ display: 'none' }}
        />
        <Button
          onClick={() => inputRef.current?.click()}
          leftIcon={<HiCloudUpload />}
        >
          {useFormatMessage('FileUpload.changePhoto')}
        </Button>
      </InputGroup>
      <FormErrorMessage>{invalid}</FormErrorMessage>
    </FormControl>
  );
};

export default FileUpload;
