import { useToast } from '@chakra-ui/toast';
import { useMutation } from 'react-query';
import { getPostInfo } from '../../../api/firebase';
import { PostType } from '../../../state/model/firebase';

export default function useVerifyPost() {
  const toast = useToast();
  return useMutation(
    (variables: { teamId: string; postUrl: string; accountId: string; postType: PostType }) =>
      getPostInfo(variables.teamId, variables.postUrl, variables.accountId, variables.postType),
    {
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
}

