import { Box, Heading, useColorModeValue as mode } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import { SigninForm } from '../../components/Authentication/SignInForm/SignInForm';
import MyLogo from '../../components/Icons/MyLogo';
import { useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';

const ConfirmLogin = () => {
  const { currentUser, verified } = useAuth();

  const redirect =
    !!currentUser?.emailVerified || verified ? (
      <Redirect to={paths.ROOT} />
    ) : null;

  return (
    <>
      {redirect}
      <Box
        bg={mode('gray.50', 'inherit')}
        minH="100vh"
        py="12"
        px={{ sm: '6', lg: '8' }}
      >
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} w={{ sm: 'full' }}>
          <Box mb={{ base: '10', md: '28' }}>
            <MyLogo mx="auto" h="8" iconColor={mode('blue.500', 'blue.200')} />
          </Box>
          <Heading mt="6" textAlign="center" size="xl" fontWeight="extrabold">
            Confirm Password
          </Heading>
        </Box>
        <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} mt="8" w={{ sm: 'full' }}>
          <Box
            bg={mode('white', 'gray.700')}
            py="8"
            px={{ base: '4', md: '10' }}
            shadow="base"
            rounded={{ sm: 'lg' }}
          >
            <SigninForm />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmLogin;
