import { CheckIcon, DeleteIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Avatar, IconButton, Stack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { useFormatMessage } from '..';
import { MembershipDocument } from '../../state/model/firebase';

export interface Columns extends MembershipDocument {
  actions?: true;
}

export function useMembersColumns(
  currentTeamId: string,
  currentUserId: string,
  onRemoveButtonClickHandler: (id: string) => void
) {
  const name = useFormatMessage('Users.name');
  const email = useFormatMessage('Users.email');
  const owner = useFormatMessage('Team.owner');
  const edit = useFormatMessage('Team.edit');
  const deleteMessage = useFormatMessage('Team.delete');
  const process = useFormatMessage('Team.process');

  return useMemo(
    (): Column<Columns>[] => [
      {
        Header: '',
        accessor: 'logoUrl',
        Cell: ({ row }) => (
          <Avatar
            h="10"
            w="10"
            src={row.original.logoUrl || undefined}
            name={row.original.name}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: name,
        accessor: 'name',
      },
      {
        Header: email,
        accessor: 'email',
      },
      {
        Header: owner,
        accessor: 'isOwner',
        Cell: ({ row }) => (
          <small>
            {row.original.isOwner ? <CheckIcon /> : <SmallCloseIcon />}
          </small>
        ),
      },
      {
        Header: deleteMessage,
        accessor: 'canDelete',
        Cell: ({ row }) => (
          <small>
            {row.original.canDelete ? <CheckIcon /> : <SmallCloseIcon />}
          </small>
        ),
      },
      {
        Header: edit,
        accessor: 'canEdit',
        Cell: ({ row }) => (
          <small>
            {row.original.canEdit ? <CheckIcon /> : <SmallCloseIcon />}
          </small>
        ),
      },
      {
        Header: process,
        accessor: 'canProcess',
        Cell: ({ row }) => (
          <small>
            {row.original.canProcess ? <CheckIcon /> : <SmallCloseIcon />}
          </small>
        ),
      },
      {
        Header: '',
        id: 'actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {row.original.id !== currentTeamId &&
              (currentUserId === currentTeamId ||
                row.original.id === currentUserId) && (
                <Stack direction="row" spacing={4}>
                  <IconButton
                    size="xs"
                    colorScheme="red"
                    aria-label="Delete user"
                    icon={<DeleteIcon />}
                    onClick={() => onRemoveButtonClickHandler(row.original.id)}
                  />
                </Stack>
              )}
          </>
        ),
        disableSortBy: true,
      },
    ],
    [
      email,
      name,
      owner,
      edit,
      deleteMessage,
      process,
      currentTeamId,
      currentUserId,
      onRemoveButtonClickHandler,
    ]
  );
}
