import { useState } from 'react';

const determineState = (activeIndex: number, index: number) => {
  if (activeIndex > index) return 'complete';
  if (activeIndex === index + 1) return 'active';
  return 'incomplete';
};

export const useProgressState = <T extends Record<string, unknown>[]>(
  steps: T
) => {
  const [activeStep, setActiveStep] = useState(1);
  const factor = steps.length - 1;
  return {
    value: (100 / factor) * (activeStep - 1),
    activeStep,
    getState: (index: number) => determineState(activeStep, index),
    onClick: (index: number) => () => setActiveStep(index + 1),
    goToNextStep: () => setActiveStep((prevStep) => prevStep + 1),
  };
};
