import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { deleteComment } from '../../../api/firebase';
import { ReviewTask } from '../../../state/model/firebase';

export default function useDeleteComment(onSettled: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    (variables: {
      pageId: string;
      teamId: string;
      commentId: string;
      pageToken: string;
    }) =>
      deleteComment(variables.teamId, variables.commentId, variables.pageToken),
    {
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: (_, error, variables) => {
        onSettled();
        if (error) return;
        const previousTasks = queryClient.getQueryData([
          variables.pageId,
          'reviewTasks',
        ]) as ReviewTask[];
        if (previousTasks)
          queryClient.setQueryData(
            [variables.pageId, 'reviewTasks'],
            previousTasks.filter(
              (task) => task.userMessage.parent.id !== variables.commentId
            )
          );
      },
    }
  );
}
