import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { editUser } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { MembershipDocument, User } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useEditProfile() {
  const { locale } = usePreferences().state;
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (editUserDto: { userId: string; user: User; file: File }) =>
      editUser(editUserDto),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (newUserData) => {
        queryClient.setQueryData('profile', newUserData);
        const previousMembers = queryClient.getQueryData('members') as MembershipDocument[];
        if (previousMembers) {
          queryClient.setQueryData(
            'members',
            previousMembers.map((user) => {
              if (user.id === newUserData.id) return {...user, ...newUserData};
              return user;
            })
          );
        } else {
          queryClient.setQueryData('members', [newUserData]);
        }
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('members');
      },
    }
  );
}
