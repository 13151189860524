import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { setUserLocale, usePreferences } from '../../state/context/PreferencesContext';
import { availableLocales, browserLocale, Locale, messages } from '../../utils';

const LanguageWrapper = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { state, dispatch } = usePreferences();
  let { locale } = state;

  if (!locale) {
    locale = availableLocales.includes(browserLocale as Locale)
      ? browserLocale as Locale
      : 'en';
    setUserLocale(dispatch, locale);
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;
