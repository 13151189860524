import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { removePage } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { FacebookPage } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useDeletePage() {
  const queryClient = useQueryClient();

  const { locale } = usePreferences().state;
  const toast = useToast();
  // TODO: translate
  // const sucessMessage = useFormatMessage('Team.removeSuccess');
  const sucessMessage = 'Page deleted sucessfully';

  return useMutation(
    (variables: { teamId: string; pageId: string }) =>
      removePage(variables.teamId, variables.pageId),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (deletedPageId) => {
        const previousPages = queryClient.getQueryData(
          'facebookPages'
        ) as FacebookPage[];
        if (previousPages)
          queryClient.setQueryData(
            'facebookPages',
            previousPages.filter((page) => page.id !== deletedPageId)
          );

        // Clear post infos from deleted page
        queryClient.setQueryData([deletedPageId, 'postInfos'], []);

        queryClient.invalidateQueries('facebookPages');
        queryClient.invalidateQueries([deletedPageId, 'postInfos']);

        toast({
          description: sucessMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
