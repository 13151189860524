import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { ignoreComment } from '../../../api/firebase';
import { PostInfoDocument, ReviewTask } from '../../../state/model/firebase';

export default function useIgnoreComment(onSettled: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    (variables: {
      pageId: string;
      teamId: string;
      postInfo: PostInfoDocument;
      commentId: string;
    }) =>
      ignoreComment(
        variables.teamId,
        variables.pageId,
        variables.postInfo,
        variables.commentId
      ),
    {
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: (newPostInfo, error, variables) => {
        onSettled();
        if (error) return;

        // Update Review tasks
        const previousTasks = queryClient.getQueryData([
          variables.pageId,
          'reviewTasks',
        ]) as ReviewTask[];
        if (previousTasks)
          queryClient.setQueryData(
            [variables.pageId, 'reviewTasks'],
            previousTasks.filter(
              (task) => task.userMessage.parent.id !== variables.commentId
            )
          );

          if (!newPostInfo) return; // should not happen onSuccess

          const previousPostInfos = queryClient.getQueryData([
            variables.pageId,
            'postInfos',
          ]) as PostInfoDocument[];
          if (previousPostInfos) {
            queryClient.setQueryData(
              [variables.pageId, 'postInfos'],
              previousPostInfos.map((postInfo) => {
                if (postInfo.id === newPostInfo.id) return newPostInfo;
                return postInfo;
              })
            );
          } else {
            queryClient.setQueryData(
              [variables.pageId, 'postInfos'],
              [newPostInfo]
            );
          }
          queryClient.invalidateQueries([variables.pageId, 'postInfos']);
      },
    }
  );
}
