import * as React from 'react';
import { createContext, useContext, useReducer } from 'react';
import { Locale } from '../../utils/index';
import {
  Action,
  authReducer,
  LOCALSTORAGE_KEY_CURRENT_PAGE,
  LOCALSTORAGE_KEY_CURRENT_TEAM,
  LOCALSTORAGE_KEY_HAS_FB,
  LOCALSTORAGE_KEY_LOCALE,
  State
} from '../reducers/preferences';

type Dispatch = (action: Action) => void;
type LocaleProviderProps = { children: React.ReactNode };
const PreferencesContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const storageLocaleState = localStorage.getItem(LOCALSTORAGE_KEY_LOCALE);
const storageTeamState = localStorage.getItem(LOCALSTORAGE_KEY_CURRENT_TEAM);
const storagePageState = localStorage.getItem(LOCALSTORAGE_KEY_CURRENT_PAGE);
const storageHasFbState = localStorage.getItem(LOCALSTORAGE_KEY_HAS_FB);
const initialState: State = {
  locale: storageLocaleState ? (storageLocaleState as Locale) : 'en',
  currentTeamId: storageTeamState,
  currentPageId: storagePageState,
  hasFb: storageHasFbState === '1',
};

function PreferencesProvider({ children }: LocaleProviderProps) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
}

const usePreferences = () => {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error('useLocale must be used within a PreferencesProvider');
  }
  return context;
};

const setUserLocale = (dispatch: Dispatch, locale: Locale) => {
  dispatch({ type: 'PREFERENCES_SET_LOCALE', locale });
};

const setCurrentTeamId = (dispatch: Dispatch, teamId: string | null) => {
  dispatch({ type: 'PREFERENCES_SET_CURRENT_TEAM', teamId });
};

const setCurrentPageId = (dispatch: Dispatch, pageId: string | null) => {
  dispatch({ type: 'PREFERENCES_SET_CURRENT_PAGE', pageId });
};

const setHasFb = (dispatch: Dispatch, hasFb: boolean | null) => {
  dispatch({ type: 'PREFERENCES_SET_HAS_FB', hasFb });
};

export {
  PreferencesProvider,
  usePreferences,
  setUserLocale,
  setCurrentTeamId,
  setCurrentPageId,
  setHasFb,
};
