import {
  Box,
  Button,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure
} from '@chakra-ui/react';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { FiSmartphone } from 'react-icons/fi';
import { NavLink, Redirect } from 'react-router-dom';
import Benefits from '../../assets/signup-page.jpg';
import { SigupForm } from '../../components/Authentication/SignupForm/SignupForm';
import { DividerWithText } from '../../components/DividerWithText/DividerWithText';
import MyLogo from '../../components/Icons/MyLogo';
import InstallAppModal from '../../components/Modal/InstallAppModal';
import useAuthProfile from '../../hooks/api/mutation/useAuthProfile';
import { signInWithGoogle, useAuth } from '../../state/context/AuthContext';
import paths from '../Router/paths';

const SignUp = () => {
  const { currentUser, signInWithFacebook } = useAuth();
  const isAuth = !!currentUser?.uid;
  const redirect = isAuth && <Redirect to={paths.ROOT} />;
  const { error, isLoading, mutate } = useAuthProfile();
  const errorMessage = error ? `${error}` : null;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {redirect}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        overflow="hidden"
        minH="100vh"
        height="100%"
        bg={mode('gray.50', 'inherit')}
      >
        <Box
          overflowY="auto"
          flex="1"
          py={{ base: '10', md: '16' }}
          px={{ base: '6', md: '10' }}
        >
          <Box
            maxW="sm"
            mx="auto"
            bg={{ md: mode('white', 'gray.700') }}
            rounded={{ md: '2xl' }}
            p={{ base: '4', md: '12' }}
            borderWidth={{ md: '1px' }}
            borderColor={mode('gray.200', 'transparent')}
            shadow={{ md: 'lg' }}
            marginTop="10"
          >
            <MyLogo
              h="7"
              mb={{ base: '16', md: '10' }}
              iconColor="blue.500"
              mx={{ base: 'auto', md: 'unset' }}
            />
            <Button
              leftIcon={<FiSmartphone/>}
              as={Button}
              bgColor="transparent"
              onClick={onOpen}
              mb="8"
              mx={{ base: 'auto', md: 'unset' }}
              display={{ base: 'block', md: 'none' }}
              variant='outline'
            >
              Install App
            </Button>
            <Box mb="8">
              <Heading size="lg" mb="2" fontWeight="extrabold">
                Welcome to TurboReply
              </Heading>
              <Text
                fontSize="lg"
                color={mode('gray.600', 'gray.400')}
                fontWeight="medium"
              >
                Enter your info to get started
              </Text>
            </Box>
            <Stack spacing="4">
              <Button
                variant="outline"
                leftIcon={<Box as={FaGoogle} color="red.500" />}
                onClick={() => mutate(signInWithGoogle)}
                isLoading={isLoading}
              >
                Sign up with Google
              </Button>
              <Button
                variant="outline"
                leftIcon={
                  <Box
                    as={FaFacebook}
                    color={mode('facebook.500', 'facebook.300')}
                  />
                }
                isLoading={isLoading}
                onClick={() => mutate(signInWithFacebook)}
              >
                Sign up with Facebook
              </Button>
            </Stack>
            {errorMessage ? (
              <Text
                mt="3"
                color={mode('red.600', 'red.400')}
                fontWeight="medium"
              >
                {errorMessage}
              </Text>
            ) : null}
            <DividerWithText>or</DividerWithText>
            <SigupForm />
          </Box>
          <Text mt="3" align="center" fontSize="xs" fontStyle="italic">
            By clicking „Sign up with…” you agree to our{' '}
            <Text
              as="a"
              fontWeight="bold"
              href="https://turboreply.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Text>{' '}
            and{' '}
            <Text
              as="a"
              fontWeight="bold"
              href="https://turboreply.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Text>
            .
          </Text>
          <Text mt="5" align="center" fontWeight="medium">
            Already have an account?{' '}
            <Box
              as={NavLink}
              to={paths.LOGIN}
              color={mode('blue.600', 'blue.200')}
              display={{ base: 'block', md: 'inline-block' }}
            >
              Log in to TurboReply
            </Box>
          </Text>
        </Box>

        <Box
          display={{ base: 'none', lg: 'block' }}
          flex="1"
          bg="blue.500"
          color="white"
          px="20"
          pt="32"
        >
          {/* <Badge
            bg="blue.700"
            px="4"
            py="1"
            rounded="md"
            letterSpacing="wide"
            color="whiteAlpha.900"
          >
            New
          </Badge> */}
          {/* <Text
            mt="6"
            fontWeight="extrabold"
            fontSize={{ base: '2xl', lg: '2xl' }}
            maxW="sm"
            letterSpacing="tight"
            lineHeight="normal"
          >
            Save hours managing your brand&apos;s social media profiles.
          </Text> */}
          <Text
            fontWeight="bold"
            mt="6"
            fontSize={{ base: '2xl', lg: '2xl' }}
            maxW="sm"
            letterSpacing="tight"
            lineHeight="normal"
          >
            Get back up to 70% of your time and eneregy.
          </Text>
          {/* TODO: future? */}
          {/* <Testimonials /> */}
          {/* <Carousel /> */}
          <Text mt="5" maxW="md" fontSize="lg">
            Save hours managing your brand&apos;s social media profiles.
          </Text>
          <Box mt="10" position="relative" insetEnd="6">
            {/* TODO: add screenshot */}
            <Img alt="App screenshot" src={Benefits} width="lg" />
          </Box>

          {/* TODO: future? */}
          {/* <Box
            maxW="sm"
            mx="auto"
            bg={{ md: mode('white', 'gray.700') }}
            rounded={{ md: 'lg' }}
            px={{ base: '4', md: '12' }}
            py="4"
            borderWidth={{ md: '1px' }}
            borderColor={mode('gray.200', 'transparent')}
            shadow={{ md: 'lg' }}
            marginTop="10"
            color={mode('black', 'gray.200')}
          >
            <Stack
              spacing="8"
              justify="space-between"
              direction={{ base: 'column', lg: 'row' }}
              divider={<StackDivider />}
            >
              <VStack>
                <TrustPilot w="24" />
                <HStack>
                  <StarIcon color="yellow.400" />
                  <Text>
                    <Text as="b">4.8</Text>/5
                  </Text>
                </HStack>
              </VStack>
              <VStack>
                <Capterra w="24" />
                <HStack>
                  <StarIcon color="yellow.400" />
                  <Text>
                    <Text as="b">4.7</Text>/5
                  </Text>
                </HStack>
              </VStack>
            </Stack>
          </Box> */}
        </Box>
      </Flex>
      <InstallAppModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SignUp;
