import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
  VStack
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useFormatDate } from '../../hooks';
import { useStats } from '../../hooks/api/query/useStats';
import useStripe from '../../hooks/api/useStripe';
import usePermission from '../../hooks/Permission/usePermission';
import { usePreferences } from '../../state/context/PreferencesContext';
import { SubscriptionDocument } from '../../state/model/firebase';
import paths from '../Router/paths';

const StatsElement = (props: {
  count: number;
  max: number | string;
  name: string;
}) => {
  const { count, max, name } = props;

  return (
    <Stack direction="row" as="dd" mt="2" align="flex-end">
      <Box
        fontSize="lg"
        as="span"
        fontWeight="bold"
        color={mode('gray.800', 'white')}
      >
        {count}
      </Box>
      <Flex fontWeight="semibold">
        <Box as="span" aria-hidden>
          /
        </Box>
        <Box srOnly>out of</Box>
        <Text ps="1">
          {max} {name}
        </Text>
      </Flex>
    </Stack>
  );
};

const ProfileSubscription = (props: {
  subscription: SubscriptionDocument | undefined;
}) => {
  const { subscription } = props;
  const stripe = useStripe();
  const { state } = usePreferences();
  const { data: stats, isLoading } = useStats(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );

  const cancelAt = useFormatDate(subscription?.cancelAt?.toDate());
  const nextPeriodEnd = useFormatDate(stats?.nextMonthlyPeriodEnd?.toDate());

  const getDifferenceInDays = (a: Date, b: Date) => {
    const differenceInTime = new Date(a).getTime() - new Date(b).getTime();
    // calculate the no. of days between two dates
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };
  const { subscriptionLimits, stripeRole } = usePermission();
  const {
    MAX_PAGES,
    MAX_MEMBERS,
    MAX_MONTHLY_REPLIES,
    MAX_POSTS,
    MAX_REPLIES,
    MAX_RULES,
  } = subscriptionLimits;

  return (
    <Box as="section" p="6">
      <Box
        maxW={{ base: 'xl', md: '5xl' }}
        mx="auto"
        px={{ base: '2', md: '8' }}
      >
        <Box bg={mode('white', 'gray.700')} p="10" rounded="xl" shadow="base">
          <Stack
            spacing="8"
            justify="space-between"
            direction={{ base: 'column', lg: 'row' }}
            divider={<StackDivider />}
          >
            <Stack mx="auto" spacing="3" maxWidth="40">
              <Heading as="h1" size="xl" fontWeight="bold">
                {subscription?.role ? subscription.role.toUpperCase() : 'FREE'}
              </Heading>
              <Text>
                You are current subscribed to the{' '}
                {subscription
                  ? `${subscription.role.toUpperCase()} plan with ${
                      subscription.currentPeriodEnd &&
                      subscription.created &&
                      getDifferenceInDays(
                        subscription.currentPeriodEnd.toDate(),
                        subscription.created.toDate()
                      ) > 35
                        ? 'yearly'
                        : 'monthly'
                    } billing.` // TODO: add yearly. translations
                  : 'free plan.'}
              </Text>
              <Divider />
              <Button
                as={NavLink}
                disabled={stripe.isLoading}
                colorScheme="blue"
                to={paths.SUBSCRIPTION}
                h="8"
                fontSize="sm"
              >
                Upgrade Plan
              </Button>
            </Stack>
            <Stack mx="auto" spacing="0">
              <Box color={mode('gray.600', 'gray.400')} fontWeight="medium">
                Your Usage
              </Box>
              {!isLoading && stats && (
                <VStack
                  divider={<StackDivider />}
                  spacing="0"
                  align="flex-start"
                >
                  <StatsElement
                    count={stats.pageCount}
                    max={MAX_PAGES}
                    name="Pages"
                  />
                  <StatsElement
                    count={stats.memberCount}
                    max={MAX_MEMBERS}
                    name="Team members"
                  />
                  <StatsElement
                    count={stats.postCount}
                    max={MAX_POSTS}
                    name="Posts"
                  />
                  <StatsElement
                    count={stats.replyRuleCount}
                    max={MAX_RULES}
                    name="Rules"
                  />
                  <StatsElement
                    count={stats.replyCount}
                    max={MAX_REPLIES || '∞'}
                    name="Replies"
                  />
                  {MAX_MONTHLY_REPLIES && (
                    <StatsElement
                      count={stats.monthlyReplyCount}
                      max={MAX_MONTHLY_REPLIES}
                      name="Monthly Replies"
                    />
                  )}
                  {subscription?.role === 'basic' &&
                    stats.nextMonthlyPeriodEnd && (
                      <Text fontSize="sm">recharges at {nextPeriodEnd}</Text>
                    )}
                </VStack>
              )}
            </Stack>
            <Stack mx="auto" spacing="3">
              <Box color={mode('gray.600', 'gray.400')} fontWeight="medium">
                Details
              </Box>
              {subscription && (
                <HStack>
                  <Text>Status:</Text>
                  <Badge
                    fontSize="sm"
                    px="2"
                    rounded="full"
                    colorScheme={
                      subscription.status === 'inactive' ? 'red' : 'green'
                    }
                  >
                    {subscription.status}
                  </Badge>
                </HStack>
              )}
              {subscription?.cancelAt ? (
                <>
                  <Text color={mode('red.500', 'red.200')}>
                    Cancels at: {cancelAt}
                  </Text>
                  <Text color={mode('red.500', 'red.200')}>
                    WARNING: You may lose the amount of your data that exceeds
                    the limit of the free plan. Upgrade to a subscription to
                    prevent this.
                  </Text>
                </>
              ) : null}
              <Button
                leftIcon={<ExternalLinkIcon />}
                isLoading={stripe.isLoading}
                onClick={stripe.sendToCustomerPortal}
                colorScheme="blue"
                isDisabled={!stripeRole}
                loadingText="Please wait..."
              >
                Manage Billing
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileSubscription;
