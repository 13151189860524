import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  ListItem,
  SimpleGrid,
  Switch,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsArrow90DegUp } from 'react-icons/bs';
import {
  FaFacebook,
  FaFacebookMessenger,
  FaInstagram,
  FaRegPaperPlane
} from 'react-icons/fa';
import { IoAirplaneOutline, IoRocketOutline } from 'react-icons/io5';
import { ActionButton } from '../../components/ActionButton/ActionButton';
import { PricingCard } from '../../components/PricingCard/PricingCard';
import { analytics } from '../../firebase';
import { useProducts } from '../../hooks/api/query/useProducts';
import { useSubscriptions } from '../../hooks/api/query/useSubscriptions';
import useStripe from '../../hooks/api/useStripe';
import { useAuth } from '../../state/context/AuthContext';
import { SubscriptionRole } from '../../state/model/firebase';

const Icons = () => {
  return (
    <HStack spacing="2">
      <FaFacebook />
      <FaInstagram />
      <FaFacebookMessenger />
    </HStack>
  );
};

const Subscription = () => {
  const { currentUser } = useAuth();
  const { data: products, isSuccess } = useProducts();
  const { data: subscriptions, isSuccess: subLoaded } = useSubscriptions(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const stripe = useStripe();

  const [showYearly, setShowYearly] = useState(1);

  // sort prices
  const sortedProducts = products?.sort(
    (a, b) =>
      a.prices.sort((x, y) => x.unit_amount - y.unit_amount)[0].unit_amount -
      b.prices.sort((x, y) => x.unit_amount - y.unit_amount)[0].unit_amount
  );
  const subscription =
    subscriptions && subscriptions?.length > 0 ? subscriptions[0] : undefined;

  const onBuyHandler = (
    userId: string,
    productId: string,
    unitAmount: number,
    name: SubscriptionRole,
    yearly: boolean
  ) => {
    if (subscription || !subLoaded) {
      return stripe.sendToCustomerPortal();
    }

    return stripe.subscribe(userId, productId, unitAmount, name, yearly);
  };

  useEffect(() => {
    // google analytics
    analytics.logEvent('page_view', {
      page_location: 'https://app.turboreply.com/subscription',
      page_path: '/subscription',
      page_title: 'Subscription',
    });
  }, []);


  return (
    // TOOD: Fix scroll
    <Box w="full" h="full" overflowY="auto">
      <Box
        as="section"
        bg={useColorModeValue('gray.50', 'gray.800')}
        py="12"
        px={{ base: '4', md: '8' }}
      >
        <Heading size="xl" textAlign="center" pb="6" id="pricing">
          Pricing
        </Heading>
          <Text size="md" textAlign="center"  pb='4'>
            All plans come with a <Text as="b">14 days FREE trial period.</Text>
          </Text>
        <VStack spacing="1">
          <HStack spacing="3">
            <Text fontWeight="bold">Monthly</Text>
            <Switch
              defaultChecked={showYearly === 1}
              onChange={(e) => setShowYearly(e.target.checked ? 1 : 0)}
            />
            <Text fontWeight="bold">Yearly</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold" color="blue.400">
              Save 50%!
            </Text>
            <Icon
              transform="scale(-1,1);"
              w={7}
              h={7}
              color="blue.400"
              as={BsArrow90DegUp}
            />
          </HStack>
          <Text fontWeight="bold" color="blue.400">
            (Limited Offer)
          </Text>
        </VStack>
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={{ base: '8', lg: '0' }}
          maxW="4xl"
          mx="auto"
          justifyItems="center"
          alignItems="center"
          pb="12"
          mt="8"
        >
          {isSuccess &&
            sortedProducts &&
            currentUser?.uid &&
            sortedProducts.length > 0 && (
              <>
                <PricingCard
                  data={{
                    price: `$${
                      !showYearly
                        ? sortedProducts[0].prices[0].unit_amount / 100
                        : sortedProducts[0].prices[1].unit_amount / 100 / 12
                    }`,
                    secondPrice: `$${
                      sortedProducts[0].prices[showYearly].unit_amount / 100
                    }`,
                    showYearly: showYearly === 1,
                    name: sortedProducts[0].name,
                    features: [
                      { text: '1 Social Set', icon: Icons },
                      { text: '2 Team members' },
                      { text: '10 Posts' },
                      { text: '5 Rules (unlimited reply conditions)' },
                      { text: '150 replies per month' },
                    ],
                  }}
                  icon={FaRegPaperPlane}
                  button={
                    <ActionButton
                      variant="outline"
                      borderWidth="2px"
                      isLoading={stripe.isLoading}
                      loadingText="Please wait..."
                      onClick={() =>
                        onBuyHandler(
                          currentUser.uid,
                          sortedProducts[0].prices[showYearly].id,
                          sortedProducts[0].prices[showYearly].unit_amount /
                            100,
                          'basic',
                          showYearly === 1
                        )
                      }
                    >
                      {subscription ? 'Change Subscription' : 'TRY FOR FREE'}
                    </ActionButton>
                  }
                />
                <PricingCard
                  zIndex={1}
                  isPopular
                  transform={{ lg: 'scale(1.05)' }}
                  data={{
                    price: `$${
                      !showYearly
                        ? sortedProducts[1].prices[0].unit_amount / 100
                        : sortedProducts[1].prices[1].unit_amount / 100 / 12
                    }`,
                    secondPrice: `$${
                      sortedProducts[1].prices[showYearly].unit_amount / 100
                    }`,
                    showYearly: showYearly === 1,
                    name: sortedProducts[1].name,
                    features: [
                      { text: '2 Social Sets', icon: Icons },
                      { text: '3 Team members' },
                      { text: '40 Posts' },
                      { text: '20 Rules (unlimited reply conditions)' },
                      { text: 'UNLIMITED replies' },
                    ],
                  }}
                  icon={IoAirplaneOutline}
                  button={
                    <ActionButton
                      isLoading={stripe.isLoading}
                      loadingText="Please wait..."
                      onClick={() =>
                        onBuyHandler(
                          currentUser.uid,
                          sortedProducts[1].prices[showYearly].id,
                          sortedProducts[1].prices[showYearly].unit_amount /
                            100,
                          'pro',
                          showYearly === 1
                        )
                      }
                    >
                      {subscription ? 'Change Subscription' : 'TRY FOR FREE'}
                    </ActionButton>
                  }
                />
                <PricingCard
                  data={{
                    price: `$${
                      !showYearly
                        ? sortedProducts[2].prices[0].unit_amount / 100
                        : sortedProducts[2].prices[1].unit_amount / 100 / 12
                    }`,
                    secondPrice: `$${
                      sortedProducts[2].prices[showYearly].unit_amount / 100
                    }`,
                    showYearly: showYearly === 1,
                    name: sortedProducts[2].name,
                    features: [
                      { text: '10 Social Sets', icon: Icons },
                      { text: '10 Team members' },
                      { text: '300 Posts' },
                      { text: '50 Rules (unlimited reply conditions)' },
                      { text: 'UNLIMITED replies' },
                    ],
                  }}
                  icon={IoRocketOutline}
                  button={
                    <ActionButton
                      variant="outline"
                      borderWidth="2px"
                      isLoading={stripe.isLoading}
                      loadingText="Please wait..."
                      onClick={() =>
                        onBuyHandler(
                          currentUser.uid,
                          sortedProducts[2].prices[showYearly].id,
                          sortedProducts[2].prices[showYearly].unit_amount /
                            100,
                          'agency',
                          showYearly === 1
                        )
                      }
                    >
                      {subscription ? 'Change Subscription' : 'TRY FOR FREE'}
                    </ActionButton>
                  }
                />
              </>
            )}
        </SimpleGrid>
        <Flex justify="center">
          <UnorderedList>
            <ListItem>All prices are US Dollars.</ListItem>
            <ListItem>
              When you upgrade, you card will automatically be charged monthly
              or yearly.
            </ListItem>
            <ListItem>
              You can cancel, downgrade or upgrade your subscription at any
              time.
            </ListItem>
            <ListItem>
              If you cancel your subscription, you will still enjoy your plan
              until the end of your billing cycle.
            </ListItem>
            {/* TODO: add warning for data loss on downgrade
            deine daten bleiben bei einem abbruch bestehen, jedoch können bei einem downgrade überschüssige daten gelöscht werden
            */}
          </UnorderedList>
        </Flex>
      </Box>
    </Box>
  );
};

export default Subscription;
