import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useBreakpointValue,
  useColorModeValue as mode,
  useDisclosure
} from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import GlobalLoadingIndicator from '../GlobalLoadingIndicator/GlobalLoadingIndicator';
import MyLogo from '../Icons/MyLogo';
import { Sidebar } from './Sidebar/Sidebar';

const useMobileMenuState = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  useEffect(() => {
    if (isMobile === false) {
      onClose();
    }
  }, [isMobile, onClose]);
  return { isOpen, onClose, onOpen };
};

const Topbar = () => {
  const { isOpen, onClose, onOpen } = useMobileMenuState();

  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={mode('gray.50', 'gray.800')}
      display={{ base: 'flex', md: 'none' }}
      borderBottomWidth="1px"
    >
      {/* <Logo h="6" iconColor="blue.600" /> */}
      {/* <MyLogo h="7" color={mode('blue.600', 'blue.400')} /> */}
      <MyLogo h='7' color={mode('blue.500', 'blue.400')} />
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={mode('white', 'gray.800')}
          shadow="none"
          position="relative"
          maxW="64"
        >
          <Sidebar width="full" height="full" bg="inherit" border="0" />
          <DrawerCloseButton
            bg="blue.500"
            _hover={{ bg: 'blue.600' }}
            _active={{ bg: 'blue.700' }}
            rounded="0"
            position="absolute"
            color="white"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

const Layout = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <Flex h="100vh" flexDirection="column" bg={mode('gray.50', 'gray.800')}>
      <Topbar />
      <Flex flex="1" overflow="hidden">
        <Sidebar display={{ base: 'none', md: 'flex' }} />
        <GlobalLoadingIndicator />
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;
