import {
  Box,
  Button,
  Collapse,
  Heading,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { useLayoutEffect, useRef, useState } from 'react';

const TextBubble = (props: { heading: string; text: string }) => {
  const { heading, text } = props;
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const textRef = useRef<any>(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const clientHeight = textRef?.current?.clientHeight;
    if (clientHeight) {
      setHeight(clientHeight);
    }
  }, [textRef?.current?.clientHeight]);
  const showCollapse = height > 50;

  return (
    <Box bgColor={mode('gray.200', 'gray.600')} w="48" borderRadius="2xl" p="1" my='1'>
      <Heading size="xs">{heading}</Heading>
      <Collapse startingHeight={showCollapse ? 55 : 50} in={show}>
        <Text fontSize="sm" ref={textRef}>
          {text}
        </Text>
      </Collapse>
      {showCollapse && (
        <Button
          size="xs"
          onClick={handleToggle}
          variant="ghost"
          mt="1rem"
          bgColor="transparent"
        >
          Show {show ? 'Less' : 'More'}
        </Button>
      )}
    </Box>
  );
};

export default TextBubble;
