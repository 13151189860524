import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getTeamMembers } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { MembershipDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchMembers = async (teamId: string): Promise<MembershipDocument[]> => {
  const response = getTeamMembers(teamId);
  return response;
};

export const useMembers = (enabled: boolean, teamId: string) => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  // TODO: Future Feature: Enable refetch again and fetch only updated docs after init fetch
  return useQuery('members', () => fetchMembers(teamId), {
    enabled,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: Infinity,
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    }
  });
};
