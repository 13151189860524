import { Box } from '@chakra-ui/react';
import { useSubscriptions } from '../../hooks/api/query/useSubscriptions';
import { useAuth } from '../../state/context/AuthContext';
import ProfileSubscription from '../Subscription/ProfileSubscription';

const Billing = () => {
  const { currentUser } = useAuth();
  const { data: subscriptions, isSuccess } = useSubscriptions(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const subscription =
    subscriptions && subscriptions?.length > 0 ? subscriptions[0] : undefined;
  return (
    <Box w="full" h="full" overflowY="auto">
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="6xl" mx="auto">
        {isSuccess && <ProfileSubscription subscription={subscription} />}
      </Box>
    </Box>
  );
};

export default Billing;
