import { Box, BoxProps } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface UnderLineLinkProps extends BoxProps {
  to: string;
}
export const UnderlineLink = (props: UnderLineLinkProps) => {
  const { to } = props;
  return (
    <Box
      as={NavLink}
      pos="relative"
      display="inline-block"
      transition="opacity 0.2s"
      _hover={{ opacity: 0.8 }}
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: '2px',
        bottom: 0,
        bg: 'blue.500',
        insetX: 0,
        insetY: 0,
      }}
      {...props}
      to={to}
    />
  );
};
