export default {
  ROOT: '/',
  SETTINGS: '/settings',
  LOGIN: '/login',
  VERIFY: '/verify',
  CONFIRM: '/confirm',
  SIGNUP: '/signup',
  EMAILLINK: '/email-link',
  TEAM: '/team',
  BILLING: '/billing',
  SUBSCRIPTION: '/subscription',
  INVITE: '/invite',
  ADD_PAGE: '/add-page',
  DONE: '/done',
  ADD_POST: '/posts/new',
  EDIT_POST: '/posts/:postId',
  EDIT_PAGE: '/page',
  EDIT_PAGES: '/edit-pages',
  PROFILE: '/profile',
  REPLY_RULES: '/reply-rules',
  RESET_PASSWORD: '/recover-password',
  THANK_YOU: '/e0db131766c68bdcc8d64d1bb0dbd02b/:price/:priceId/:name/:yearly',
};
