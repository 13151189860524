import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { connectPage } from '../../../api/firebase';
import { setCurrentPageId, usePreferences } from '../../../state/context/PreferencesContext';
import { FacebookPage } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useConnectPage() {
  const { state, dispatch } = usePreferences();
  const { locale } = state;
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (values: { teamId: string; newPage: FacebookPage; meId: string; email: string; update: boolean }) =>
      connectPage(values.teamId, values.newPage, values.meId, values.email, values.update),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (newPageData) => {
        const previousPages = queryClient.getQueryData(
          'facebookPages'
        ) as FacebookPage[];
        if (previousPages) {
          queryClient.setQueryData(
            'facebookPages',
            previousPages.map((page) => {
              if (page.id === newPageData.id) return newPageData;
              return page;
            })
          );
        } else {
          queryClient.setQueryData('facebookPages', [newPageData]);
        }
        queryClient.invalidateQueries('facebookPages');

        // set current page
        setCurrentPageId(dispatch, newPageData.id);

        // TODO: translations!
        toast({
          description: 'Page connected sucessfully!',
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
