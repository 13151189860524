import { useMutation, useQueryClient } from 'react-query';
import {
  setCurrentTeamId,
  usePreferences
} from '../../../state/context/PreferencesContext';
import { UserDocument } from '../../../state/model/firebase';

export default function useAuthProfile() {
  const queryClient = useQueryClient();
  const { state, dispatch } = usePreferences();

  return useMutation((authUser: () => Promise<UserDocument>) => authUser(), {
    onMutate: () => {
      setCurrentTeamId(dispatch, null);
    },
    onSuccess: (newUserData) => {
      // Optimistic update
      queryClient.setQueryData('profile', newUserData);

      // TODO: remove?
      // const previousUsers = queryClient.getQueryData('members') as [];
      // if (previousUsers) {
      //   queryClient.setQueryData('members', [...previousUsers, newUserData]);
      // } else {
      //   queryClient.setQueryData('members', [newUserData]);
      // }

      // queryClient.invalidateQueries('members');
      // queryClient.invalidateQueries('profile');

      // Init current team
      if (!state.currentTeamId) setCurrentTeamId(dispatch, newUserData.id);
    },
  });
}
