import { BrowserRouter, Route, Switch } from 'react-router-dom';
import usePermission from '../../hooks/Permission/usePermission';
import AddPage from '../AddPage/AddPage';
import Billing from '../Billing/Billing';
import ConfirmLogin from '../Confirm/ConfirmLogin';
import Done from '../Done/Done';
import EditPages from '../EditPages/EditPages';
import Home from '../Home/Home';
import Invite from '../Invite/Invite';
import EmailLink from '../Login/EmailLink';
import Login from '../Login/Login';
import PageSettings from '../Page/PageSettings';
import Post from '../Post/Post';
import Profile from '../Profile/Profile';
import ReplyRules from '../ReplyRules/ReplyRules';
import ResetPassword from '../ResetPassword/ResetPassword';
import SignUp from '../SignUp/SignUp';
import Subscription from '../Subscription/Subscription';
import Team from '../Team/Team';
import ThankYou from '../ThankYou/ThankYou';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import paths from './paths';
import PrivateRoute from './PrivateRoute/PrivateRoute';

// TODO: Future: code splitting?
// const Home = lazy(() => import('../Home/Home'));
// const Invite = lazy(() => import('../Invite/Invite'));
// const EmailLink = lazy(() => import('../Login/EmailLink'));
// const AddPage = lazy(() => import('../AddPage/AddPage'));
// const Billing = lazy(() => import('../Billing/Billing'));
// const ConfirmLogin = lazy(() => import('../Confirm/ConfirmLogin'));
// const EditPages = lazy(() => import('../EditPages/EditPages'));
// const PageSettings = lazy(() => import('../Page/PageSettings'));
// const Post = lazy(() => import('../Post/Post'));
// const Profile = lazy(() => import('../Profile/Profile'));
// const ReplyRules = lazy(() => import('../ReplyRules/ReplyRules'));
// const ResetPassword = lazy(() => import('../ResetPassword/ResetPassword'));
// const Subscription = lazy(() => import('../Subscription/Subscription'));
// const Team = lazy(() => import('../Team/Team'));
// const VerifyEmail = lazy(() => import('../VerifyEmail/VerifyEmail'));

const RouterComponent = () => {
  const { hasSubscription } = usePermission();

  return (
    <BrowserRouter>
      {/* TODO: Future: code splitting? <Suspense fallback={<div>Loading...</div>}> */}
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.SIGNUP} component={SignUp} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={paths.EMAILLINK} component={EmailLink} />
        <Route exact path={paths.VERIFY} component={VerifyEmail} />
        <Route exact path={paths.CONFIRM} component={ConfirmLogin} />
        <Route exact path={paths.THANK_YOU} component={ThankYou} />
        <PrivateRoute exact path={paths.PROFILE} component={Profile} />
        <PrivateRoute exact path={paths.REPLY_RULES} component={ReplyRules} />
        <PrivateRoute exact path={paths.TEAM} component={Team} />
        <PrivateRoute exact path={paths.BILLING} component={Billing} />
        <PrivateRoute
          exact
          path={paths.SUBSCRIPTION}
          component={Subscription}
        />
        <PrivateRoute exact path={paths.ADD_PAGE} component={AddPage} />
        <PrivateRoute exact path={paths.DONE} component={Done} />
        <PrivateRoute exact path={paths.ADD_POST} component={Post} />
        <PrivateRoute exact path={paths.EDIT_POST} component={Post} />
        <PrivateRoute exact path={paths.EDIT_PAGE} component={PageSettings} />
        <PrivateRoute exact path={paths.EDIT_PAGES} component={EditPages} />
        <PrivateRoute exact path={paths.INVITE} component={Invite} />
        {/* <PrivateRoute exact={false} path={paths.ROOT} component={Home} /> */}
        {hasSubscription ? (
          <PrivateRoute exact={false} path={paths.ROOT} component={Home} />
        ) : (
          <PrivateRoute
            exact={false}
            path={paths.ROOT}
            component={Subscription}
          />
        )}
      </Switch>
      {/* </Suspense> */}
    </BrowserRouter>
  );
};

export default RouterComponent;
