import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  HStack,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue as mode,
  useMenuButton
} from '@chakra-ui/react';
import { HiSelector } from 'react-icons/hi';
import { NavLink, useHistory } from 'react-router-dom';
import { useFormatMessage } from '../../../hooks';
import { useProfile } from '../../../hooks/api/query/useProfile';
import { useProfileTeams } from '../../../hooks/api/query/useProfileTeams';
import paths from '../../../pages/Router/paths';
import { logout, useAuth } from '../../../state/context/AuthContext';
import {
  setCurrentTeamId,
  usePreferences
} from '../../../state/context/PreferencesContext';

interface AccountButtonProps extends FlexProps {
  name: string;
  logo: string | undefined;
}
const AccountButton = (props: AccountButtonProps) => {
  const buttonProps = useMenuButton(props);
  const { name, logo } = props;

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={mode('gray.50', 'gray.800')}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: mode('gray.200', 'gray.700') }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Avatar
          w="8"
          h="8"
          referrerPolicy="no-referrer"
          src={logo}
          alt="Profile"
          name={name}
        />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            {name}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
};

export const AccountDropdown = () => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { data: profile } = useProfile(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const { state, dispatch } = usePreferences();
  const { data: teams = [] } = useProfileTeams(
    currentUser?.uid || '',
    !!currentUser?.uid
  );

  const onTeamChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      setCurrentTeamId(dispatch, value);
      history.push(paths.ROOT);
      window.location.reload(false);
    }
  };

  return (
    <Menu>
      <AccountButton
        name={profile?.name || ''}
        logo={profile?.logoUrl || undefined}
      />
      <MenuList shadow="lg" py="4" color={mode('gray.600', 'gray.200')} px="3">
        <Text fontSize='sm' mb="2">
          {profile?.email}
        </Text>
        {state.currentTeamId && teams.length > 0 && (
          <>
            <Text fontWeight="medium" mb="2">
              Select Team
            </Text>
            <MenuOptionGroup
              defaultValue={state.currentTeamId}
              onChange={onTeamChange}
            >
              {teams.map((t) => (
                <MenuItemOption
                  key={t.teamId}
                  value={t.teamId}
                  fontWeight="semibold"
                  rounded="md"
                >
                  {t.teamName}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </>
        )}
        <MenuDivider />
        <NavLink to={paths.PROFILE}>
          <MenuItem rounded="md">{useFormatMessage('NavBar.profile')}</MenuItem>
        </NavLink>
        <MenuDivider />
        <MenuItem rounded="md" onClick={logout}>
          {useFormatMessage('NavBar.logOut')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
