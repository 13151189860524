import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { processComment } from '../../../api/firebase';
import { FbError } from '../../../state/model/facebook';
import { Platform, ReviewTask } from '../../../state/model/firebase';

export default function useProcessComment(onSettled: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    (variables: {
      pageId: string;
      teamId: string;
      commentId: string;
      replyText: string | undefined;
      shouldLike: boolean;
      platform: Platform;
      pageToken: string;
    }) =>
      processComment(
        variables.teamId,
        variables.commentId,
        variables.replyText,
        variables.shouldLike,
        variables.platform,
        variables.pageToken,
        variables.pageId
      ),
    {
      onError: (error: Error) => {
        let message = `${error}`;
        if (error.message) {
          const fbError = JSON.parse(error.message) as FbError;
          if (fbError.error?.message) message = fbError.error.message;
        }
        toast({
          description: message,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: (_, error, variables) => {
        onSettled();
        if (error) return;
        const previousTasks = queryClient.getQueryData([
          variables.pageId,
          'reviewTasks',
        ]) as ReviewTask[];
        if (previousTasks)
          queryClient.setQueryData(
            [variables.pageId, 'reviewTasks'],
            previousTasks.filter(
              (task) => task.userMessage.parent.id !== variables.commentId
            )
          );
      },
    }
  );
}
