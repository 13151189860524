import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { object, string } from 'yup';
import MyLogo from '../../components/Icons/MyLogo';
import { useFormatMessage } from '../../hooks';
import useResetPassword from '../../hooks/api/mutation/useResetPassword';
import paths from '../Router/paths';

const schema = object().shape({
  email: string().email().required(),
});

const ResetPassword = () => {
  const { error, isLoading, isSuccess, mutate } = useResetPassword();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [email, setEmail] = useState('');

  const onSubmitHandler = (value: { email: string }) => {
    mutate(value.email);
    setEmail(value.email);
  };

  const errorMessage = error ? `${error}` : null;

  const successMessage = useFormatMessage('ResetPassword.recoverEmail', {
    mail: email,
  });
  const backMessage = useFormatMessage('ResetPassword.back');
  const emailMessage = useFormatMessage('ResetPassword.email');
  const emailPlaceholderMessage = useFormatMessage(
    'ResetPassword.emailRegistration'
  );
  const sendLinkMessage = useFormatMessage('ResetPassword.resetLink');

  return (
    <Box
      bg={mode('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{ sm: '6', lg: '8' }}
    >
      <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} w={{ sm: 'full' }}>
        <Box mb={{ base: '10', md: '28' }}>
          <MyLogo mx="auto" h="8" iconColor={mode('blue.500', 'blue.200')} />
        </Box>
        <Heading mt="6" textAlign="center" size="xl" fontWeight="extrabold">
          {useFormatMessage('ResetPassword.recovery')}
        </Heading>
      </Box>
      <Box maxW={{ sm: 'md' }} mx={{ sm: 'auto' }} mt="8" w={{ sm: 'full' }}>
        <Box
          bg={mode('white', 'gray.700')}
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
        >
          {isSuccess ? (
            <>
              <FormLabel>{successMessage}</FormLabel>
              <Button
                colorScheme="blue"
                size="lg"
                fontSize="md"
                as={NavLink}
                to={paths.LOGIN}
              >
                {backMessage}
              </Button>
            </>
          ) : (
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Stack spacing="6">
                <FormControl id="email" isInvalid={!!errors.email}>
                  <FormLabel>{emailMessage}</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder={emailPlaceholderMessage}
                    required
                    ref={register}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                  {errorMessage ? (
                    <FormLabel color="red">{errorMessage}</FormLabel>
                  ) : null}
                </FormControl>
                <HStack width="full">
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    colorScheme="blue"
                    size="lg"
                    fontSize="md"
                  >
                    {sendLinkMessage}
                  </Button>
                  <Button
                    size="lg"
                    fontSize="md"
                    variant="outline"
                    as={NavLink}
                    to={paths.LOGIN}
                  >
                    {backMessage}
                  </Button>
                </HStack>
              </Stack>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
