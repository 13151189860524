import { useToast } from '@chakra-ui/toast';
import { useMutation } from 'react-query';
import { useFormatMessage } from '../..';
import { changePassword } from '../../../state/context/AuthContext';

interface NewPassword {
  currentPassword: string;
  newPassword: string;
}
export default function useChangePassword() {
  const toast = useToast();

  const successMessage = useFormatMessage('ChangePassword.passwordSuccess');

  return useMutation(
    (values: NewPassword) =>
      changePassword(values.currentPassword, values.newPassword),
    {
      onError: (error: Error) => {
        toast({
          description: `${error.message}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: () => {
        toast({
          description: successMessage,
          position: 'top-right',
          duration: 9000,
          status: 'success',
          isClosable: true,
        });
      },
    }
  );
}
