/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app';
import 'firebase/app-check';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const performance = firebase.performance();
export const analytics = firebase.analytics();

const appCheck = firebase.appCheck();
appCheck.activate(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '');

// HACK: TEST WITH EMULATOR
// firebase emulators:start /  firebase emulators:start --import=./test-data
// eslint-disable-next-line no-restricted-globals
// if (location.hostname === 'localhost') {
//   console.log('localhost detected!!!!!!!!! - use simulator');
//   auth.useEmulator('http://localhost:9099');
//   firestore.useEmulator('localhost', 8080);
//   functions.useEmulator('localhost', 5001);
// }
// export data with -> firebase emulators:export ./test-data

export default firebase;
