import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { RuleTag } from '../../state/model/firebase';

interface TagInputProps {
  ruleTags: RuleTag[];
  onAddTag: (value: string) => void;
  onRemoveTag: (id: string) => void;
  placeHolder?: string;
  tagColor?: 'blue' | 'red';
}
const TagInput = (props: TagInputProps) => {
  const { placeHolder, ruleTags = [], tagColor = 'blue', onAddTag, onRemoveTag } = props;
  const [currentText, setCurrentText] = useState('');

  const handleAddTag = () => {
    // Do not add tag, if already exists
    for (let i = 0; i < ruleTags.length; i += 1) {
      const ruleTag = ruleTags[i];
      if (ruleTag.text === currentText) return;
    }
    onAddTag(currentText);
    setCurrentText('');
  };

  const onKeyPressed = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleAddTag();
    }
  };

  const TagElements = useMemo(() => {
    if(!ruleTags) return null;
    return ruleTags.map((tag) => {
      return (
        <Tag
          key={tag.id}
          borderRadius="full"
          variant="solid"
          colorScheme={tagColor}
          mr="1"
          mb="1"
        >
          <TagLabel>{tag.text}</TagLabel>
          <TagCloseButton onClick={() => onRemoveTag(tag.id)} />
        </Tag>
      );
    });
  }, [ruleTags, tagColor, onRemoveTag]);

  return (
    <>
      <Box mb="2">{!!ruleTags && TagElements}</Box>
      <InputGroup>
        <Input
          pr="4.5rem"
          type="text"
          placeholder={placeHolder}
          value={currentText}
          onChange={(event) => setCurrentText(event.target.value)}
          onKeyUp={onKeyPressed}
        />
        <InputRightElement width="4.5rem">
          <IconButton
            aria-label="Add word"
            icon={<AddIcon />}
            size="sm"
            colorScheme="blue"
            borderRadius="full"
            onClick={handleAddTag}
          />
        </InputRightElement>
      </InputGroup>
    </>
  );
};

export default TagInput;
