import { DeleteIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
  VStack
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { FieldGroup } from '../../components/FormLayout/FieldGroup/FieldGroup';
import { useConfirmationModal } from '../../components/Modal/ConfirmationModal';
import { useFormatMessage } from '../../hooks';
import useDeletePage from '../../hooks/api/mutation/useDeletePage';
import useLinkFacebook from '../../hooks/api/mutation/useLinkFacebook';
import { useFacebookPages } from '../../hooks/api/query/useFacebookPages';
import { useProfile } from '../../hooks/api/query/useProfile';
import { useAuth } from '../../state/context/AuthContext';
import { usePreferences } from '../../state/context/PreferencesContext';
import SettingsCard from '../Home/Settings/SettingsCard';
import paths from '../Router/paths';

const EditPages = () => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const { data: profile } = useProfile(
    !!currentUser?.uid,
    currentUser?.uid || ''
  );
  const { state } = usePreferences();
  const { data: fbPages, isLoading } = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );

  const linkMutation = useLinkFacebook();
  const deleteMutation = useDeletePage();

  const { ConfirmationModal, onOpen, onClose, setPayload } =
    useConfirmationModal<{
      pageId: string;
      teamId: string;
    }>(
      deleteMutation.isLoading,
      useFormatMessage('Users.delete'),
      'This will permanently delete the Page and all of its posts inside TurboReply. Action can not be undone.',
      useFormatMessage('Users.confirm'),
      useFormatMessage('Users.cancel')
    );
  const onPageDeleteHandler = useCallback(
    (values: { pageId: string; teamId: string }) => {
      deleteMutation.mutate(values, {
        onSuccess: () => {
          onClose();
        },
      });
    },
    [deleteMutation, onClose]
  );
  const onRemoveButtonClickHandler = useCallback(
    (pageId: string) => {
      setPayload({
        pageId,
        teamId: state.currentTeamId || '',
      });
      onOpen(onPageDeleteHandler);
    },
    [onPageDeleteHandler, onOpen, setPayload, state]
  );

  const onLinkFacebook = () => {
    if (!profile) {
      console.error('Current Profile not found!'); // should not happen!
      return;
    }
    linkMutation.mutate(profile, {
      onSuccess: () => {
        history.push(paths.ROOT);
      },
    });
  };

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4">
            Manage Pages
          </Heading>
          <FieldGroup title="Refresh Permissions">
            <VStack width="full" spacing="6">
              <Text fontSize="xs">
                Your Facebook permissions may become invalid if you change your
                Facebook password, or because of internal Facebook changes. If
                receiving replies don&apos;t work properly, you can try
                refreshing your permissions.
              </Text>
              <Button
                variant="outline"
                leftIcon={
                  <Box
                    as={FaFacebook}
                    color={mode('facebook.500', 'facebook.300')}
                  />
                }
                isLoading={linkMutation.isLoading}
                onClick={onLinkFacebook}
              >
                Login with Facebook
              </Button>
              <Alert status="warning" maxW="lg">
                <AlertIcon />
                Make sure to select ALL pages you manage with TurboReply
              </Alert>
            </VStack>
          </FieldGroup>
          <FieldGroup title="Your Pages">
            <VStack width="full" spacing="2" align="start">
              {!isLoading &&
                fbPages &&
                fbPages.map((p) => (
                  <SettingsCard
                    key={p.id}
                    w="full"
                    ml="0"
                    img={p.img}
                    title={p.name}
                    icon={
                      <HStack>
                        <FaFacebook />{' '}
                        {p.instagramBusinessAccountId && <FaInstagram />}{' '}
                      </HStack>
                    }
                    menu={
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          disabled={deleteMutation.isLoading}
                          aria-label="Options"
                          icon={<FiSettings />}
                          variant="outline"
                        />
                        <MenuList>
                          <MenuItem
                            color="red.400"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              onRemoveButtonClickHandler(p.id);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    }
                  />
                ))}
            </VStack>
          </FieldGroup>
        </Stack>
      </Box>
      <ConfirmationModal />
    </Box>
  );
};

export default EditPages;
