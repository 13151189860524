import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { linkFacebookAccount } from '../../../state/context/AuthContext';
import { UserDocument } from '../../../state/model/firebase';

export default function useLinkFacebook() {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation((user: UserDocument) => linkFacebookAccount(user), {
    onError: (error: Error) => {
      toast({
        description: `${error}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
    onSuccess: (newUserData) => {
      queryClient.setQueryData('profile', newUserData);
      queryClient.invalidateQueries('profile');
    },
  });
}
