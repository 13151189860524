import { useToast } from '@chakra-ui/toast';
import { useQuery } from 'react-query';
import { getStripeProducts } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { ProductDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

const fetchProducts = async (): Promise<ProductDocument[]> => {
  const response = getStripeProducts();
  return response;
};

export const useProducts = () => {
  const toast = useToast();
  const { locale } = usePreferences().state;

  return useQuery('products', () => fetchProducts(), {
    onError: (error: Error) => {
      const errorMessage = firebaseError(error.message, locale);
      toast({
        description: `${errorMessage}`,
        position: 'top-right',
        duration: 9000,
        status: 'error',
        isClosable: true,
      });
    },
  });
};
