import { useEffect, useState } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';
import {
  getCurrentUserIdToken,
  UserProvider
} from '../state/context/AuthContext';

const useFormatMessage = (
  id: string,
  values = {},
  defaultMessage = '',
  description = ''
) => {
  const intl = useIntl();
  return intl.formatMessage({ id, defaultMessage, description }, values);
};

const useFormatDate = (
  value: string | number | Date | undefined,
  options: FormatDateOptions | undefined = {}
) => {
  const intl = useIntl();
  return intl.formatDate(value, options);
};

function useUserProvider() {
  const [userProvider, setUserProvider] = useState<UserProvider | undefined>();
  useEffect(() => {
    async function getToken() {
      const idToken = await getCurrentUserIdToken();
      if (idToken?.signInProvider?.toLowerCase() === 'facebook.com')
        setUserProvider({ type: 'facebook.com' });
      else if (idToken?.signInProvider?.toLowerCase() === 'google.com')
        setUserProvider({ type: 'google.com' });
      else setUserProvider({ type: 'email', password: '' });
    }
    getToken();
  }, []);

  return userProvider;
}

type SelectorGroupItem = {
  id: string;
  value: string;
};
function useSelectorGroup() {
  const [state, setState] = useState<SelectorGroupItem[]>([]);

  const onSelectorChange = (id: string, value: string) => {
    const newState = [...state];
    const index = state.findIndex((e) => e.id === id);
    if (index < 0) newState.push({ id, value });
    else {
      newState[index] = { id, value };
    }
    setState(newState);
  };

  return {
    state,
    onSelectorChange,
  };
}

export type FormInputHook = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  InputProps: {
    onChange: (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    value: string;
  };
};

function useFormInput(initialValue: string): FormInputHook {
  const [value, setValue] = useState<typeof initialValue>(initialValue);

  function handleChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) {
    setValue(e.target.value);
  }

  const InputProps = { onChange: handleChange, value };
  return {
    value,
    setValue,
    onChange: handleChange,
    InputProps,
  };
}

export {
  useFormatMessage,
  useFormatDate,
  useUserProvider,
  useSelectorGroup,
  useFormInput,
}; // useChangeHandler
