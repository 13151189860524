import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useColorModeValue as mode
} from '@chakra-ui/react';
import { useFormatMessage } from '../../hooks';

export const TablePagination = (props: {
  length: number;
  lengthLabel: string;
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
}) => {
  const {
    length,
    lengthLabel,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
  } = props;
  return (
    <Flex align="center" justify="space-between">
      <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
        {length} {lengthLabel}
      </Text>
      <ButtonGroup variant="outline" size="sm">
        <Button
          as="a"
          rel="prev"
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          {useFormatMessage('General.previous')}
        </Button>
        <Button
          as="a"
          rel="next"
          disabled={!canNextPage}
          onClick={() => nextPage()}
        >
          {useFormatMessage('General.next')}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
