import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { createOrUpdateReplyRule } from '../../../api/firebase';
import { usePreferences } from '../../../state/context/PreferencesContext';
import { ReplyRule, ReplyRuleDocument } from '../../../state/model/firebase';
import { firebaseError } from '../../../utils';

export default function useEditReplyRule() {
  const { locale } = usePreferences().state;
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    (values: { teamId: string; replyRule: ReplyRule; ruleId?: string }) =>
      createOrUpdateReplyRule(values.teamId, values.replyRule, values.ruleId),
    {
      onError: (error: Error) => {
        const errorMessage = firebaseError(error.message, locale);
        toast({
          description: `${errorMessage}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSuccess: (newReplyRule) => {
        const previousReplyRules = queryClient.getQueryData('replyRules') as ReplyRuleDocument[];
        if (previousReplyRules) {
          queryClient.setQueryData(
            'replyRules',
            previousReplyRules.map((rule) => {
              if (rule.id === newReplyRule.id) return newReplyRule;
              return rule;
            })
          );
        } else {
          queryClient.setQueryData('replyRules', [newReplyRule]);
        }
        queryClient.invalidateQueries('replyRules');
      },
    }
  );
}
