import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { FieldGroup } from '../../components/FormLayout/FieldGroup/FieldGroup';
import { useFormatMessage } from '../../hooks';
import useEditPage from '../../hooks/api/mutation/useEditPage';
import { useFacebookPages } from '../../hooks/api/query/useFacebookPages';
import { usePreferences } from '../../state/context/PreferencesContext';
import { FacebookPage } from '../../state/model/firebase';
import ReplyRuleSelect from '../Post/ReplyRuleSelect';
import paths from '../Router/paths';

const schema = object().shape({
  replyRuleId: string().required(),
});
interface FormValues {
  replyRuleId: string;
}

const PageSettings = () => {
  const history = useHistory();
  const { state } = usePreferences();
  const pages = useFacebookPages(
    (state?.currentTeamId || '') !== '',
    state.currentTeamId || ''
  );
  const currentPage = pages.data?.find((p) => p.id === state.currentPageId);
  const mutation = useEditPage();
  const { register, handleSubmit, errors, watch } = useForm<FormValues>({
    defaultValues: {
      replyRuleId: currentPage?.pageRuleId || undefined,
    },
    resolver: yupResolver(schema),
  });
  const replyRuleId = watch('replyRuleId');

  const onSubmitHandler = (values: FormValues) => {
    if (state.currentTeamId && state.currentPageId && currentPage) {
      const newPage: FacebookPage = {
        ...currentPage,
        pageRuleId: values.replyRuleId,
      };
      mutation.mutate(
        {
          teamId: state.currentTeamId,
          pageId: state.currentPageId,
          page: newPage,
        },
        { onSuccess: () => history.push(paths.ROOT) }
      );
    }
  };

  return (
    <Box w="full" h="full" overflowY="auto">
      <Box px={{ base: '4', md: '10' }} py="16" maxWidth="3xl" mx="auto">
        <form id="post-form" onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack spacing="4" divider={<StackDivider />}>
            <Heading size="lg" as="h1" paddingBottom="4">
              {`Edit Messenger - ${currentPage?.name}`}
            </Heading>
            <FieldGroup title="Apply Settings">
              <VStack width="full" spacing="6">
                <FormControl id="replyRuleId" isInvalid={!!errors.replyRuleId}>
                  <FormLabel>Reply Rule</FormLabel>
                  <ReplyRuleSelect
                    name="replyRuleId"
                    replyRuleId={replyRuleId}
                    ref={register}
                  />
                  <FormErrorMessage>
                    {!!errors.replyRuleId?.message &&
                      errors.replyRuleId?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </FieldGroup>
          </Stack>
          <FieldGroup mt="8">
            <HStack width="full" justify="flex-end">
              <Button
                type="submit"
                colorScheme="blue"
                loadingText={useFormatMessage('General.submitting')}
                isLoading={mutation.isLoading}
              >
                Save
              </Button>
              <Button onClick={() => history.push(paths.ROOT)}>Cancel</Button>
            </HStack>
          </FieldGroup>
        </form>
      </Box>
    </Box>
  );
};

export default PageSettings;
