import { Box, Flex } from '@chakra-ui/react';
import Inbox from './Inbox/Inbox';
import Settings from './Settings/Settings';

const Desktop = () => {
  return (
    <Box display={{ base: 'none', lg: 'flex' }} h="full">
      <Flex
        display={{ base: 'none', lg: 'block' }}
        width="96"
        direction="column"
        overflowY="auto"
        borderRightWidth="1px"
        p="2"
      >
        <Settings />
      </Flex>
      <Flex flex="1" pt="6">
        <Inbox />
      </Flex>
    </Box>
  );
};

export default Desktop;
