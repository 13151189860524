import { useToast } from '@chakra-ui/toast';
import { useMutation, useQueryClient } from 'react-query';
import { ignoreMessage } from '../../../api/firebase';
import { FacebookPage, ReviewTask } from '../../../state/model/firebase';

export default function useIgnoreMessage(onSettled: () => void) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(
    (variables: {
      pageId: string;
      teamId: string;
      page: FacebookPage;
      commentId: string;
    }) =>
      ignoreMessage(
        variables.teamId,
        variables.pageId,
        variables.page,
        variables.commentId
      ),
    {
      onError: (error: Error) => {
        toast({
          description: `${error}`,
          position: 'top-right',
          duration: 9000,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: (newPage, error, variables) => {
        onSettled();
        if (error) return;

        // Update review tasks
        const previousTasks = queryClient.getQueryData([
          variables.pageId,
          'reviewTasks',
        ]) as ReviewTask[];
        if (previousTasks)
          queryClient.setQueryData(
            [variables.pageId, 'reviewTasks'],
            previousTasks.filter(
              (task) => task.userMessage.parent.id !== variables.commentId
            )
          );

        if (!newPage) return; // should not happen onSuccess

        // Update facebook pages
        const previousPages = queryClient.getQueryData([
          'facebookPages',
        ]) as FacebookPage[];
        if (previousPages) {
          queryClient.setQueryData(
            'facebookPages',
            previousPages.map((page) => {
              if (page.id === newPage.id) return newPage;
              return page;
            })
          );
        } else {
          queryClient.setQueryData('facebookPages', [newPage]);
        }
        queryClient.invalidateQueries('facebookPages');
      },
    }
  );
}
