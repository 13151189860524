import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useColorModeValue as mode,
  useDisclosure
} from '@chakra-ui/react';
import { BiMessageRoundedCheck } from 'react-icons/bi';
import { FaRegQuestionCircle, FaUsers } from 'react-icons/fa';
import { FiMail, FiSmartphone } from 'react-icons/fi';
import { HiInboxIn } from 'react-icons/hi';
import { RiBillLine, RiMessengerFill, RiMovieLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import usePermission from '../../../hooks/Permission/usePermission';
import paths from '../../../pages/Router/paths';
import { useAuth } from '../../../state/context/AuthContext';
import { usePreferences } from '../../../state/context/PreferencesContext';
import MyLogo from '../../Icons/MyLogo';
import InstallAppModal from '../../Modal/InstallAppModal';
import { AccountDropdown } from './AccountDropdown';
import { NavLink as NavElement } from './NavLink';

export const Sidebar = (props: FlexProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { currentUser } = useAuth();
  const { state } = usePreferences();
  const { hasSubscription } = usePermission();

  const showSubscription = state.currentTeamId === currentUser?.uid;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Popover placement="right">
        <Flex
          bg={mode('gray.50', 'gray.800')}
          direction="column"
          borderRightWidth="1px"
          width="64"
          boxShadow={mode(
            'inset -10px 1px 24px -5px rgb(132 146 166 / 16%);',
            'none'
          )}
          {...props}
        >
          <Flex
            direction="column"
            flex="1"
            pt="5"
            pb="4"
            overflowY="auto"
            px="4"
          >
            <Box mb="6">
              <MyLogo h="7" color={mode('blue.500', 'blue.400')} />
            </Box>
            <Box mb="6">
              <AccountDropdown />
            </Box>
            <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
              <Stack spacing="1">
                <NavElement
                  label="Inbox"
                  icon={HiInboxIn}
                  to={paths.ROOT}
                  exact
                  display={hasSubscription ? "block" : "none"}
                />
                <NavElement
                  label="Rules"
                  icon={BiMessageRoundedCheck}
                  to={paths.REPLY_RULES}
                  display={hasSubscription ? "block" : "none"}
                />
                <NavElement label="Team" icon={FaUsers} to={paths.TEAM}
                  display={hasSubscription ? "block" : "none"} />
              </Stack>
              <Divider />
              <Stack spacing="3">
                {showSubscription && (
                  <>
                    <NavElement
                      label="Billing"
                      icon={RiBillLine}
                      to={paths.BILLING}
                      display={hasSubscription ? "block" : "none"}
                    />
                    <Button
                      as={NavLink}
                      to={paths.SUBSCRIPTION}
                      colorScheme="blue"
                      fontSize="sm"
                      mb="4"
                      h="8"
                    >
                      Upgrade Plan
                    </Button>
                  </>
                )}
                <Menu>
                  <NavElement
                    label="Help"
                    icon={FaRegQuestionCircle}
                    as={MenuButton}
                  />
                  <MenuList>
                    <MenuGroup title="Help">
                      <MenuItem
                        icon={<RiMovieLine />}
                        as={Link}
                        href="https://youtube.com/playlist?list=PLyQLtIJhKLQZiEa2MTJDsBzU11hasMOpr"
                        target="_blank"
                      >
                        Video Tutorials
                      </MenuItem>
                      <MenuItem
                        icon={<RiMessengerFill />}
                        as={Link}
                        href="https://m.me/turboreply"
                        target="_blank"
                      >
                        Chat with us
                      </MenuItem>
                      <MenuItem
                        icon={<FiMail />}
                        as={Link}
                        href="https://turboreply.com/contact"
                        target="_blank"
                      >
                        Contact us
                      </MenuItem>
                      <Text
                        fontSize="xs"
                        display="block"
                        w="48"
                        wordBreak="break-word"
                        textAlign="center"
                        pl="4"
                        pt="4"
                      >
                        Please refresh/re-open the app once in a while to get
                        the latest updates.
                      </Text>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Info">
                      <MenuItem
                        as={Link}
                        href="https://turboreply.com/terms-of-service/"
                        target="_blank"
                      >
                        Terms of Service
                      </MenuItem>
                      <MenuItem
                        as={Link}
                        href="https://turboreply.com/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Stack>
            </Stack>
            <Spacer />
            <Stack spacing="3">
              <NavElement
                label="Install App"
                icon={FiSmartphone}
                as={Button}
                bgColor="transparent"
                onClick={onOpen}
              />
              <IconButton
                aria-label="Toggle Color Mode"
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
              />
            </Stack>
          </Flex>
        </Flex>
      </Popover>
      <InstallAppModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
