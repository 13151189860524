import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldGroup } from '../../../components/FormLayout/FieldGroup/FieldGroup';
import { useConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { useFormatMessage } from '../../../hooks';
import useDeleteProfile from '../../../hooks/api/mutation/useDeleteProfile';
import {
  logout,
  reauthenticateAccount,
  UserProvider
} from '../../../state/context/AuthContext';
import { UserDocument } from '../../../state/model/firebase';

const DeleteAccount = (props: {
  profile: UserDocument;
  userProvider: UserProvider;
}) => {
  const { profile, userProvider } = props;
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  });
  const mutation = useDeleteProfile();
  const onDeleteUserHandler = useCallback(
    (values: { userId: string; logoUrl: string | null }) => {
      mutation.mutate(values, {
        onSuccess: async () => {
          await logout();
        },
      });
    },
    [mutation]
  );
  const { ConfirmationModal, onOpen, setPayload } = useConfirmationModal<{
    userId: string;
    logoUrl: string | null;
  }>(
    mutation.isLoading,
    useFormatMessage('Users.delete'),
    useFormatMessage('Users.permDelete'),
    useFormatMessage('Users.confirm'),
    useFormatMessage('Users.cancel')
  );
  const onRemoveButtonClickHandler = useCallback(() => {
    setPayload({ userId: profile!.id, logoUrl: profile!.logoUrl });
    onOpen(onDeleteUserHandler);
  }, [onOpen, setPayload, profile, onDeleteUserHandler]);

  const [wrong, setWrong] = useState(false);
  const notMatchPasswordMessage = useFormatMessage(
    `ChangePassword.notMatchPassword`
  );

  const onSubmitHandler = async (values: { password: string }) => {
    let valid = false;
    try {
      if (userProvider.type === 'email')
        valid = await reauthenticateAccount({
          type: userProvider.type,
          password: values.password,
        });
      else valid = await reauthenticateAccount(userProvider);
    } catch (error) {
      valid = false;
    }
    if (valid) onRemoveButtonClickHandler();
    else setWrong(true);
  };

  return (
    <>
      <form id="delete-form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing="4" divider={<StackDivider />}>
          <Heading size="lg" as="h1" paddingBottom="4" paddingTop="4">
            {useFormatMessage(`DeleteAccount.dangerZone`)}
          </Heading>
          <FieldGroup title={useFormatMessage(`DeleteAccount.removeAccount`)}>
            <VStack width="full" spacing="6">
              <FormControl id="password" isInvalid={!!wrong}>
                <FormLabel>
                  {useFormatMessage(`DeleteAccount.confirmDelete`)}
                </FormLabel>
                {userProvider.type === 'email' ? (
                  <>
                    <Input
                      name="password"
                      type="password"
                      required
                      ref={register}
                    />
                    <FormErrorMessage>
                      {wrong && notMatchPasswordMessage}
                    </FormErrorMessage>
                  </>
                ) : null}
              </FormControl>
            </VStack>
          </FieldGroup>
        </Stack>
        <FieldGroup mt="8">
          <HStack width="full">
            <Button type="submit" colorScheme="red">
              {useFormatMessage(`DeleteAccount.delete`)}
            </Button>
          </HStack>
        </FieldGroup>
      </form>
      <ConfirmationModal />
    </>
  );
};

export default DeleteAccount;
